import { Button, Popconfirm, Select, Upload, message } from "antd";
import {
  EmailAuthProvider,
  deleteUser,
  getAuth,
  reauthenticateWithCredential,
} from "firebase/auth";
import { collection, doc, get, getDocs } from "firebase/firestore";
import React, { useState } from "react";
import { BiArrowFromLeft } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "sonner";
import { db } from "../../firebase";
import Papa from "papaparse";
import {
  ArrowLeftCircleIcon,
  ArrowRightCircleIcon,
} from "@heroicons/react/24/outline";
import { InboxOutlined } from "@ant-design/icons";
import { v4 as uuid4 } from "uuid";
import moment from "moment";
import { bulkAddToTaskOrder, bulkAddToTasks } from "../../redux/tasksSlice";
import { bulkCreateLabels } from "../../redux/labelsSlice";
import { groupBy } from "lodash";
import {
  incrementManualTriggerCounter,
  toggleSettings,
} from "../../redux/appSlice";
import { analytics } from "../../utils";
import ImportSunsama from "./ImportSunsama";
import SettingsBackButton from "../Generics/SettingsBackButton";
import { BsArrowRight } from "react-icons/bs";
import {
  TbArrowRight,
  TbLayoutList,
  TbListDetails,
  TbSquareRounded,
  TbSquareRoundedCheck,
  TbTableExport,
  TbTableImport,
  TbTag,
} from "react-icons/tb";
import { MdOutlineDangerous } from "react-icons/md";
import { TiWarningOutline } from "react-icons/ti";
import SunsamaLogo from "../../images/sunsama-logo.svg";

function AccountData() {
  const [activeCard, setActiveCard] = useState(null);
  const userId = useSelector((state) => state.app.uid);

  const Card = ({
    title = null,
    description = null,
    onClick = null,
    buttonTitle = null,
    icon = null,
  }) => {
    return (
      <div className="flex flex-row items-center justify-between border rounded-xl px-5 py-5 primary dark:border-neutral-600">
        <div className="flex flex-col items-start gap-1">
          <div className="flex flex-row gap-2 items-center">
            {icon ? icon : null}
            <div className="text-base font-semibold">{title}</div>
          </div>
          <div className="text-sm text-neutral-400">{description}</div>
        </div>
        <button
          onClick={onClick}
          className="h-full flex flex-row gap-1 items-center px-3 py-2 rounded-md text-sm font-medium text-appPrimaryLight hover:text-appPrimaryLightHover dark:text-appPrimaryDark dark:hover:text-appPrimaryDarkHover"
        >
          <span>{buttonTitle ? buttonTitle : "Go"}</span>
          <TbArrowRight className="h-4" />
        </button>
      </div>
    );
  };

  const Import = () => {
    const [sunsamaImportActive, setSunsamaImportActive] = useState(false);

    return (
      <>
        {!sunsamaImportActive && (
          <div className="flex flex-col gap-4 ">
            <div>
              <SettingsBackButton onClick={() => setActiveCard(null)} />
            </div>
            <div className="text-[#172b4d] dark:text-white font-medium">
              Select how you want to import your files.
            </div>
            <div className="flex flex-col">
              {/* Sunsama */}
              <div className="add-calendar-container primary">
                <div className="flex items-center gap-2">
                  <div>
                    <img
                      className="w-6 h-6 object-contain"
                      src={SunsamaLogo}
                      alt="Sunsama Logo"
                    />
                  </div>
                  <div>
                    <div className="text-base font-semibold">Sunsama</div>
                  </div>
                </div>
                <button
                  onClick={() => setSunsamaImportActive(true)}
                  className="button border border-[#CACACA] dark:border-[#373737] p-1 rounded-lg text-xs font-medium"
                >
                  Import
                </button>
              </div>
              {/* CSV */}
              <div className="add-calendar-container primary">
                <div className="flex items-center gap-2">
                  <div>
                    <img
                      className="w-6 h-6 object-contain"
                      src={require("../../images/csv-icon.png")}
                      alt="Csv Logo"
                    />
                  </div>
                  <div>
                    <div className=" text-base font-semibold">CSV</div>
                  </div>
                </div>
                <button className="button disabled border border-[#CACACA] dark:border-[#373737] py-1 px-2.5 rounded-md text-xs font-medium">
                  Coming Soon
                </button>
              </div>
              {/* Todoist */}
              <div className="add-calendar-container primary">
                <div className="flex items-center gap-2">
                  <div>
                    <img
                      className="w-6 h-6 object-contain"
                      src={require("../../images/todoist-icon.png")}
                      alt="Todoist Logo"
                    />
                  </div>
                  <div>
                    <div className="text-base font-semibold">Todoist</div>
                  </div>
                </div>
                <button className="button disabled border border-[#CACACA] dark:border-[#373737] p-1 rounded-lg text-xs font-medium">
                  Coming Soon
                </button>
              </div>
            </div>
          </div>
        )}
        {sunsamaImportActive && (
          <ImportSunsama setSunamaImportActive={setSunsamaImportActive} />
        )}
      </>
    );
  };
  const Export = () => {
    const [activeExport, setActiveExport] = useState(null);

    const ExportCard = ({ onClick, title, description, icon }) => {
      return (
        <div className="flex flex-row items-center justify-between border rounded-xl px-5 py-5 primary dark:border-neutral-600">
          <div className="flex flex-col items-start gap-1">
            <div className="flex flex-row gap-2 items-center">
              {icon ? icon : null}
              <div className="text-base font-semibold">{title}</div>
            </div>
            <div className="text-sm text-neutral-400">{description}</div>
          </div>
          <button
            onClick={onClick}
            className="button border border-[#CACACA] dark:border-[#373737] p-1 rounded-lg text-xs font-medium"
          >
            Download
          </button>
        </div>
      );
    };

    const handleTaskExport = async () => {
      try {
        const tasksCollectionRef = collection(db, "users", userId, "tasks");
        const tasksSnapshot = await getDocs(tasksCollectionRef);

        if (!tasksSnapshot.empty) {
          // Convert the document snapshots into an array of task objects
          const tasksArray = tasksSnapshot.docs.map((taskDoc) =>
            taskDoc.data()
          );

          const headers = [
            "id",
            "description",
            "listId",
            "complete",
            "created_at",
            "date",
            "start",
            "completed_at",
            "estimated_time",
            "actual_time",
            "label",
          ];
          const csv = [headers.join(",")];

          const timestampHeaders = [
            "created_at",
            "date",
            "start",
            "completed_at",
          ];

          tasksArray.forEach((task) => {
            const row = headers.map((header) => {
              if (
                timestampHeaders.includes(header) &&
                task[header] &&
                task[header].seconds
              ) {
                const date = new Date(task[header].seconds * 1000);
                return date.toISOString();
              }
              return task[header] ? task[header].toString() : "";
            });
            csv.push(row.join(","));
          });

          const csvString = csv.join("\r\n");
          const blob = new Blob([csvString], { type: "text/csv" });
          const href = URL.createObjectURL(blob);

          const link = document.createElement("a");
          link.href = href;
          link.download = "ellie-tasks-export.csv";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          toast.success(`(${tasksArray.length}) Tasks exported successfully`);
        }
      } catch (error) {
        toast.error(error.message);
        console.log(error.message);
      }
    };

    const handleListExport = async () => {
      try {
        const listsCollectionRef = collection(db, "users", userId, "lists");
        const listsSnapshot = await getDocs(listsCollectionRef);

        if (!listsSnapshot.empty) {
          const listsArray = listsSnapshot.docs.map((listDoc) =>
            listDoc.data()
          );

          const headers = ["id", "title", "icon", "auto_label_id"];
          const csv = [headers.join(",")];

          listsArray.forEach((list) => {
            const row = headers.map((header) => {
              return list[header];
            });
            csv.push(row.join(","));
          });

          const csvString = csv.join("\r\n");

          const blob = new Blob([csvString], { type: "text/csv" });
          const href = URL.createObjectURL(blob);

          const link = document.createElement("a");
          link.href = href;
          link.download = "ellie-lists-export.csv";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          toast.success(`(${listsArray.length}) Lists exported successfully`);
        }
      } catch (error) {
        toast.error(error.message);
        console.log(error.message);
      }
    };

    const handleLabelExport = async () => {
      try {
        const labelsCollectionRef = collection(db, "users", userId, "labels");
        const labelsSnapshot = await getDocs(labelsCollectionRef);

        const headers = ["id", "name", "created_at", "color"];

        const timestampHeaders = ["created_at"];
        const csv = [headers.join(",")];

        if (!labelsSnapshot.empty) {
          const labelsArray = labelsSnapshot.docs.map((labelDoc) =>
            labelDoc.data()
          );

          labelsArray.forEach((label) => {
            const row = headers.map((header) => {
              if (
                timestampHeaders.includes(header) &&
                label[header] &&
                label[header].seconds
              ) {
                const date = new Date(label[header].seconds * 1000);
                return date.toISOString();
              }
              return label[header] ? label[header].toString() : "";
            });
            csv.push(row.join(","));
          });

          const csvString = csv.join("\r\n");

          const blob = new Blob([csvString], { type: "text/csv" });
          const href = URL.createObjectURL(blob);

          const link = document.createElement("a");
          link.href = href;
          link.download = "ellie-labels-export.csv";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          toast.success(`(${labelsArray.length}) Labels exported successfully`);
        }
      } catch (error) {
        toast.error(error.message);
        console.log(error.message);
      }
    };

    return (
      <div>
        {!activeExport && (
          <div className="flex flex-col gap-4">
            <div>
              <SettingsBackButton onClick={() => setActiveCard(null)} />
            </div>
            <ExportCard
              title={"Export Tasks"}
              description={"Export all your tasks as a CSV file"}
              onClick={() => handleTaskExport()}
              icon={<TbSquareRoundedCheck className="w-5 h-5" />}
            />
            <ExportCard
              onClick={() => handleListExport()}
              title={"Export Lists"}
              description={"Export all your lists as a CSV file"}
              icon={<TbLayoutList className="w-5 h-5" />}
            />
            <ExportCard
              onClick={() => handleLabelExport()}
              title={"Export labels"}
              description={"Export all your labels as a CSV file"}
              icon={<TbTag className="w-5 h-5" />}
            />
          </div>
        )}
      </div>
    );
  };
  const Delete = () => {
    const [password, setPassword] = useState("");

    const auth = getAuth();
    const user = auth.currentUser;

    const handleDelete = async () => {
      try {
        var credential = EmailAuthProvider.credential(user.email, password);

        await reauthenticateWithCredential(user, credential);

        await deleteUser(user);
      } catch (error) {
        toast.error(error.message);
      }
    };

    return (
      <div className="flex flex-col gap-2 items-start">
        <div>
          <SettingsBackButton onClick={() => setActiveCard(null)} />
        </div>

        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-2">
            <div className="font-semibold text-lg">🧨 Delete entire account</div>
            <div>
             Ellie will delete all of your data and you will no longer be able to access it. We recommend exporting your data before deleting your account.
            </div>
          </div>

          <div className=" text-red-600 bg-red-100/40 dark:bg-red-500/20 dark:text-red-500 py-2 px-3 text-center rounded-lg text-sm">
            <b>Warning:</b> This action cannot be undone.
          </div>

          <div className="flex flex-col gap-6 items-start">
            <div className="flex flex-col gap-2">
              <label
                htmlFor="confirmationPassword"
                className="text-neutral-400 "
              >
                Password
              </label>
              <input
                type="password"
                name="confirmationPassword"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                className="settings-item-input"
                placeholder="Enter your password"
              />
            </div>

            <button
              className="bg-red-600 hover:bg-red-700 text-white px-4 py-2 rounded-lg"
              onClick={() => handleDelete()}
            >
              Delete Account
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col gap-4">
      {!activeCard && (
        <>
          <Card
            title={"Import Data"}
            description={"Import data from other apps into Ellie"}
            onClick={() => {
              setActiveCard("import");
            }}
            buttonTitle={"Import data"}
            icon={<TbTableImport className="w-5 h-5" />}
          />
          <Card
            title={"Export Data"}
            description={"Export your data from Ellie"}
            onClick={() => {
              setActiveCard("export");
            }}
            buttonTitle={"Export data"}
            icon={<TbTableExport className="w-5 h-5" />}
          />
          <Card
            title={"Delete Account"}
            description={"Delete your account and all your data"}
            onClick={() => {
              setActiveCard("delete");
            }}
            buttonTitle={"Delete account"}
            icon={<TiWarningOutline className="w-5 h-5" />}
          />
        </>
      )}
      {activeCard === "import" && <Import />}
      {activeCard === "export" && <Export />}
      {activeCard === "delete" && <Delete />}
    </div>
  );
}

export default AccountData;
