// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";
import { getStorage } from "firebase/storage";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCy0i7hLzkBazliQDNiJcntMngeqtx6nto",
  authDomain: "ellie-18430.firebaseapp.com",
  projectId: "ellie-18430",
  storageBucket: "ellie-18430.appspot.com",
  messagingSenderId: "591669685672",
  appId: "1:591669685672:web:6f75a902fc7887591b019d",
  measurementId: "G-NELZS63PDV",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const db = getFirestore();
export const storage = getStorage(app);

export const functions = getFunctions(app);

// Uncomment this line to connect to the emulator
//connectFunctionsEmulator(functions, "localhost", 5001);
