import "./Sidebar.css";
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect, useRef, useCallback } from "react";
import { ChevronRightIcon, ArrowSmLeftIcon } from "@heroicons/react/24/outline";

import AddTaskCard from "../Kanban/Column/AddTaskCard";

import Card from "../Kanban/Card";

import {
  updateCurrentUser,
  setColumnSelected,
  updatePowerFtux,
} from "../../redux/appSlice";

import CreateCard from "../Kanban/Card/CreateCard";
import { Resizable } from "re-resizable";
import { useSpring, animated as a } from "react-spring";
import { useHotkeys } from "react-hotkeys-hook";

import logo from "../../images/ellie-solid.svg";
import logoLight from "../../images/logo-light.svg";
import BraindumpBody from "./BraindumpBody";

import { analytics, isElectron } from "../../utils";
import { TbList, TbSelector, TbTargetArrow } from "react-icons/tb";
import { isDesktopApp } from "@todesktop/client-core/platform/todesktop";
import {
  HiArrowRight,
  HiCheck,
  HiOutlinePlusCircle,
  HiPlus,
  HiPlusCircle,
  HiSelector,
  HiCog,
  HiPencil,
  HiOutlineCog,
  HiOutlineCollection,
  HiTrash,
} from "react-icons/hi";

import { TrashIcon, PencilIcon } from "@heroicons/react/24/outline";

import { FiMoreHorizontal } from "react-icons/fi";
import { Popover, Dropdown, Menu, Tooltip } from "antd";
import { AiOutlineEdit, AiOutlinePlusCircle } from "react-icons/ai";
import List from "./List";
import { set } from "lodash";
import { BiListPlus, BiPlusCircle } from "react-icons/bi";
import { BsArrowRightCircle } from "react-icons/bs";
import { FiSettings } from "react-icons/fi";

import CreateList from "./Lists/CreateList";
import { deleteList, softListDelete } from "../../redux/tasksSlice";
import MiniList from "./MiniList";
import { getOSPlatform } from "@todesktop/client-core/platform/os";
import DeadlineLists from "./DueDates/DeadlineLists";

import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { Sortable } from "@dnd-kit/sortable";
import SortableMiniListContainer from "./SortableMiniListContainer";

function Sidebar({
  onDragEnd,
  saveOrder,
  mobile = false,
  taskOrder = {},
  sortedLists = [],
  activelyReorderingSidebar = null,
}) {
  const [navigatedViaKeyboard, setNavigatedViaKeyboard] = useState(false);

  const due_date_settings = useSelector(
    (state) =>
      state.app.currentUser.power_feature_settings?.due_dates || {
        enabled: false,
        // Settings to determine when to show indicator
        number_of_days_to_show_indicator: 7,
      }
  );

  const columnSelected = useSelector((state) => state.app.columnSelected);

  const tasks = useSelector((state) => state.tasks.data);

  const lists = useSelector((state) => state.tasks.lists || {});

  const selectedList = useSelector(
    (state) => state.app.currentUser?.selectedList || "brain_dump"
  );

  const [listSelectorActive, setListSelectorActive] = useState(false);

  const [listCreateActive, setListCreateActive] = useState(false);
  const [listEditActive, setListEditActive] = useState(false);

  const due_date_topbar = useSelector(
    (state) => state.app.power_ftux?.due_date_topbar || false
  );

  const {
    hide_sidebar,
    sidebar_width = 290,
    user_theme = "system",
    sidebar_layout = "single_list",
    sidebar_mode = "lists",
  } = useSelector((state) => state.app.currentUser);

  const lists_expanded = useSelector(
    (state) =>
      state.app.currentUser.lists_expanded || [
        ...(Object.keys(lists) || []),
        "brain_dump",
      ]
  );

  const [listsExpanded, setListsExpanded] = useState(lists_expanded);

  /*
  useEffect(() => {
    // If it changed and is different from the current state, update it.
    if (lists_expanded !== listsExpanded) {
      setListsExpanded(lists_expanded);
    }
  }, [lists_expanded]); */

  const dispatch = useDispatch();

  const [createActive, setCreateActive] = useState(null);

  useHotkeys(
    "b",
    (e) => {
      e.preventDefault();

      // Set create active to today's date
      setCreateActive(true);

      // ... set up our own saving dialog.
    },
    {
      enabled: sidebar_layout === "single_list" && !createActive,
    },
    [createActive, columnSelected]
  );

  const sidebarSpringProps = useSpring({
    width: mobile ? "100%" : hide_sidebar ? 0 : sidebar_width || 290,
    overflow: mobile ? "visible" : hide_sidebar ? "hidden" : "visible",
  });

  const [indexSelected, setIndexSelected] = useState(null);

  useEffect(() => {
    if (createActive) {
    
      setIndexSelected(null);
    }
  }, [indexSelected, createActive, columnSelected]);



  useHotkeys(
    "shift+b",
    (e) => {
      e.preventDefault();
      setCreateActive(false)
      dispatch(setColumnSelected("brain_dump"));

      if (sidebar_layout === "all") {
      
        // select the brain dump list and set index to 0
        setIndexSelected(0);
      } else {
        // // Set create active to today's date
        setIndexSelected(0);
        dispatch(setColumnSelected("brain_dump"));
      }

      // ... set up our own saving dialog.
    },
    [columnSelected, indexSelected]
  );

  const { label_filters: activeLabels, hide_complete } = useSelector(
    (state) => state.app?.currentUser
  );

  // Function that checks if a task should be visible
  function shouldShowTask(task) {
    // If the task is complete and hide_complete is true, don't show it
    if (task.complete && hide_complete) {
      return false;
    }

    // If there are no active labels, show the task
    if (!activeLabels || activeLabels.length === 0) {
      return true;
    }

    // If there are active labels, check if task.label exists and is in activeLabels
    if (task.label) {
      return activeLabels.includes(task.label);
    } else {
      return false;
    }
  }

  useHotkeys(
    "down",
    (e) => {
      e.preventDefault();

 

      if (sidebar_layout === "all") {
        var filteredOrder = taskOrder?.[columnSelected]?.order?.filter(
          (taskId) => {
            if (tasks[taskId] && shouldShowTask(tasks[taskId])) {
              return true;
            }
            return false;
          }
        );


        if (indexSelected === null) {
          setIndexSelected(0);
        } else if (indexSelected < filteredOrder?.length) {
          if (indexSelected === filteredOrder?.length - 1) {
            var filteredSortedLists = sortedLists.filter((listId) => {
              if (lists[listId]) {
                return true;
              }
              return false;
            });

            if (filteredSortedLists.length > 0) {
              var nextList =
                filteredSortedLists[
                  filteredSortedLists.indexOf(columnSelected) + 1
                ];

        

              if (nextList) {
                dispatch(setColumnSelected(nextList));
                setIndexSelected(0);
              } else {
                return;
              }
            }
          } else {
            setNavigatedViaKeyboard(true);
            setIndexSelected(indexSelected + 1);
          }
        }
      } else {
        var filteredOrder = taskOrder?.[selectedList]?.order?.filter(
          (taskId) => {
            if (tasks[taskId] && shouldShowTask(tasks[taskId])) {
              return true;
            }
            return false;
          }
        );

        if (indexSelected === null) {
          setIndexSelected(0);
        } else if (indexSelected < filteredOrder.length - 1) {
          setNavigatedViaKeyboard(true);
          setIndexSelected(indexSelected + 1);
        }
      }
      // ... set up our own saving dialog.P
    },
    {
      enabled:
        ["brain_dump", ...sortedLists].includes(columnSelected) &&
        !createActive,
    },
    [indexSelected, columnSelected, tasks, taskOrder]
  );

  useHotkeys(
    "up",
    (e) => {
      e.preventDefault();

      if (indexSelected === null) {
        return;
      }


      if (sidebar_layout === "all") {
        if (indexSelected > 0) {
          setNavigatedViaKeyboard(true);
          setIndexSelected(indexSelected - 1);
        } else {
          if (indexSelected === 0) {
            // We are at the end of the list, so we want to select the next list

            var filteredSortedLists = sortedLists.filter((listId) => {
              if (lists[listId]) {
                return true;
              }
              return false;
            });

            if (filteredSortedLists.length > 0) {
              var nextList =
                filteredSortedLists[
                  filteredSortedLists.indexOf(columnSelected) - 1
                ] || "brain_dump";

              if (columnSelected === "brain_dump") {
                return;
              }

              var filteredOrder = taskOrder?.[nextList]?.order?.filter(
                (taskId) => {
                  if (tasks[taskId] && shouldShowTask(tasks[taskId])) {
                    return true;
                  }
                  return false;
                }
              );

              if (nextList) {
                dispatch(setColumnSelected(nextList));
                setIndexSelected(filteredOrder?.length - 1 || 0);
              }
            }
          }
        }
      } else {
        if (indexSelected > 0) {
          setNavigatedViaKeyboard(true);
          setIndexSelected(indexSelected - 1);

          var filteredOrder = taskOrder?.[selectedList]?.order?.filter(
            (taskId) => {
              if (tasks[taskId]) {
                return true;
              }
              return false;
            }
          );
        }
      }

      // ... set up our own saving dialog.
    },
    {
      enabled:
        ["brain_dump", ...sortedLists].includes(columnSelected) &&
        !createActive,
    },
    [indexSelected, columnSelected, taskOrder]
  );

  function manuallySelectColumn(newDate, index) {
    dispatch(setColumnSelected(newDate));
    setIndexSelected(index);
  }

  useEffect(() => {
    if (!columnSelected && indexSelected !== null) {
      setIndexSelected(null);
    }
  }, [columnSelected]);

  function isWindows() {
    if (isDesktopApp() && getOSPlatform() === "win32") return true;

    return false;
  }

  function getThemeFromUser(user_theme) {
    if (user_theme == "system") {
      return window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches
        ? "dark"
        : "light";
    } else {
      return user_theme;
    }
  }

  function toggleList(listId) {
    setListsExpanded((lists_expanded) =>
      lists_expanded.includes(listId)
        ? lists_expanded.filter((id) => id !== listId)
        : [...lists_expanded, listId]
    );
  }

  useEffect(() => {
    if (lists_expanded !== listsExpanded) {
      dispatch(
        updateCurrentUser({
          newValues: {
            lists_expanded: listsExpanded,
          },
          previousValues: {
            lists_expanded,
          },
        })
      );
    }
  }, [listsExpanded]);

  return (
    <a.div
      className="sidebar"
      style={sidebarSpringProps}
      onMouseMove={() => {
        if (navigatedViaKeyboard) {
          setNavigatedViaKeyboard(false);
        }
      }}
    >
      <Resizable
        size={{
          width: mobile ? "100%" : sidebar_width || 290,
          height: "100vh",
        }}
        minHeight={"100vh"}
        className="sidebar-container"
        maxWidth={mobile ? "1000px" : "400px"}
        minWidth={"290px"}
        enable={{
          top: false,
          right: mobile ? false : true,
          bottom: false,
          left: false,
          topRight: false,
          bottomRight: false,
          bottomLeft: false,
          topLeft: false,
        }}
        onResizeStop={(e, direction, ref, d) => {
          const newWidth = (sidebar_width || 290) + d.width;

          dispatch(
            updateCurrentUser({
              newValues: {
                sidebar_width: newWidth,
              },
              previousValues: {
                sidebar_width: sidebar_width || 290,
              },
            })
          );
        }}
      >
        {!mobile && (
          <div
            className={`braindump-topbar ${
              (isElectron() || isDesktopApp()) && !isWindows() && hide_sidebar
                ? "disabled"
                : ""
            } flex flex-row justify-between items-center ${
              (isElectron() || isDesktopApp()) && !isWindows() && "!justify-end"
            }`}
          >
            <img
              src={getThemeFromUser(user_theme) == "dark" ? logoLight : logo}
              alt="logo"
              className={`sidebar-logo ${
                (isElectron() || isDesktopApp()) && !isWindows()
                  ? "electron"
                  : ""
              }`}
            />

            {due_date_settings.enabled && (
              <Tooltip
                placement="bottom"
                title="✨ Due date section now available"
                open={due_date_topbar}
                onOpenChange={(open) => {
                  dispatch(
                    updatePowerFtux({
                      due_date_topbar: open,
                    })
                  );
                }}
                trigger={[]}
                zIndex={900}
              >
                <div
                  onClick={() => {
                    dispatch(
                      updatePowerFtux({
                        due_date_topbar: false,
                      })
                    );

                    analytics("Sidebar mode toggled", {
                      source: "web",
                      mode: sidebar_mode == "lists" ? "due_dates" : "lists",
                    });

                    dispatch(
                      updateCurrentUser({
                        newValues: {
                          sidebar_mode:
                            sidebar_mode == "lists" ? "due_dates" : "lists",
                        },
                        previousValues: {
                          sidebar_mode: sidebar_mode,
                        },
                      })
                    );
                  }}
                  className="topbar-toggle"
                >
                  <div
                    className={`${
                      sidebar_mode == "lists" ? "option active" : "option"
                    }`}
                  >
                    <TbList className="topbar-button-icon w-4 h-5" />
                  </div>

                  <div
                    className={
                      sidebar_mode == "due_dates" ? "option active" : "option"
                    }
                  >
                    <TbTargetArrow className="topbar-button-icon h-5 w-4" />
                  </div>
                </div>
              </Tooltip>
            )}
          </div>
        )}

        {(sidebar_mode == "lists" || !due_date_settings.enabled) && (
          <>
            {sidebar_layout == "single_list" && (
              <>
                <div className="braindump-header">
                  <div className="braindump-header-title-container">
                    <CreateList
                      active={listCreateActive || listEditActive}
                      setActive={
                        listCreateActive
                          ? setListCreateActive
                          : setListEditActive
                      }
                      mode={listCreateActive ? "create" : "edit"}
                      listToEdit={listEditActive ? lists[selectedList] : null}
                    />
                    <div className="mb-2 flex flex-row items-center justify-between w-full px-1">
                      <Popover
                        open={listSelectorActive}
                        onOpenChange={(open) => {
                          setListSelectorActive(open);
                        }}
                        content={
                          <div className="flex flex-col gap-2 w-64">
                            <div className="flex flex-col gap-1 pt-2">
                              <ListItem
                                title="Brain Dump"
                                icon={"🧠"}
                                selected={selectedList == "brain_dump"}
                                taskCount={
                                  taskOrder?.["brain_dump"]?.order?.filter(
                                    (taskId) => {
                                      if (tasks[taskId]) {
                                        return true;
                                      }
                                      return false;
                                    }
                                  ).length
                                }
                                onClick={() => {
                                  dispatch(
                                    updateCurrentUser({
                                      newValues: {
                                        selectedList: "brain_dump",
                                      },
                                      previousValues: {
                                        selectedList: selectedList,
                                      },
                                    })
                                  );
                                  setListSelectorActive(false);
                                }}
                              />

                              {lists &&
                                Object.values(lists).map((list) => {
                                  return (
                                    <ListItem
                                      title={list.title}
                                      icon={list.icon}
                                      selected={selectedList == list.id}
                                      taskCount={
                                        taskOrder?.[list.id]?.order?.filter(
                                          (taskId) => {
                                            if (tasks[taskId]) {
                                              return true;
                                            }
                                            return false;
                                          }
                                        ).length
                                      }
                                      onClick={() => {
                                        dispatch(
                                          updateCurrentUser({
                                            newValues: {
                                              selectedList: list.id,
                                            },
                                            previousValues: {
                                              selectedList: selectedList,
                                            },
                                          })
                                        );
                                        setListSelectorActive(false);
                                      }}
                                    />
                                  );
                                })}
                            </div>
                            <div
                              onClick={() => {
                                setListCreateActive(true);
                                setListSelectorActive(false);
                              }}
                              className="flex flex-col gap-0 border-t py-1 dark:border-t-neutral-700"
                            >
                              <div className="flex flex-row gap-2 items-center text-sm text-neutral-700 dark:text-neutral-400 font-medium rounded-lg cursor-pointer hover:bg-neutral-100 hover:dark:bg-neutral-600 px-3 py-2">
                                <HiPlus className="text-lg text-neutral-400" />
                                <div>New List</div>
                              </div>
                            </div>
                          </div>
                        }
                        placement="bottomLeft"
                        trigger="click"
                      >
                        <div className="flex flex-row gap-1 items-center px-1 border border-transparent rounded-md cursor-pointer hover:border hover:border-neutral-300 hover:bg-neutral-100 hover:dark:bg-neutral-700 hover:dark:border-neutral-600">
                          <div className="text-lg font-medium text-neutral-700 dark:text-neutral-200">
                            {lists[selectedList]?.icon || "🧠"}{" "}
                            {lists[selectedList]?.title || "Brain Dump"}
                          </div>
                          <TbSelector className="text-neutral-400 h-4 w-4" />
                        </div>
                      </Popover>
                      {selectedList != "brain_dump" && (
                        <Dropdown
                          menu={{
                            items: [
                              {
                                key: "1",
                                label: <div>Edit list</div>,
                                icon: <PencilIcon className="h-4 w-4" />,
                                onClick: () => {
                                  setListEditActive(true);
                                },
                              },
                              {
                                key: "2",
                                label: <div>Delete list</div>,
                                icon: <TrashIcon className="h-4 w-4" />,
                                onClick: () => {
                                  dispatch(
                                    softListDelete({
                                      list: lists[selectedList],
                                    })
                                  );

                                  dispatch(
                                    updateCurrentUser({
                                      newValues: {
                                        selectedList: "brain_dump",
                                      },
                                      previousValues: {
                                        selectedList: selectedList,
                                      },
                                    })
                                  );
                                },
                              },
                            ],
                          }}
                        >
                          <FiMoreHorizontal className="text-lg text-neutral-400 cursor-pointer" />
                        </Dropdown>
                      )}
                    </div>
                  </div>

                  <AddTaskCard setCreateActive={setCreateActive} />
                  {createActive && (
                    <CreateCard
                      setCreateActive={setCreateActive}
                      setNavigatedViaKeyboard={setNavigatedViaKeyboard}
                      listId={selectedList}
                    />
                  )}
                </div>

                <List
                  order={taskOrder?.[selectedList]?.order || []}
                  indexSelected={indexSelected}
                  manuallySelectColumn={manuallySelectColumn}
                  navigatedViaKeyboard={navigatedViaKeyboard}
                  setIndexSelected={setIndexSelected}
                  hide_sidebar={hide_sidebar}
                  listId={selectedList}
                />
              </>
            )}

            {sidebar_layout == "all" && (
              <div className="gap-2 p-3 overflow-y-auto h-full">
                <CreateList
                  active={listCreateActive || listEditActive}
                  setActive={
                    listCreateActive ? setListCreateActive : setListEditActive
                  }
                  mode={listCreateActive ? "create" : "edit"}
                  listToEdit={listEditActive ? lists[selectedList] : null}
                />
                <div className="text-neutral-700 text-base font-semibold flex flex-row justify-between items-center mb-3 px-1">
                  <span>All Lists</span>
                  <HiPlus
                    onClick={() => {
                      setListCreateActive(true);
                    }}
                    className="text-lg text-neutral-400 cursor-pointer hover:text-neutral-500"
                  />
                </div>
                <MiniList
                  order={taskOrder?.["brain_dump"]?.order || []}
                  indexSelected={indexSelected}
                  manuallySelectColumn={manuallySelectColumn}
                  navigatedViaKeyboard={navigatedViaKeyboard}
                  setIndexSelected={setIndexSelected}
                  hide_sidebar={hide_sidebar}
                  listId={"brain_dump"}
                  setNavigatedViaKeyboard={setNavigatedViaKeyboard}
                  expanded={listsExpanded.includes("brain_dump")}
                  setExpanded={(expanded) => {
                    toggleList("brain_dump");
                  }}
                  key={"brain_dump_sidebar"}
                  columnSelected={columnSelected}
                />

                <SortableContext
                  items={sortedLists}
                  strategy={verticalListSortingStrategy}
                  id="sidebar_list"
                >
                  {sortedLists.map((listId, index) => {
                    const list = lists[listId];

                    if (!list) return null;

                    return (
                      <MiniList
                        key={list.id + "_sidebar"}
                        id={listId}
                        order={taskOrder?.[list.id]?.order || []}
                        indexSelected={indexSelected}
                        manuallySelectColumn={manuallySelectColumn}
                        navigatedViaKeyboard={navigatedViaKeyboard}
                        setIndexSelected={setIndexSelected}
                        hide_sidebar={hide_sidebar}
                        listId={list.id}
                        setNavigatedViaKeyboard={setNavigatedViaKeyboard}
                        expanded={listsExpanded.includes(list.id)}
                        setExpanded={(expanded) => {
                          toggleList(list.id);
                        }}
                        activelyDragging={
                          activelyReorderingSidebar &&
                          activelyReorderingSidebar === list.id
                        }
                        columnSelected={columnSelected}
                      />
                    );
                  })}
                </SortableContext>
              </div>
            )}
          </>
        )}

        {sidebar_mode == "due_dates" && due_date_settings.enabled && (
          <DeadlineLists />
        )}
      </Resizable>
    </a.div>
  );
}

export function ListItem({ title, icon, taskCount = 0, selected, onClick }) {
  return (
    <div
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
      className="flex flex-col gap-2 cursor-pointer hover:bg-neutral-100 hover:dark:bg-neutral-600 px-3 py-1"
    >
      <div className="flex flex-row justify-between items-center">
        <div className="flex flex-row items-center gap-3">
          <div className="bg-[conic-gradient(at_bottom_right,_var(--tw-gradient-stops))] from-teal-900 via-fuchsia-200 to-slate-200 rounded-md w-8 h-8 flex items-center justify-center">
            <div className="text-md">{icon}</div>
          </div>
          <div className="flex flex-col">
            <div className="text-sm font-semibold">{title}</div>
            <div className="text-xs text-gray-500 font-medium">
              {taskCount} tasks
            </div>
          </div>
        </div>
        {selected && <HiCheck />}
      </div>
    </div>
  );
}

// Custom areEqual function to check if we should re-render
function areEqual(prev, next) {
  // Add conditions for all props that should trigger a re-render when changed
  return (
    prev.taskOrder === next.taskOrder &&
    prev.mobile === next.mobile &&
    prev.sortedLists === next.sortedLists &&
    prev.activelyReorderingSidebar === next.activelyReorderingSidebar
  );
}

export default React.memo(Sidebar, areEqual);
