import { Modal, Spin } from "antd";
import { createRef, useCallback, useEffect, useRef, useState } from "react";
import axios from "axios";
import _ from "lodash";
import RecurringInput from "../Kanban/Card/Components/RecurringInput";
import SubtaskIcon from "../Kanban/Card/Components/Subtasks/SubtaskIcon";
import NotesIcon from "../Kanban/Card/Components/NotesIcon";
import EmailIcon from "../Kanban/Card/Components/EmailIcon";
import { LoadingOutlined } from "@ant-design/icons";
import LabelSelector from "../Kanban/Card/Components/LabelSelector";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { setCardModalActive } from "../../redux/appSlice";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useHotkeys } from "react-hotkeys-hook";
import { setSearchActive } from "../../redux/appSlice";

export default function Search() {


  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const [selectedResult, setSelectedResult] = useState(0);
  const [isLoading, setIsLoading] = useState(false); // Add this line
  const [searchKey, setSearchKey] = useState(null);

  const inputRef = useRef(null);

  const searchActive = useSelector((state) => state.app.searchActive);

  const functions = getFunctions();
  const generateScopedSearchKey = httpsCallable(
    functions,
    "generateScopedSearchKey"
  );



  useEffect(() => {
    // This check is outside the async function
    if (!searchKey) {

      // Define the async function
      async function getKey() {
        try {
          const response = await generateScopedSearchKey();
          const searchKey = response.data.key;

          setSearchKey(searchKey);
        } catch (error) {
          console.error('Error getting Typesense search key:', error);
        }
      };

      // Immediately invoke the async function
      getKey();
    }
}, []);


  const search = useCallback((term, searchKey) => {
    setIsLoading(true); // Set loading to true at the start of the search

    const url =
      "https://4yr0kie1ahz6tnxwp-1.a1.typesense.net:443/collections/tasks/documents/search";
    const headers = {
      "X-TYPESENSE-API-KEY": searchKey,
    };
    const params = {
      q: term,
      query_by: "description",
      per_page: 20,
      infix: "fallback",
      sort_by: "date._seconds:desc",
    };

    if (!term || term === "") {
      setSearchResults([]);
      setIsLoading(false);
      return;
    } else {
      axios
        .get(url, { headers, params })
        .then((response) => {
          setSearchResults(response.data.hits);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        })
        .finally(() => {
          setIsLoading(false); // Set loading to false at the end of the search
        });
    }
  }, []);


  const debouncedSearch = useCallback(
    _.debounce((term) => search(term, searchKey), 200), // Debounce search function for 1000 milliseconds
    [searchKey] // No dependencies, so it only gets created once
  );

  function handleSearchInputChange(e) {
    const value = e.target.value;
    setIsLoading(true); // Set loading to true at the start of the search
    setSearchTerm(value);
    debouncedSearch(value); // Execute the debounced search function
  }

  const dispatch = useDispatch();

  useHotkeys(
    "cmd+k, ctrl+k",
    (e) => {
      e.preventDefault();

      if (searchActive) {
        dispatch(setSearchActive(false))
      } else {
        dispatch(setSearchActive(true))
      }
    },
    {
      enabled: true,
      enableOnTags: ["INPUT", "SELECT", "TEXTAREA"],
    },
    [searchActive]
  );


  const handleKeyDown = (event) => {
    if (event.keyCode === 38 && selectedResult > 0) {
      // Up arrow
      event.preventDefault();
      setSelectedResult(selectedResult - 1);
    } else if (event.keyCode === 40 && selectedResult < searchResults.length - 1) {
      // Down arrow
      event.preventDefault();
      setSelectedResult(selectedResult + 1);
    } else if (event.keyCode === 13) {
      // Enter key

      // Get the result at that index
      const result = searchResults[selectedResult];

      if(!result) return;

      if(!result.document) return;
      
      dispatch(setCardModalActive(result.document.id));
    }

    if (event.keyCode === 38 || event.keyCode === 40) {
      event.preventDefault();
      searchResultRefs.current[selectedResult]?.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  const searchResultRefs = useRef([]);

  useEffect(() => {
    setSelectedResult(0);

    searchResultRefs.current = searchResults.map((_, i) => searchResultRefs.current[i] || createRef())
  }, [searchResults]);

  return (
    <Modal
      open={searchActive}
      onCancel={() => {
        dispatch(setSearchActive(false));
      }}
      title={null}
      footer={null}
      closable={false}
      afterOpenChange={(open) => {
        if (open && inputRef.current) {
          inputRef.current.focus();
        } else {
          setSearchTerm("");
          setSearchResults([]);
          setSelectedResult(0);
        }
      }}
    >
      <div className="rounded-lg overflow-hidden">
        <div className="relative" onKeyDown={handleKeyDown}>
          <input
            type="text"
            placeholder="Search tasks"
            value={searchTerm}
            onChange={handleSearchInputChange}
            className="p-4 w-full focus:outline-none overflow-hidden rounded-lg bg-transparent"
            ref={inputRef}
          />
          {isLoading && (
            <div className="absolute right-4 top-1/2 transform -translate-y-1/2">
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                spinning={true}
              />
            </div>
          )}
        </div>
        <div
          className={`${searchResults.length > 0 ? "border-t dark:border-t-neutral-700 border-t-slate-200" : ""
            } max-h-96 overflow-y-auto`}
        >
          {searchResults &&
            searchResults.map((result, index) => {
              const isLastItem = index === searchResults.length - 1;
              const borderClass = isLastItem ? "cursor-pointer" : "border-b dark:border-stone-800 border-slate-200 cursor-pointer";
              return (
                <div key={index} className={`${borderClass}`} ref={searchResultRefs.current[index]}>
                  {result.highlight?.description?.snippet && (
                    <div
                      onMouseOver={() => {
                        setSelectedResult(index);
                      }}
                      onClick={() => {
                        if(!result.document) return;

                        
                        dispatch(setCardModalActive(result.document.id));
                      }}
                      className={`p-2 px-4 flex flex-row gap-4 justify-between ${selectedResult === index ? "dark:bg-neutral-700 bg-gray-200" : ""
                        }`}
                    >
                      <div className="flex flex-col gap-1 w-10/12">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: result.highlight.description.snippet
                              .replace(
                                /<mark>/g,
                                '<span class="dark:bg-yellow-700 bg-yellow-200">'
                              )
                              .replace(/<\/mark>/g, "</span>"),
                          }}
                          className="whitespace-normal overflow-auto break-words min-w-0 flex-shrink"
                        />
                        <div className="flex flex-wrap justify-start gap-1 search-item-container">
                          <LabelSelector
                            label={result.document?.label}
                            key={result.document.id + "_label_selector"}
                            item={result.document}
                            setLabelPickerActive={(isOpen) => {
                              // Let's do nothing here
                              if (isOpen) {
                                dispatch(
                                  setCardModalActive(result.document.id)
                                );
                              }
                            }}
                            disabled={true}
                          />
                          <SubtaskIcon
                            item={result.document}
                            action={() => { }}
                          />
                          <NotesIcon
                            item={result.document}
                            action={() => {
                              dispatch(setCardModalActive(result.document.id));
                            }}
                          />
                          <EmailIcon item={result.document} />
                        </div>
                      </div>
                      <div className="flex-shrink-0 flex-grow-0 w-2/12">
                        <div className="task-time">
                          {result.document?.date
                            ? moment(
                              generateDateFromTimestampObject(
                                result.document.date
                              )
                            ).format("MMM D, YYYY")
                            : ""}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
        </div>
      </div>
    </Modal>
  );
}

function generateDateFromTimestampObject(timestamp) {
  const date = new Date(timestamp._seconds * 1000); // The Date constructor expects milliseconds
  date.setMilliseconds(
    date.getMilliseconds() + timestamp._nanoseconds / 1000000
  ); // Convert nanoseconds to milliseconds

  return date;
}
