import "./TimeSection.css";
import { useState, useRef } from "react";

import { PlayIcon, PauseIcon } from "@heroicons/react/24/outline";

import DurationPicker from "../DurationPicker";

import { useDispatch, useSelector } from "react-redux";

import { updateTask } from "../../../../../redux/tasksSlice";
import { updateCurrentUser } from "../../../../../redux/appSlice";

import moment from "moment";

import FocusModeDurationPicker from "../../../../Mobile/Task/FocusModeDurationPicker";
import FocusModeSelector from "../../../../Mobile/Task/FocusModeSelector";
import { useHotkeys } from "react-hotkeys-hook";
import { Tooltip } from "antd";
import { StopIcon } from "@heroicons/react/24/solid";
import { analytics } from "../../../../../utils";

export default function TimeSection({
  item,
  updateTimeLocal,
  activelySelected,
  timeSectionActive,
  setTimeSectionActive,
}) {
  const { active_timer = null } = useSelector((state) => state.app.currentUser);
  const active_timer_task = useSelector(
    (state) => state.tasks?.data[active_timer?.task_id]
  );

  const activeTimerAccumulatedTime = useSelector(
    (state) => state.app.activeTimerAccumulatedTime
  );

  const [focusModeDurationPickerOpen, setFocusModeDurationPickerOpen] =
    useState(false);
  const [focusModePickerOpen, setFocusModePickerOpen] = useState(false);

  const [estimatedTimeActive, setEstimatedTimeActive] = useState(false);
  const [actualTimeActive, setActualTimeActive] = useState(false);

  const dispatch = useDispatch();

  useHotkeys(
    "cmd+e, ctrl+e",
    (e) => {
      e.preventDefault();

      if (estimatedTimeActive) {
        setEstimatedTimeActive(false);
      } else {
        // if the time section is not active, let's activate it
        if (!timeSectionActive) {
          setTimeSectionActive(true);
        }
        setEstimatedTimeActive(true);
      }

      if (actualTimeActive) {
        setActualTimeActive(false);
      }

      // ... set up our own saving dialog.
    },
    {
      enabled: activelySelected ? true : false,
      enableOnTags: ["INPUT", "SELECT", "TEXTAREA"],
    },
    [activelySelected, estimatedTimeActive, timeSectionActive, actualTimeActive]
  );

  useHotkeys(
    "cmd+b, ctrl+b",
    (e) => {
      e.preventDefault();

      if (actualTimeActive) {
        setActualTimeActive(false);
      } else {
        // if the time section is not active, let's activate it
        if (!timeSectionActive) {
          setTimeSectionActive(true);
        }
        setActualTimeActive(true);
      }

      if (estimatedTimeActive) {
        setEstimatedTimeActive(false);
      }

      // ... set up our own saving dialog.
    },
    {
      enabled: activelySelected ? true : false,
      enableOnTags: ["INPUT", "SELECT", "TEXTAREA"],
    },
    [activelySelected, actualTimeActive, timeSectionActive, estimatedTimeActive]
  );

  function enterStopwatchMode() {
    // If there is already one running, let's update the
    if (active_timer_task) {
      dispatch(
        updateTask({
          taskId: active_timer_task.id,
          currentTask: active_timer_task,
          newData: {
            actual_time:
              (active_timer_task.actual_time || 0) + activeTimerAccumulatedTime,
          },
        })
      );
    }

    const active_timer = {
      task_id: item.id,
      active: true,
      focus_time_original: 0,
      focus_time_left: 0,
      last_start_time: new Date(),
      type: "stopwatch",
    };

    analytics("Focus mode entered", {
      start_duration: active_timer.focus_time_original,
      task_id: item.id,
      type: "stopwatch",
    });

    dispatch(
      updateCurrentUser({
        newValues: { active_timer: active_timer },
        previousValues: { active_timer: null },
      })
    );
  }

  if (!timeSectionActive) {
    return null;
  }

  return (
    <div className="card-time-content" data-no-dnd="true">
      <div className="time-section">
        <div className="active-timer-container">
          <FocusModeDurationPicker
            item={item}
            isOpen={focusModeDurationPickerOpen}
            setIsOpen={setFocusModeDurationPickerOpen}
          />
          <FocusModeSelector
            item={item}
            isOpen={focusModePickerOpen}
            setIsOpen={setFocusModePickerOpen}
            setFocusModeDurationPickerOpen={setFocusModeDurationPickerOpen}
            enterStopwatchMode={enterStopwatchMode}
          />

          {active_timer &&
            active_timer_task &&
            active_timer?.task_id == item?.id && (
              <Tooltip
                title="Timer already active, check the bottom right of your screen"
                placement="top"
                enabled={false}
              >
                <PlayIcon
                  onClick={() => {
                    //   enterStopwatchMode();
                  }}
                  className="timer-play-button"
                  style={{
                    cursor: "not-allowed",
                    opacity: 0.5,
                  }}
                />
              </Tooltip>
            )}

          {(active_timer == null ||
            active_timer_task == null ||
            active_timer?.task_id != item?.id) && (
            <PlayIcon
              onClick={() => {
                enterStopwatchMode();
              }}
              className="timer-play-button"
            />
          )}
        </div>

        <div className="time-sections">
          {(!active_timer ||
            active_timer_task == null ||
            active_timer?.task_id != item?.id) && (
            <DurationPicker
              time={!isNaN(item.actual_time) ? item.actual_time : null}
              item={item}
              title="Actual"
              updateTimeLocal={
                updateTimeLocal
                  ? (newValue) => {
                      updateTimeLocal("actual_time", newValue);
                    }
                  : null
              }
              durationPickerActive={actualTimeActive}
              setDurationPickerActive={setActualTimeActive}
            />
          )}

          <DurationPicker
            time={!isNaN(item.estimated_time) ? item.estimated_time : null}
            item={item}
            title="Estimated"
            updateTimeLocal={
              updateTimeLocal
                ? (newValue) => {
                    updateTimeLocal("estimated_time", newValue);
                  }
                : null
            }
            durationPickerActive={estimatedTimeActive}
            setDurationPickerActive={setEstimatedTimeActive}
          />
        </div>
      </div>
    </div>
  );
}
