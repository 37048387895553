import { PauseIcon, PlayIcon, StopIcon } from "@heroicons/react/24/solid";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { MdMinimize } from "react-icons/md";
import { TbExternalLink } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveTimerAccumulatedTime,
  setCardModalActive,
  updateCurrentUser,
} from "../../redux/appSlice";
import { addTasks, updateTask } from "../../redux/tasksSlice";
import "./Timer.css";

import { useSpring, animated, useSpringRef, useChain } from "react-spring";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../../firebase";

export default function Timer({}) {
  const { active_timer = null } = useSelector((state) => state.app.currentUser);

  const userId = useSelector((state) => state.app.uid);
  const task = useSelector((state) => state.tasks?.data[active_timer?.task_id]);

  const { auto_stop_timer = null } = useSelector(
    (state) => state.app.currentUser
  );

  const dispatch = useDispatch();

  // The only things we care about in active_timer are "last_start_time" and "active" and "time_accumulated"

  const [time, setTime] = useState(0);

  const timerRef = useRef(null);

  const [minimized, setMinimized] = useState(false);

  // Let's pull the task from firestore
  useEffect(() => {
    if (active_timer && active_timer.task_id) {
      const unsub = onSnapshot(
        doc(db, "users", userId, "tasks", active_timer.task_id),
        (doc) => {
          // If it exists
          if (!doc.exists()) return;

          var taskTemp = {
            ...doc.data(),
            id: doc.id,
            date: doc.data().date ? doc.data().date.toDate() : null,
          };

          dispatch(
            addTasks({
              tasks: [taskTemp],
              dates: [],
              activeTimerTaskId: taskTemp.id,
            })
          );
        }
      );

      return () => {
        unsub();
      };
    }
  }, [active_timer, userId]);

  // Let's useChain to animate the timer in and out
  const springRef = useSpringRef();
  const props = useSpring({
    ref: springRef,
    from: {
      bottom: 0,
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      rowGap: 5,
    },
    to: {
      bottom: minimized ? 0 : 20,
      borderTopLeftRadius: minimized ? 10 : 22,
      borderTopRightRadius: minimized ? 10 : 22,
      borderBottomLeftRadius: minimized ? 0 : 22,
      borderBottomRightRadius: minimized ? 0 : 22,
      rowGap: minimized ? 0 : 5,
    },
  });

  const transRef = useSpringRef();
  const contentProps = useSpring({
    ref: transRef,
    from: {
      //  display: "none",
      height: 45,
      opacity: 1,
    },
    to: {
      //  display: minimized ? "none" : "flex",
      height: minimized ? 0 : 45,
      opacity: minimized ? 0 : 1,
    },
  });

  useChain([springRef, transRef], [0, 0]);

  useEffect(() => {
    if (active_timer) {
      if (active_timer?.active) {
        clearInterval(timerRef.current);
        timerRef.current = setInterval(() => {
          // Set the time to be the time_accumulated + the time since last_start_time

          setTime(
            (active_timer.time_accumulated || 0) +
              moment().diff(
                active_timer.last_start_time?.toDate
                  ? active_timer.last_start_time?.toDate()
                  : active_timer.last_start_time,
                "seconds"
              )
          );
        }, 1000);
      } else {
        // It is just paused
        setTime(active_timer.time_accumulated || 0);
        clearInterval(timerRef.current);
      }
    } else {
      clearInterval(timerRef.current);
      setTime(0);
    }
  }, [active_timer]);

  useEffect(() => {
    // Let's dispatch setActiveTimerAccumulatedTime
    dispatch(setActiveTimerAccumulatedTime(time));
  }, [time]);

  const pauseTimer = () => {
    // Let's update the timer to be inactive, and set the time_accumulated to the current time

    dispatch(
      updateCurrentUser({
        newValues: {
          active_timer: {
            ...active_timer,
            active: false,
            time_accumulated: time,
          },
        },
        previousValues: {
          active_timer: active_timer,
        },
      })
    );

    clearInterval(timerRef.current);
  };

  const startTimer = () => {
    // Let's update the timer to be active, and set the last_start_time to now
    dispatch(
      updateCurrentUser({
        newValues: {
          active_timer: {
            ...active_timer,
            active: true,
            last_start_time: new Date(),
          },
        },
        previousValues: {
          active_timer: active_timer,
        },
      })
    );
  };

  const stopTimer = () => {
    dispatch(
      updateTask({
        taskId: task.id,
        currentTask: task,
        newData: {
          actual_time: (task.actual_time || 0) + time,
        },
      })
    );

    // Let's update the timer to be active, and set the time_accumulated to the current time
    dispatch(
      updateCurrentUser({
        newValues: {
          active_timer: null,
        },
        previousValues: {
          active_timer: active_timer,
        },
      })
    );

    clearInterval(timerRef.current);
  };

  useEffect(() => {
    if (auto_stop_timer === task?.id) {
      // Let's stop the timer
      stopTimer();
      // update auto_stop_timer
      dispatch(
        updateCurrentUser({
          newValues: {
            auto_stop_timer: null,
          },
          previousValues: {
            auto_stop_timer: auto_stop_timer,
          },
        })
      );
    }
  }, [auto_stop_timer, task?.id]);

  if (!active_timer || !task) return null;

  return (
    <animated.div style={props} className="floating-timer">
      <div className="floating-timer-header">
        {!minimized && (
          <div
            onClick={() => {
              dispatch(setCardModalActive(task?.id));
            }}
            className="task-name"
          >
            {task?.description}
          </div>
        )}

        {minimized && (
          <div className="time">
            {!isNaN(time)
              ? moment.utc(time * 1000).format("HH:mm:ss")
              : "00:00:00"}
          </div>
        )}
        <div className="actions">
          {!minimized && (
            <FaChevronDown
              className="icon"
              onClick={() => {
                setMinimized(!minimized);
              }}
            />
          )}

          {minimized && (
            <FaChevronUp
              className="icon"
              onClick={() => {
                setMinimized(!minimized);
              }}
            />
          )}
        </div>
      </div>
      <animated.div style={contentProps} className="timer">
        <div className="time">
          {!isNaN(time)
            ? moment.utc(time * 1000).format("HH:mm:ss")
            : "00:00:00"}
        </div>
        <div className="timer-controls">
          {active_timer?.active ? (
            <div
              onClick={() => {
                pauseTimer();
              }}
              className="timer-control"
            >
              <PauseIcon className="icon" />
              <div>Pause</div>
            </div>
          ) : (
            <div
              onClick={() => {
                startTimer();
              }}
              className="timer-control"
            >
              <PlayIcon className="icon" />
              <div>Start</div>
            </div>
          )}
          <div
            onClick={() => {
              stopTimer();
            }}
            className="timer-control"
          >
            <StopIcon className="icon" />
            <div>Stop</div>
          </div>
        </div>
      </animated.div>
    </animated.div>
  );
}
