import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";

import { Modal } from "antd";
import { AiOutlineApple, AiOutlineEye, AiOutlinePlus } from "react-icons/ai";
import { Checkbox } from "antd";

import { updateCurrentUser, setUpgradeVisible } from "../../redux/appSlice";
import _, { set } from "lodash";
import { FcGoogle } from "react-icons/fc";
import { TbPlus } from "react-icons/tb";
import { BsPlus } from "react-icons/bs";
import { googleServerUrl } from "../../utils";
import { IoPhonePortraitOutline } from "react-icons/io5";
import { RiAppStoreFill, RiAppStoreLine } from "react-icons/ri";
import { FaAppStoreIos } from "react-icons/fa";

import calenderImageDark from "../../images/cal_integration_dark.png";
import calenderImageLight from "../../images/cal_integration.png";
import AddAppleCalModal from "./AddAppleCalModal";

export default function AddCalendar({ children }) {
  const userId = useSelector((state) => state.app.uid);

  const [connectAppleCalModal, setConnectAppleCalModal] = useState(false);
  const [modalActive, setModalActive] = useState(false);
  const subscriptionActive = useSelector(
    (state) => state.app.subscriptionActive
  );


  const { user_theme = "system" } = useSelector(
    (state) => state.app.currentUser
  );

  function getThemeFromUser(user_theme) {
    if (user_theme == "system") {
      return window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches
        ? "dark"
        : "light";
    } else {
      return user_theme;
    }
  }

  const dispatch = useDispatch();
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <AddAppleCalModal
        open={connectAppleCalModal}
        setOpen={setConnectAppleCalModal}
        setParentModalActive={setModalActive}
      />
      <Modal
        title={null}
        footer={null}
        closeIcon={null}
        closable={false}
        width={500}
        open={modalActive}
        onCancel={() => {
          setModalActive(false);
        }}
        zIndex={1050}
      >
        <div className="add-calendar-modal">
          <div className="integration-preview">
            <img
              className="integration-image"
              src={
                getThemeFromUser(user_theme) == "dark"
                  ? calenderImageDark
                  : calenderImageLight
              }
            />
            <div className="integration-title">
              Google/Apple Calendar events next to your time-boxed tasks 🤯
            </div>
          </div>
          <div className="add-calendar-container primary">
            <div className="add-calendar-text">
              <FcGoogle className="gcal-icon" />
              <span>Google Calendar</span>
            </div>
            <div
              onClick={() => {
                // Use axios to call google login in a new window
                if (!subscriptionActive) {
                  dispatch(setUpgradeVisible(true));
                } else {
                  window.open(
                    googleServerUrl + "/googleLogin?userId=" + userId
                  );
                }
              }}
              className="button"
            >
              Connect
            </div>
          </div>
          <div className="add-calendar-container primary">
            <div className="add-calendar-text">
              <AiOutlineApple className="gcal-icon" />
              <span>Apple Calendar</span>
            </div>
            <div
              onClick={() => {
                if (!subscriptionActive) {
                  dispatch(setUpgradeVisible(true));
                } else {
                  setConnectAppleCalModal(true);
                }
              }}
              className="button"
            >
              Connect
            </div>
          </div>
       
            <div className="add-calendar-container primary">
              <div className="add-calendar-text">
                <img
                  src={require("../../images/icons8-outlook-calendar-480.png")}
                  alt="outlook icon"
                  className="gcal-icon"
                />
                <span>Outlook Calendar</span>
              </div>
              <div
                onClick={() => {
                  // Use axios to call google login in a new window
                  if (!subscriptionActive) {
                    dispatch(setUpgradeVisible(true));
                  } else {
                    window.open(
                      "https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=66012393-dd4e-4e12-96cc-6a83c295b460&response_type=code&redirect_uri=https://app.ellieplanner.com/outlook-oauth&response_mode=query&scope=offline_access%20Calendars.ReadWrite%20User.Read"
                    );
                  }
                }}
                className="button"
              >
                Connect
              </div>
            </div>
     
        </div>{" "}
      </Modal>
      {!children && (
        <div
          onClick={(e) => {
            // Stop propagation of the click event
            setModalActive(true);
          }}
          className="last-sync-text"
        >
          <BsPlus className="last-sync-icon" />
          <span>Add calendar account</span>
        </div>
      )}
      {children && (
        <div
          onClick={(e) => {
            // Stop propagation of the click event
            setModalActive(true);
          }}
        >
          {children}
        </div>
      )}
    </div>
  );
}
