import "./Settings.css";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CogIcon,
  SupportIcon,
  AdjustmentsIcon,
  TagIcon,
  UserIcon,
  SparklesIcon,
  CalendarIcon,
  HeartIcon,
  HiCommandLine,
  CommandLineIcon,
  Cog6ToothIcon,
  CakeIcon,
  ChatBubbleOvalLeftEllipsisIcon,
  ChartPieIcon,
  EnvelopeOpenIcon,
  AtSymbolIcon,
  DevicePhoneMobileIcon,
  CloudArrowDownIcon,
  BoltIcon,
  RocketLaunchIcon,
  BeakerIcon,
  Battery50Icon,
  UserCircleIcon,
  SquaresPlusIcon
} from "@heroicons/react/24/solid";

import { TbPlug, TbTargetArrow } from "react-icons/tb";

import { GrMagic } from "react-icons/gr";

import Account from "./Account";
import Customize from "./Customize";
import LabelManager from "../Labels/LabelManager";
import ManageSubscription from "./ManageSubscription";
import Support from "./Support";
import Calendars from "./Calendars";

import { Default, Mobile } from "../../mediaUtils";

import { getAuth, signOut } from "firebase/auth";
import { getInitials } from "../../utils";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import { IoClose } from "react-icons/io5";
import {
  ArrowLeftOnRectangleIcon,
  EnvelopeIcon,
  LogoutIcon,
} from "@heroicons/react/24/outline";

import {
  toggleLabelManager,
  toggleSettings,
  showPersonalizationSettings,
} from "../../redux/appSlice";
import { AdjustmentsVerticalIcon } from "@heroicons/react/24/outline";
import { LifebuoyIcon } from "@heroicons/react/24/solid";
import KeyboardShortcuts from "../Generics/KeyboardShortcuts";
import { useNavigate } from "react-router-dom";
import EmailForwarding from "./EmailForwarding";
import DownloadApps from "./DownloadApps";
import { Drawer, Tooltip } from "antd";
import { FiMenu } from "react-icons/fi";
import API from "./API";
import ImportData from "./ImportData";
import PowerFeatures from "./PowerFeatures";
import DueDateSettings from "./Power Features/DueDateSettings";
import AccountData from "./AccountData";
import Integrations from "./Integrations";

export default function SettingsContent({
  toolTipActiveForTab,
  setToolTipActiveForTab,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [active, setActive] = useState("account");

  const {
    labelManagerVisible,
    settingsVisible,
    personalizationSettingsVisible,
  } = useSelector((state) => state.app);

  const { avatar_url, name } = useSelector((state) => state.app.currentUser);

  useEffect(() => {
    if (labelManagerVisible) {
      setActive("labels");
    } else if (personalizationSettingsVisible) {
      setActive("customization");
    } else {
      setActive("account");
    }
  }, [labelManagerVisible, personalizationSettingsVisible]);

  const auth = getAuth();
  const user = auth.currentUser;

  const types = {
    account: "Account Settings",
    subscription: "Membership & Billing",
    labels: "Labels",
    customization: "Customize Ellie",
    support: "Help & Support",
    calendars: "Your Calendars",
    emailForwarding: "Email Forwarding",
    apps: "Download Apps",
    importData: "Import Data",
    accountData: "Account Data",
    api: "API Token",
    "power-features": "Power Features",
    due_dates: "Due Dates",
    integrations: "Integrations",
  };

  const [shortcutsVisible, setShortcutsVisible] = useState(false);

  useEffect(() => {
    window.Canny("initChangelog", {
      appID: "637d2ce47a741d0ff12b8c61",
      position: "top",
      align: "right",
    });
  }, []);

  const [drawerVisible, setDrawerVisible] = useState(false);

  const power_feature_settings = useSelector(
    (state) =>
      state.app.currentUser.power_feature_settings || {
        analytics_enabled: true,
      }
  );

  const email_forwarding_id = useSelector(
    (state) => state.app.currentUser.email_forwarding_id || null
  );

  return (
    <div className="settings-container">
      <KeyboardShortcuts
        visible={shortcutsVisible}
        setVisible={setShortcutsVisible}
      />

      <Mobile>
        <Drawer
          placement="left"
          closable={false}
          onClose={() => {
            setDrawerVisible(false);
          }}
          open={drawerVisible}
          width={300}
          style={{
            backgroundColor: "var(--settings-sidebar-background-color)",
          }}
        >
          <div className="settings-sidebar">
            <div className="settings-sidebar-header">
              <div className="settings-sidebar-header-left">
                {avatar_url ? (
                  <img
                    className="settings-sidebar-avatar"
                    src={avatar_url}
                    alt="avatar"
                  />
                ) : (
                  <div className="settings-sidebar-avatar-initials">
                    {getInitials(name)}
                  </div>
                )}
              </div>
              <div className="settings-sidebar-header-right">
                <div className="settings-sidebar-header-right-name">{name}</div>
                <div className="settings-sidebar-header-right-email">
                  {user.email}
                </div>
              </div>
            </div>

            <div className="settings-section">
              <div className="settings-section-header">User settings</div>
              <div
                onClick={() => {
                  setActive("account");
                }}
                className={
                  "settings-sidebar-item" +
                  (active === "account" ? " active" : "")
                }
              >
                <UserCircleIcon className="settings-sidebar-icon" />
                Account Settings
              </div>
              <div
                onClick={() => {
                  setActive("subscription");
                }}
                className={
                  "settings-sidebar-item" +
                  (active === "subscription" ? " active" : "")
                }
              >
                <SparklesIcon className="settings-sidebar-icon" />
                Subscription
              </div>
            </div>

            <div className="settings-section-divider" />

            <div className="settings-section-divider" />
            <div className="settings-section">
              <div className="settings-section-header">App Settings</div>
              <div
                onClick={() => {
                  setActive("customization");
                }}
                className={
                  "settings-sidebar-item" +
                  (active === "customization" ? " active" : "")
                }
              >
                <Cog6ToothIcon className="settings-sidebar-icon" />
                General settings
              </div>
              <div
                onClick={() => {
                  setActive("calendars");
                }}
                className={
                  "settings-sidebar-item" +
                  (active === "calendars" ? " active" : "")
                }
              >
                {" "}
                <CalendarIcon className="settings-sidebar-icon" />
                Calendar accounts
              </div>

              <div
                onClick={() => {
                  setActive("labels");
                }}
                className={
                  "settings-sidebar-item" +
                  (active === "labels" ? " active" : "")
                }
              >
                <TagIcon className="settings-sidebar-icon" />
                Labels
              </div>
            </div>

            <div className="settings-section-divider" />

            <div className="settings-section">
              <div className="settings-section-header">Power features</div>

              <div
                onClick={() => {
                  setActive("power-features");
                }}
                className={
                  "settings-sidebar-item" +
                  (active === "power-features" ? " active" : "")
                }
              >
                <BoltIcon className="settings-sidebar-icon" />
                Toggle power features
              </div>

              {power_feature_settings &&
                power_feature_settings.due_dates?.enabled && (
                  <Tooltip
                    placement="left"
                    title="Setting now available ✨"
                    open={toolTipActiveForTab === "due_dates"}
                    onOpenChange={(open) => {
                      if (setToolTipActiveForTab) {
                        if (open) {
                          setToolTipActiveForTab("due_dates");
                        } else {
                          setToolTipActiveForTab(null);
                        }
                      }
                    }}
                    trigger={[]}
                  >
                    <div
                      onClick={() => {
                        setActive("due_dates");
                        if (setToolTipActiveForTab) {
                          setToolTipActiveForTab(null);
                        }
                      }}
                      className={
                        "settings-sidebar-item" +
                        (active === "due_dates" ? " active" : "")
                      }
                    >
                      <TbTargetArrow className="settings-sidebar-icon h-[18px] w-[18px]" />
                      Due dates
                    </div>
                  </Tooltip>
                )}

              {email_forwarding_id != null &&
                email_forwarding_id.length > 0 && (
                  <Tooltip
                    placement="left"
                    title="Setting now available ✨"
                    open={toolTipActiveForTab === "emailForwarding"}
                    onOpenChange={(open) => {
                      if (setToolTipActiveForTab) {
                        if (open) {
                          setToolTipActiveForTab("emailForwarding");
                        } else {
                          setToolTipActiveForTab(null);
                        }
                      }
                    }}
                    trigger={[]}
                  >
                    <div
                      onClick={() => {
                        setActive("emailForwarding");
                        if (setToolTipActiveForTab) {
                          setToolTipActiveForTab(null);
                        }
                      }}
                      className={
                        "settings-sidebar-item" +
                        (active === "emailForwarding" ? " active" : "")
                      }
                    >
                      <AtSymbolIcon className="settings-sidebar-icon" />
                      Email forwarding
                    </div>
                  </Tooltip>
                )}

              {power_feature_settings &&
                power_feature_settings.analytics_enabled && (
                  <Tooltip
                    placement="left"
                    title="Setting now available ✨"
                    open={toolTipActiveForTab === "analytics"}
                    onOpenChange={(open) => {
                      if (setToolTipActiveForTab) {
                        if (open) {
                          setToolTipActiveForTab("analytics");
                        } else {
                          setToolTipActiveForTab(null);
                        }
                      }
                    }}
                    trigger={[]}
                  >
                    <div
                      onClick={() => {
                        dispatch(toggleSettings());
                        navigate("/analytics");
                      }}
                      className={"settings-sidebar-item"}
                    >
                      <ChartPieIcon className="settings-sidebar-icon" />
                      Analytics
                    </div>
                  </Tooltip>
                )}

              {power_feature_settings &&
                power_feature_settings.api_access_enabled && (
                  <Tooltip
                    placement="left"
                    title="Setting now available ✨"
                    open={toolTipActiveForTab === "api_access"}
                    onOpenChange={(open) => {
                      if (setToolTipActiveForTab) {
                        if (open) {
                          setToolTipActiveForTab("api_access");
                        } else {
                          setToolTipActiveForTab(null);
                        }
                      }
                    }}
                    trigger={[]}
                  >
                    <div
                      onClick={() => {
                        setActive("api");
                        if (setToolTipActiveForTab) {
                          setToolTipActiveForTab(null);
                        }
                      }}
                      className={
                        "settings-sidebar-item" +
                        (active === "api" ? " active" : "")
                      }
                    >
                      <BoltIcon className="settings-sidebar-icon" />
                      API Token
                    </div>
                  </Tooltip>
                )}
            </div>

            <div className="settings-section-divider" />

            <div className="settings-section">
              <div
                onClick={() => {
                  setShortcutsVisible(true);
                }}
                className={"settings-sidebar-item"}
              >
                {" "}
                <CommandLineIcon className="settings-sidebar-icon" />
                Keyboard shortcuts
              </div>

              <a data-canny-changelog className={"settings-sidebar-item"}>
                <CakeIcon className="settings-sidebar-icon" />
                What's new
              </a>

              <div
                onClick={() => {
                  setActive("support");
                }}
                className={
                  "settings-sidebar-item" +
                  (active === "support" ? " active" : "")
                }
              >
                {" "}
                <LifebuoyIcon className="settings-sidebar-icon" />
                Help & Support
              </div>

              <a
                href="https://feedback.ellieplanner.com/feature-requests"
                target="_blank"
                className={"settings-sidebar-item"}
              >
                <ChatBubbleOvalLeftEllipsisIcon className="settings-sidebar-icon" />
                Give feedback
              </a>

              <div
                onClick={() => {
                  setActive("apps");
                }}
                className={
                  "settings-sidebar-item" + (active === "apps" ? " active" : "")
                }
              >
                {" "}
                <DevicePhoneMobileIcon className="settings-sidebar-icon" />
                Download apps
              </div>
              {/* <div
                onClick={() => {
                  setActive("importData");
                  setDrawerVisible(false);
                }}
                className={
                  "settings-sidebar-item" +
                  (active === "importData" ? " active" : "")
                }
              >
                {" "}
                <CloudArrowDownIcon className="settings-sidebar-icon" />
                Import Data
              </div> */}
              <div
                onClick={() => {
                  setActive("accountData");
                  setDrawerVisible(false);
                }}
                className={
                  "settings-sidebar-item" +
                  (active === "accountData" ? " active" : "")
                }
              >
                {" "}
                <CloudArrowDownIcon className="settings-sidebar-icon" />
                Account Data
              </div>
            </div>

            <div className="settings-section-divider" />

            <div
              onClick={async () => {
                signOut(auth)
                  .then(() => {
                    // Sign-out successful.
                  })
                  .catch((error) => {
                    // An error happened.
                  });
              }}
              className="log-out-button"
            >
              <ArrowLeftOnRectangleIcon className="log-out-icon" />
              Log out
            </div>
          </div>
        </Drawer>
      </Mobile>
      <Default>
        <div className="settings-sidebar">
          <div className="settings-sidebar-header">
            <div className="settings-sidebar-header-left">
              {avatar_url ? (
                <img
                  className="settings-sidebar-avatar"
                  src={avatar_url}
                  alt="avatar"
                />
              ) : (
                <div className="settings-sidebar-avatar-initials">
                  {getInitials(name)}
                </div>
              )}
            </div>
            <div className="settings-sidebar-header-right">
              <div className="settings-sidebar-header-right-name">{name}</div>
              <div className="settings-sidebar-header-right-email">
                {user.email}
              </div>
            </div>
          </div>

          <div className="settings-section">
            <div className="settings-section-header">User settings</div>
            <div
              onClick={() => {
                setActive("account");
              }}
              className={
                "settings-sidebar-item" +
                (active === "account" ? " active" : "")
              }
            >
              <UserCircleIcon className="settings-sidebar-icon" />
              Account Settings
            </div>
            <div
              onClick={() => {
                setActive("subscription");
              }}
              className={
                "settings-sidebar-item" +
                (active === "subscription" ? " active" : "")
              }
            >
              <SparklesIcon className="settings-sidebar-icon" />
              Subscription
            </div>
          </div>

          <div className="settings-section-divider" />

          <div className="settings-section-divider" />
          <div className="settings-section">
            <div className="settings-section-header">App Settings</div>
            <div
              onClick={() => {
                setActive("customization");
              }}
              className={
                "settings-sidebar-item" +
                (active === "customization" ? " active" : "")
              }
            >
              <Cog6ToothIcon className="settings-sidebar-icon" />
              General settings
            </div>
            <div
              onClick={() => {
                setActive("calendars");
              }}
              className={
                "settings-sidebar-item" +
                (active === "calendars" ? " active" : "")
              }
            >
              {" "}
              <CalendarIcon className="settings-sidebar-icon" />
              Calendar accounts
            </div>

            <div
              onClick={() => {
                setActive("labels");
              }}
              className={
                "settings-sidebar-item" + (active === "labels" ? " active" : "")
              }
            >
              <TagIcon className="settings-sidebar-icon" />
              Labels
            </div>

            <div
              onClick={() => {
                setActive("integrations");
              }}
              className={
                "settings-sidebar-item" +
                (active === "integrations" ? " active" : "")
              }
            >
              <SquaresPlusIcon className="settings-sidebar-icon" />
              Integrations
            </div>
          </div>

          <div className="settings-section-divider" />

          <div className="settings-section">
            <div className="settings-section-header">Power features</div>

            <div
              onClick={() => {
                setActive("power-features");
              }}
              className={
                "settings-sidebar-item" +
                (active === "power-features" ? " active" : "")
              }
            >
              <BoltIcon className="settings-sidebar-icon" />
              Toggle power features
            </div>

            {power_feature_settings &&
              power_feature_settings.due_dates?.enabled && (
                <Tooltip
                  placement="left"
                  title="Setting now available ✨"
                  open={toolTipActiveForTab === "due_dates"}
                  onOpenChange={(open) => {
                    if (setToolTipActiveForTab) {
                      if (open) {
                        setToolTipActiveForTab("due_dates");
                      } else {
                        setToolTipActiveForTab(null);
                      }
                    }
                  }}
                  trigger={[]}
                >
                  <div
                    onClick={() => {
                      setActive("due_dates");
                      if (setToolTipActiveForTab) {
                        setToolTipActiveForTab(null);
                      }
                    }}
                    className={
                      "settings-sidebar-item" +
                      (active === "due_dates" ? " active" : "")
                    }
                  >
                    <TbTargetArrow className="settings-sidebar-icon h-[18px] w-[18px]" />
                    Due dates
                  </div>
                </Tooltip>
              )}

            {email_forwarding_id != null && email_forwarding_id.length > 0 && (
              <Tooltip
                placement="left"
                title="Setting now available ✨"
                open={toolTipActiveForTab === "emailForwarding"}
                onOpenChange={(open) => {
                  if (setToolTipActiveForTab) {
                    if (open) {
                      setToolTipActiveForTab("emailForwarding");
                    } else {
                      setToolTipActiveForTab(null);
                    }
                  }
                }}
                trigger={[]}
              >
                <div
                  onClick={() => {
                    setActive("emailForwarding");
                    if (setToolTipActiveForTab) {
                      setToolTipActiveForTab(null);
                    }
                  }}
                  className={
                    "settings-sidebar-item" +
                    (active === "emailForwarding" ? " active" : "")
                  }
                >
                  <AtSymbolIcon className="settings-sidebar-icon" />
                  Email forwarding
                </div>
              </Tooltip>
            )}

            {power_feature_settings &&
              power_feature_settings.analytics_enabled && (
                <Tooltip
                  placement="left"
                  title="Setting now available ✨"
                  open={toolTipActiveForTab === "analytics"}
                  onOpenChange={(open) => {
                    if (setToolTipActiveForTab) {
                      if (open) {
                        setToolTipActiveForTab("analytics");
                      } else {
                        setToolTipActiveForTab(null);
                      }
                    }
                  }}
                  trigger={[]}
                >
                  <div
                    onClick={() => {
                      dispatch(toggleSettings());
                      navigate("/analytics");
                    }}
                    className={"settings-sidebar-item"}
                  >
                    <ChartPieIcon className="settings-sidebar-icon" />
                    Analytics
                  </div>
                </Tooltip>
              )}

            {power_feature_settings &&
              power_feature_settings.api_access_enabled && (
                <Tooltip
                  placement="left"
                  title="Setting now available ✨"
                  open={toolTipActiveForTab === "api_access"}
                  onOpenChange={(open) => {
                    if (setToolTipActiveForTab) {
                      if (open) {
                        setToolTipActiveForTab("api_access");
                      } else {
                        setToolTipActiveForTab(null);
                      }
                    }
                  }}
                  trigger={[]}
                >
                  <div
                    onClick={() => {
                      setActive("api");
                      if (setToolTipActiveForTab) {
                        setToolTipActiveForTab(null);
                      }
                    }}
                    className={
                      "settings-sidebar-item" +
                      (active === "api" ? " active" : "")
                    }
                  >
                    <BoltIcon className="settings-sidebar-icon" />
                    API Token
                  </div>
                </Tooltip>
              )}
          </div>

          <div className="settings-section-divider" />

          <div className="settings-section">
            <div
              onClick={() => {
                setShortcutsVisible(true);
              }}
              className={"settings-sidebar-item"}
            >
              {" "}
              <CommandLineIcon className="settings-sidebar-icon" />
              Keyboard shortcuts
            </div>

            <a data-canny-changelog className={"settings-sidebar-item"}>
              <CakeIcon className="settings-sidebar-icon" />
              What's new
            </a>

            <div
              onClick={() => {
                setActive("support");
              }}
              className={
                "settings-sidebar-item" +
                (active === "support" ? " active" : "")
              }
            >
              {" "}
              <LifebuoyIcon className="settings-sidebar-icon" />
              Help & Support
            </div>

            <a
              href="https://feedback.ellieplanner.com/feature-requests"
              target="_blank"
              className={"settings-sidebar-item"}
            >
              <ChatBubbleOvalLeftEllipsisIcon className="settings-sidebar-icon" />
              Give feedback
            </a>

            <div
              onClick={() => {
                setActive("apps");
              }}
              className={
                "settings-sidebar-item" + (active === "apps" ? " active" : "")
              }
            >
              {" "}
              <DevicePhoneMobileIcon className="settings-sidebar-icon" />
              Download apps
            </div>
            {/* <div
              onClick={() => {
                setActive("importData");
                setDrawerVisible(false);
              }}
              className={
                "settings-sidebar-item" +
                (active === "importData" ? " active" : "")
              }
            >
              {" "}
              <CloudArrowDownIcon className="settings-sidebar-icon" />
              Import Data
            </div> */}
            <div
              onClick={() => {
                setActive("accountData");
                setDrawerVisible(false);
              }}
              className={
                "settings-sidebar-item" +
                (active === "accountData" ? " active" : "")
              }
            >
              {" "}
              <CloudArrowDownIcon className="settings-sidebar-icon" />
              Account Data
            </div>
          </div>

          <div className="settings-section-divider" />

          <div
            onClick={async () => {
              signOut(auth)
                .then(() => {
                  // Sign-out successful.
                })
                .catch((error) => {
                  // An error happened.
                });
            }}
            className="log-out-button"
          >
            <ArrowLeftOnRectangleIcon className="log-out-icon" />
            Log out
          </div>
        </div>
      </Default>

      <div className="settings-content">
        <div className="settings-content-header">
          <Mobile>
            {(!labelManagerVisible || !personalizationSettingsVisible) && (
              <FiMenu
                className="menu-icon"
                onClick={() => setDrawerVisible(true)}
              />
            )}
          </Mobile>
          <div>{types[active]}</div>
          <Mobile>
            {(labelManagerVisible || personalizationSettingsVisible) && (
              <div
                onClick={() => {
                  if (settingsVisible) {
                    dispatch(toggleSettings());
                  }

                  if (labelManagerVisible) {
                    dispatch(toggleLabelManager());
                  }

                  if (personalizationSettingsVisible) {
                    dispatch(showPersonalizationSettings());
                  }

                  if (toolTipActiveForTab && setToolTipActiveForTab) {
                    setToolTipActiveForTab(null);
                  }
                }}
                className="close-icon-container"
              >
                <IoClose className="close-icon" />
              </div>
            )}
          </Mobile>
          <Default>
            <div
              onClick={() => {
                if (settingsVisible) {
                  dispatch(toggleSettings());
                }

                if (labelManagerVisible) {
                  dispatch(toggleLabelManager());
                }

                if (personalizationSettingsVisible) {
                  dispatch(showPersonalizationSettings());
                }

                if (toolTipActiveForTab && setToolTipActiveForTab) {
                  setToolTipActiveForTab(null);
                }
              }}
              className="close-icon-container"
            >
              <IoClose className="close-icon" />
            </div>
          </Default>
        </div>
        <div className="content">
          {active === "account" && <Account />}
          {active === "customization" && <Customize />}
          {active === "power-features" && (
            <PowerFeatures
              setToolTipActiveForTab={setToolTipActiveForTab || (() => {})}
            />
          )}
          {active === "labels" && <LabelManager />}
          {active === "support" && <Support />}
          {active === "subscription" && <ManageSubscription />}
          {active === "calendars" && <Calendars />}
          {active === "emailForwarding" && <EmailForwarding />}
          {active === "apps" && <DownloadApps />}
          {active === "api" && <API />}
          {active === "importData" && <ImportData />}
          {active === "accountData" && <AccountData />}
          {active === "due_dates" && <DueDateSettings />}
          {active === "integrations" && <Integrations />}
        </div>
      </div>
    </div>
  );
}

export function SettingsCell({ icon, title, description, onClick }) {
  return (
    <div onClick={onClick} className="account-page-cell">
      <div className="account-page-cell-left">
        {icon}
        <div className="account-page-meta">
          <div className="account-page-cell-title">{title}</div>
          <div className="account-page-cell-description">{description}</div>
        </div>
      </div>
      <ChevronRightIcon className="chevron-right-icon" />
    </div>
  );
}
