import React, { useEffect, useRef, useState } from "react";

import { useDroppable } from "@dnd-kit/core";
import { CSS } from "@dnd-kit/utilities";
import { useSortable } from "@dnd-kit/sortable";
import { useMediaQuery } from "react-responsive";

export default function SortableCard({ children, id, columnId }) {
  const {
    isDragging,
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: id });

  const isMobile = useMediaQuery({ maxWidth: 767 });


  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    position: "relative",
    zIndex: columnId === "braindump" ? (!isMobile ? 10000 : 0) : 0,
    opacity: isDragging ? 0.5 : 1,
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      aria-disabled={true}
    >
      {children}
    </div>
  );
}
