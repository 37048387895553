import { Popover } from "antd";
import React, { useEffect, useRef, useState } from "react";
import * as chrono from "chrono-node";
import { format, parse } from "date-fns";

export const formatTo12Hour = (timeString) => {
  let time;
  let endTime;

  try {
    // Check if the time string contains 'AM' or 'PM'
    if (timeString.match(/AM|PM/i)) {
      // Parse as 12-hour format
      time = parse(timeString, "h:mm aa", new Date());
    } else {
      // Parse as 24-hour format
      time = parse(timeString, "HH:mm", new Date());
    }

    endTime = format(time, "h:mm aa");
  } catch {
    endTime = "12:00 AM";
  }

  return endTime;
};

function CustomTimePicker({ time_format = "12_hour", date, value, onChange }) {
  const is12Hour = time_format === "12_hour";
  const [open, setOpen] = useState(false);
  const [inputParseError, setInputParseError] = useState(false);
  const [timeVariants, setTimeVariants] = useState([]);
  const [attemptedValue, setAttemptedValue] = useState(
    is12Hour ? formatTo12Hour(value) : value
  );
  const [selectedIndex, setSelectedIndex] = useState(0);
  const closingRef = useRef(false);

  const processTime = (time) => {
    if (time) {
      const parsedTime = chrono.parseDate(time);
      if (parsedTime) {
        var timeString = parsedTime.toLocaleTimeString("en-US", {
          hour: "2-digit",
          minute: "2-digit",
          hour12: false,
        });
        timeString = timeString.replace(/^24/, "00");

        setAttemptedValue(is12Hour ? formatTo12Hour(timeString) : timeString);

        onChange(timeString);
        setInputParseError(false);
      } else {
        setAttemptedValue(is12Hour ? formatTo12Hour(value) : value);
        setInputParseError(true);
      }
    }
  };

  const generateTimeOptions = () => {
    let times = [];
    for (let i = 0; i < 24; i++) {
      for (let j = 0; j < 2; j++) {
        let hour = i.toString().padStart(2, "0");
        let minute = j === 0 ? "00" : "30";
        times.push(`${hour}:${minute}`);
      }
    }
    return times;
  };

  useEffect(() => {
    setTimeVariants(generateTimeOptions());
  }, []);

  const handleBlur = () => {
    closingRef.current = true;
    const timer = setTimeout(() => {
      if (closingRef.current) {
        setOpen(false);
      }
    }, 100);

    return () => clearTimeout(timer);
  };

  const handleTimeDropdownClick = (time) => {
    closingRef.current = false;
    setAttemptedValue(time);

    processTime(time);
    setOpen(false);
  };

  const handleKeyPress = (e) => {
    if (e.key === "ArrowDown") {
      if (selectedIndex < timeVariants.length - 1) {
        setSelectedIndex(selectedIndex + 1);
      }
    }
    if (e.key === "ArrowUp") {
      if (selectedIndex > 0) {
        setSelectedIndex(selectedIndex - 1);
      }
    }
    if (e.key === "Enter") {
      e.preventDefault();
      processTime(timeVariants[selectedIndex]);
      setOpen(false);
    }
  };

  const handleInputChange = (e) => {
    setOpen(true);
    setSelectedIndex(0);
    setAttemptedValue(e.target.value);
    const parseTime = chrono.parseDate(e.target.value);

    // filter on fly dropdown options
    var defaultTimeOptions = generateTimeOptions();
    var filteredTimeOptions = defaultTimeOptions.filter((timeOption) =>
      timeOption.includes(e.target.value)
    );

    if (parseTime) {
      const timeString = parseTime.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      });
      if (isValidTime(timeString)) {
        setTimeVariants([timeString]);
      }
    } else if (filteredTimeOptions.length > 0) {
      setTimeVariants(filteredTimeOptions);
    } else {
      setTimeVariants([e.target.value]);
    }
  };

  const isValidTime = (time) => {
    const timePattern = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;
    return timePattern.test(time);
  };

  return (
    <Popover
      open={open}
      arrow={false}
      placement="bottom"
      trigger="click"
      disabled={true}
      destroyTooltipOnHide={true}
      content={
        <TimePickerDropdown
          options={timeVariants}
          is12Hour={is12Hour}
          formatTo12Hour={formatTo12Hour}
          setAttemptedValue={setAttemptedValue}
          handleTimeDropdownClick={handleTimeDropdownClick}
          isValidTime={isValidTime}
          selectedIndex={selectedIndex}
        />
      }
    >
      <input
        onFocus={() => setOpen(true)}
        onBlurCapture={handleBlur}
        value={attemptedValue}
        onChange={(e) => handleInputChange(e)}
        onKeyDown={handleKeyPress}
        className={`!w-full border border-neutral-400 rounded-lg px-2 py-1 text-black dark:bg-neutral-700 dark:border-neutral-600 dark:text-neutral-100 ${
          inputParseError && " outline-rose-600/90"
        }`}
      ></input>
    </Popover>
  );
}

export default CustomTimePicker;

const TimePickerDropdown = ({
  options = null,
  setAttemptedValue,
  is12Hour,
  formatTo12Hour,
  handleTimeDropdownClick,
  isValidTime,
  selectedIndex,
}) => {
  return (
    <>
      <div className="flex flex-col gap-1 w-52 max-h-32 overflow-y-auto p-1 text-sm">
        {options &&
          options.map((timeOption, index) => (
            <TimeOption
              timeOption={timeOption}
              index={index}
              setAttemptedValue={setAttemptedValue}
              is12Hour={is12Hour}
              formatTo12Hour={formatTo12Hour}
              handleTimeDropdownClick={handleTimeDropdownClick}
              isValidTime={isValidTime}
              selectedIndex={selectedIndex}
            />
          ))}
      </div>
    </>
  );
};

export const TimeOption = ({
  timeOption,
  index,
  selectedIndex,
  handleTimeDropdownClick,
  isValidTime,
  is12Hour,
}) => {
  const ref = useRef(null);
  useEffect(() => {
    if (ref && index === selectedIndex) {
      ref.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [selectedIndex, ref]);
  return (
    <div
      ref={ref}
      className={`flex items-center justify-between px-2 py-1 rounded-lg hover:bg-neutral-200 cursor-pointer dark:hover:bg-neutral-600 ${
        selectedIndex === index && "bg-neutral-200 dark:bg-neutral-600"
      }`}
      key={timeOption}
      onClick={() => handleTimeDropdownClick(timeOption)}
    >
      <span>
        {is12Hour && isValidTime(timeOption)
          ? formatTo12Hour(timeOption)
          : timeOption}
      </span>
    </div>
  );
};
