import React, { useState } from "react";
import { signInWithCustomToken, getAuth } from "firebase/auth";

export default function Valhalla() {
  const [idToken, setIdToken] = useState("");

  const handleLogin = async () => {
    try {
      const auth = getAuth();
      signInWithCustomToken(auth, idToken)
        .then((userCredential) => {
          // Signed in
          const user = userCredential.user;
          // ...
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          // ...
        });
      console.log("Logged in successfully");
    } catch (error) {
      console.error("Error logging in with custom token", error);
    }
  };

  return (
    <div>
      <input
        type="text"
        placeholder="Nothing to see :)"
        value={idToken}
        onChange={(e) => setIdToken(e.target.value)}
      />
      <button onClick={handleLogin}>Test</button>
    </div>
  );
}
