import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { analytics, getDaysDiff } from "../utils";
import moment from "moment";

import { db } from "../firebase";

import { doc, setDoc } from "firebase/firestore";

import _ from "lodash";

import { app_version } from "../utils";

const initialState = {
  dates: [],
  kanbanRefreshCursor: moment().format("YYYY-MM-DD"),
  labelManagerVisible: false,
  personalizationSettingsVisible: false,
  settingsVisible: false,
  subscriptionActive: false,
  currentUser: {
    last_rollover_date: null,
    mode: "kanban",
  },
  uid: null,
  currentUserLoaded: false,
  mobilePageActive: "tasks",
  cardModalActiveFor: null,
  eventModalActiveFor: null,
  toastVisible: null,
  upgradeVisible: false,
  columnSelected: moment().format("YYYY-MM-DD"),
  isOverCalendar: false,
  createTaskModalActive: {
    active: false,
    date: null,
  },
  latestVersion: app_version,
  activeTimerAccumulatedTime: 0,
  searchActive: false,
  quickCaptureActive: false,
  manualTriggerCounter: 0,
  power_ftux: {
    due_date_topbar: false,
  },
};

const getNextDateDetails = (type, mode) => {
  // If mode is kanban, default to 7
  if (mode === "kanban") {
    return { unit: "days", value: 7 };
  }

  switch (type) {
    case "month":
      return { unit: "months", value: 1, isSpecial: true };
    case "day":
      return { unit: "days", value: 1 };
    case "timeGridTwoDay":
      return { unit: "days", value: 2 };
    case "timeGridThreeDay":
      return { unit: "days", value: 3 };
    case "timeGridFourDay":
      return { unit: "days", value: 4 };
    case "timeGridFiveDay":
      return { unit: "days", value: 5 };
    case "timeGridSixDay":
      return { unit: "days", value: 6 };
    default:
      return { unit: "days", value: 7 };
  }
};

const getPreviousDateDetails = (type, mode) => {
  // If mode is kanban, default to 7
  if (mode === "kanban") {
    return { unit: "days", value: -7 };
  }

  switch (type) {
    case "month":
      return { unit: "months", value: -1, isSpecial: true };
    case "day":
      return { unit: "days", value: -1 };
    case "timeGridTwoDay":
      return { unit: "days", value: -2 };
    case "timeGridThreeDay":
      return { unit: "days", value: -3 };
    case "timeGridFourDay":
      return { unit: "days", value: -4 };
    case "timeGridFiveDay":
      return { unit: "days", value: -5 };
    case "timeGridSixDay":
      return { unit: "days", value: -6 };
    default:
      return { unit: "days", value: -7 };
  }
};

// Update the current user
export const updateCurrentUser = createAsyncThunk(
  "app/updateCurrentUser",
  async ({ newValues, previousValues }, { getState, rejectWithValue }) => {
    const userId = getState().app.uid;

    const newValuesCopy = _.cloneDeep(newValues);

    // Easily track if filters are being used
    if (newValues.label_filters) {
      analytics("Filters edited", {
        active: newValues.label_filters.length,
      });
    }

    try {
      await setDoc(doc(db, "users", userId), newValuesCopy, {
        merge: true,
      });

      return { newValues, previousValues };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    updatePowerFtux: (state, action) => {
      // Update the power_ftux object, but keep the existing values
      state.power_ftux = {
        ...state.power_ftux,
        ...action.payload,
      };
    },
    incrementManualTriggerCounter: (state) => {
      state.manualTriggerCounter += 1;
    },
    setUid: (state, action) => {
      state.uid = action.payload;
    },
    setSearchActive: (state, action) => {
      state.searchActive = action.payload;
    },
    setQuickCaptureActive: (state, action) => {
      state.quickCaptureActive = action.payload;
    },
    setColumnSelected: (state, action) => {
      state.columnSelected = action.payload;
    },
    selectNextColumn: (state) => {
      // Get the current columnSelected
      const current = state.columnSelected;

      if (current !== "braindump" && current !== null) {
        // Convert from string to date
        const currentDate = moment(current, "YYYY-MM-DD");

        // Increment to the next day
        const nextDate = currentDate.add(1, "days");

        // Set the new columnSelected
        state.columnSelected = nextDate.format("YYYY-MM-DD");
      }
    },
    selectPreviousColumn: (state) => {
      // Get the current columnSelected
      const current = state.columnSelected;

      if (current !== "braindump" && current !== null) {
        // Convert from string to date
        const currentDate = moment(current, "YYYY-MM-DD");

        // Increment to the previous day
        const nextDate = currentDate.subtract(1, "days");

        // Set the new columnSelected
        state.columnSelected = nextDate.format("YYYY-MM-DD");
      }
    },
    setActiveTimerAccumulatedTime: (state, action) => {
      state.activeTimerAccumulatedTime = action.payload;
    },
    setLatestVersion: (state, action) => {
      state.latestVersion = action.payload;
    },
    setUpgradeVisible: (state, action) => {
      state.upgradeVisible = action.payload;
    },
    setSubscriptionActive: (state, action) => {
      state.subscriptionActive = action.payload;
    },
    setMobilePageActive: (state, action) => {
      state.mobilePageActive = action.payload;
    },
    setCardModalActive: (state, action) => {
      state.cardModalActiveFor = action.payload;
    },
    setEventModalActive: (state, action) => {
      state.eventModalActiveFor = action.payload;
    },
    toggleLabelManager(state, action) {
      state.labelManagerVisible = !state.labelManagerVisible;
    },
    showPersonalizationSettings(state, action) {
      state.personalizationSettingsVisible =
        !state.personalizationSettingsVisible;
    },
    toggleSettings(state, action) {
      state.settingsVisible = !state.settingsVisible;
    },
    loadNextDates(state, action) {
      const { type = "week", mode = "kanban" } = action.payload;
      const { unit, value, isSpecial } = getNextDateDetails(type, mode);

      if (isSpecial && type === "month" && mode != "kanban") {
        // currentDate should be the center of the current state.dates array
        const currentDate = moment(
          state.dates[Math.floor(state.dates.length / 2)],
          "YYYY-MM-DD"
        );

        const nextMonthEnd = currentDate
          .clone()
          .add(1, "month")
          .endOf("month")
          .format("YYYY-MM-DD");
        const nextTwoWeeksFromNextMonth = moment(nextMonthEnd, "YYYY-MM-DD")
          .add(2, "weeks")
          .format("YYYY-MM-DD");

        const nextMonthStart = currentDate
          .clone()
          .add(1, "month")
          .startOf("month")
          .format("YYYY-MM-DD");

        const twoWeeksBeforeMonthStart = moment(nextMonthStart, "YYYY-MM-DD")
          .add(-2, "weeks")
          .format("YYYY-MM-DD");

        state.dates = getDaysDiff(
          twoWeeksBeforeMonthStart,
          nextTwoWeeksFromNextMonth
        );
      } else {
        const currentDate = moment(
          state.dates[Math.floor(state.dates.length / 2)],
          "YYYY-MM-DD"
        );

        // New center is
        const newCurrentDate = currentDate.add(value, unit);

        // prevDate is subtracting the value from the current date
        const prevDate = newCurrentDate
          .clone()
          .subtract(value, unit)
          .format("YYYY-MM-DD");

        // nextDate is adding the value from the current date
        const nextDate = newCurrentDate
          .clone()
          .add(value, unit)
          .format("YYYY-MM-DD");

        state.dates = getDaysDiff(prevDate, nextDate);
      }
    },
    loadPreviousDates(state, action) {
      const { type = "week", mode = "kanan" } = action.payload;
      const { unit, value, isSpecial } = getPreviousDateDetails(type, mode);

      if (isSpecial && type === "month" && mode != "kanban") {
        const currentDate = moment(
          state.dates[Math.floor(state.dates.length / 2)],
          "YYYY-MM-DD"
        );

        const prevMonthEnd = currentDate
          .clone()
          .add(-1, "month")
          .endOf("month")
          .format("YYYY-MM-DD");
        const twoWeeksAfterPreviousMonhEnd = moment(prevMonthEnd, "YYYY-MM-DD")
          .add(2, "weeks")
          .format("YYYY-MM-DD");

        const prevMonthStart = currentDate
          .clone()
          .add(-1, "month")
          .startOf("month")
          .format("YYYY-MM-DD");

        const twoWeeksBeforePrevMonth = moment(prevMonthStart, "YYYY-MM-DD")
          .add(-2, "weeks")
          .format("YYYY-MM-DD");

        state.dates = getDaysDiff(
          twoWeeksBeforePrevMonth,
          twoWeeksAfterPreviousMonhEnd
        );
      } else {
        const currentDate = moment(
          state.dates[Math.floor(state.dates.length / 2)],
          "YYYY-MM-DD"
        );

        // New center is
        const newCurrentDate = currentDate.add(value, unit);

        // prevDate is subtracting the value from the current date
        const prevDate = newCurrentDate
          .clone()
          .add(value, unit)
          .format("YYYY-MM-DD");

        // nextDate is adding the value from the current date
        const absValue = Math.abs(value);
        const nextDate = newCurrentDate
          .clone()
          .add(absValue, unit)
          .format("YYYY-MM-DD");

        state.dates = getDaysDiff(prevDate, nextDate);
      }
    },
    loadInitialDates(state, action) {
      const { type = "week", mode = "kanban" } = action.payload;

      let startDate = moment().add(-7, "days").format("YYYY-MM-DD");
      let endDate = moment().add(7, "days").format("YYYY-MM-DD");

      if (type === "month" && mode != "kanban") {
        startDate = moment()
          .startOf("month")
          .add(-14, "days")
          .format("YYYY-MM-DD");
        endDate = moment().endOf("month").add(14, "days").format("YYYY-MM-DD");
      }

      const initialDateRange = getDaysDiff(startDate, endDate);

      state.dates = initialDateRange;
    },

    loadDatesFromStartDate(state, action) {
      const { type = "week", date, mode = "kanban" } = action.payload;

      let startDate = moment(date).add(-7, "days").format("YYYY-MM-DD");
      let endDate = moment(date).add(7, "days").format("YYYY-MM-DD");

      if (type === "month" && mode != "kanban") {
        startDate = moment(date)
          .startOf("month")
          .add(-14, "days")
          .format("YYYY-MM-DD");
        endDate = moment(date)
          .endOf("month")
          .add(14, "days")
          .format("YYYY-MM-DD");
      }

      const initialDateRange = getDaysDiff(startDate, endDate);

      state.dates = initialDateRange;
    },
    changeIsOverCalendar(state, action) {
      state.isOverCalendar = action.payload;
    },
    refreshKabanCursor(state, action) {
      state.kanbanRefreshCursor = action.payload;
    },
    setCreateTaskModalActive(state, action) {
      state.createTaskModalActive = action.payload;
    },
    setCurrentUser(state, action) {
      state.currentUserLoaded = true;

      var pro_expiration_date = action.payload?.pro_meta?.pro_expiration_date;
      var pro_status = action.payload?.pro_meta?.pro_status;

      window.mixpanel.people.set({
        pro_source: action.payload?.pro_meta?.pro_source, // only reserved properties need the $
        pro_status: action.payload?.pro_meta?.pro_status, // use human-readable names
      });

      if (
        pro_expiration_date != null &&
        moment(
          pro_expiration_date.toDate
            ? pro_expiration_date.toDate()
            : pro_expiration_date
        ) > moment(new Date())
      ) {
        state.subscriptionActive = true;
      } else {
        state.subscriptionActive = false;
      }

      // If this is a lifetime user, override and set subscription to active
      if (pro_status === "lifetime") {
        state.subscriptionActive = true;
      }

      state.currentUser = action.payload;
    },
    setToastVisible(state, action) {
      const payload = action.payload;
      if (payload && payload.toastType) {
        state.toastVisible = {
          type: payload.toastType,
          message: payload.message,
        };
      } else {
        state.toastVisible = null;
      }
    },
  },
  extraReducers: {
    [updateCurrentUser.pending]: (state, action) => {
      // Let's just update the store
      const { newValues } = action.meta.arg;

      // Use lodash to merge the new values into the current user object
      state.currentUser = _.merge({}, state.currentUser, newValues);
    },
    [updateCurrentUser.rejected]: (state, action) => {
      // Roll back the update if this failed
      const { previousValues } = action.meta.arg;
      state.currentUser = { ...state.currentUser, ...previousValues };
    },
  },
});

export const {
  updatePowerFtux,
  loadInitialDates,
  loadNextDates,
  loadPreviousDates,
  loadDatesFromStartDate,
  changeIsOverCalendar,
  refreshKabanCursor,
  toggleLabelManager,
  showPersonalizationSettings,
  toggleSettings,
  setMobilePageActive,
  setCardModalActive,
  setEventModalActive,
  setUid,
  setCurrentUser,
  setToastVisible,
  setSubscriptionActive,
  setUpgradeVisible,
  setColumnSelected,
  selectNextColumn,
  selectPreviousColumn,
  setCreateTaskModalActive,
  setLatestVersion,
  setActiveTimerAccumulatedTime,
  setSearchActive,
  setQuickCaptureActive,
  incrementManualTriggerCounter,
} = appSlice.actions;
export default appSlice.reducer;
