import {
  collection,
  doc,
  getDoc,
  onSnapshot,
  updateDoc,
} from "firebase/firestore";
import React, { useEffect } from "react";
import { db } from "../../firebase";
import { toast } from "sonner";
import { useSelector } from "react-redux";
import { BiNotification } from "react-icons/bi";
import moment from "moment";
import { analytics } from "../../utils";

const jsonLogic = require("json-logic-js");

function AppNotifications() {
  const { uid } = useSelector((state) => state.app);

  async function loadNotifications() {
    try {
      const notifRef = collection(db, "notifications");
      const unsubscribe = onSnapshot(notifRef, (snapshot) => {
        snapshot.docChanges().forEach(async (change) => {
          if (change.type === "added") {
            const notificationData = change.doc.data();
            // Check if the notification should be shown to the user
            const shouldShowNotification = await shouldShowToUser(
              notificationData
            );

            if (shouldShowNotification) {
              // Show the notification to the user
              const toastId = toast(
                <div className="flex gap-2 items-center">
                  <div>
                    <img
                      alt="headshot pic"
                      src={require("../../images/chris-headshot.webp")}
                      className="rounded-full w-12 h-12 object-contain"
                    />
                  </div>
                  <div>
                    <div className="flex gap-1 items-center">
                      <h1 className="font-semibold">
                        {notificationData.title || "Hi 👋"}
                      </h1>
                    </div>
                    <h3 className="">{notificationData.description}</h3>
                    <a
                      onClick={() => {
                        console.log("clicked");
                        toast.dismiss(toastId);
                      }}
                      href={notificationData.external_link}
                      target="_blank"
                      rel="noreferrer"
                      className="text-blue-600 dark:text-blue-400"
                    >
                      Read more
                    </a>
                  </div>
                </div>,
                { duration: 3600000 }
              );

              analytics("2023 Pricing Notification Shown");

              // Mark the notification as shown by updating the user's document
              const userDocRef = doc(db, "users", uid);

              // Update the notifications_shown field with the notification ID
              const updatedNotificationsShown = {
                [notificationData.id]: true,
              };

              // Update the user document
              await updateDoc(userDocRef, {
                notifications_shown: updatedNotificationsShown,
              });
            }
          }
        });
      });

      return unsubscribe;
    } catch (error) {
      console.log("error", error);
    }
  }

  async function shouldShowToUser(notificationData) {
    try {
      if (!notificationData.id) {
        return false;
      }

      const currentDate = new Date();
      const notificationDate = notificationData.endDate.toDate();

      const userDocRef = await doc(db, "users", uid);
      const userDoc = await getDoc(userDocRef);
      const userDocData = userDoc.data();

      const rules = notificationData.rules;
      var { pro_status, pro_expiration_date } = userDocData.pro_meta;

      // format
      try {
        // Must be format of YYYY-MM-DD
        pro_expiration_date = pro_expiration_date.toDate
          ? pro_expiration_date.toDate()
          : pro_expiration_date;
        pro_expiration_date = moment(pro_expiration_date).format("YYYY-MM-DD");
      } catch (error) {
        console.log("error", error);
      }

      // Check if the notification ID exists in the notifications_shown field
      return (
        (!userDocData.notifications_shown ||
          !userDocData.notifications_shown[notificationData.id]) &&
        jsonLogic.apply(rules, { pro_status, pro_expiration_date }) &&
        currentDate < notificationDate
      );
    } catch (error) {
      console.log("error", error);
    }
  }

  useEffect(() => {
    async function loadData() {
      try {
        const unsubscribe = await loadNotifications();
        return () => {
          unsubscribe();
        };
      } catch (error) {
        console.log("error", error);
      }
    }

    loadData();
  }, []);
  return <></>;
}

export default AppNotifications;
