import { RRule, RRuleSet, rrulestr } from "rrule";
import _ from "lodash";
import posthog from "posthog-js";

export const isDev = false;

// all in one analytics capture
export const analytics = (event, properties) => {
  window.mixpanel.track(event, properties);
  posthog.capture(event, properties);
};

// Pull from package.json
const packageJson = require("../package.json");
export const app_version = packageJson.version;

const moment = require("moment");
export const getDaysDiff = (
  start_date,
  end_date,
  date_format = "YYYY-MM-DD"
) => {
  const getDateAsArray = (date) => {
    return moment(date.split(/\D+/), date_format);
  };
  const diff =
    getDateAsArray(end_date).diff(getDateAsArray(start_date), "days") + 1;
  const dates = [];
  for (let i = 0; i < diff; i++) {
    const nextDate = getDateAsArray(start_date).add(i, "day");
    dates.push(nextDate.format(date_format));
  }
  return dates;
};

// Function to get intials from a name string
// If there is no name, return a smily face
export const getInitials = (name) => {
  if (name) {
    return name
      .split(" ")
      .map((name) => name.charAt(0))
      .join("");
  } else {
    return "👋";
  }
};

export function getThemeFromUser(user_theme) {
  if (user_theme === "system") {
    return window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
      ? "dark"
      : "light";
  } else {
    return user_theme;
  }
}

// Deep clone an array of objects
export const cloneArrayOfObjects = (array) => {
  return array.map((item) => {
    return { ...item };
  });
};

export function showToast(type, message) {
  // store.dispatch(setToastVisible(type, message));
}

export const focusModeBackgrounds = {
  japan2: {
    id: "japan2",
    name: "Calm Tokyo",
    image: require("./images/japan2.png"),
    video:
      "https://player.vimeo.com/external/664028532.hd.mp4?s=1f90c6fd7f83771e09230ee113da190ed657160c&profile_id=175",
  },
  rain2: {
    id: "rain2",
    name: "Rainy Apartment",
    image: require("./images/rain2.png"),
    video:
      "https://player.vimeo.com/external/664059441.hd.mp4?s=4fd93288123d5cddcaea668e5586d50932eb3bca&profile_id=175",
  },
  fireplace1: {
    id: "fireplace1",
    name: "Cozy Fireplace",
    image: require("./images/fireplace1.png"),
    video:
      "https://player.vimeo.com/external/664059384.hd.mp4?s=808ec2cf9c204c48b852fb30b7aa1f5402c3ad37&profile_id=175",
  },
  gb2: {
    id: "gb2",
    name: "Studio Ghibli Montage",
    image: require("./images/gb1-icon.jpeg"),
    video:
      "https://player.vimeo.com/external/664058476.hd.mp4?s=f966a2c341bee045d63b38e45e3284fe6845add3&profile_id=174",
  },
  beach1: {
    id: "beach1",
    name: "Beach Cafe",
    image: require("./images/beach1.png"),
    video:
      "https://player.vimeo.com/external/664059403.hd.mp4?s=54ce55159df10b30471f6396649130294cb413c5&profile_id=175",
  },
  lofi1: {
    id: "lofi1",
    name: "Lofi Girl",
    image: require("./images/lofi1.jpeg"),
    video:
      "https://player.vimeo.com/external/664059612.hd.mp4?s=b074071973e6c5f730fc8c70dcada5becda0e462&profile_id=175",
  },
};

export const serverUrl = isDev
  ? "http://localhost:5050/stripe"
  : "https://valhalla.ellieplanner.com/stripe";

export const googleServerUrl = isDev
  ? "http://localhost:5050/calendars"
  : "https://valhalla.ellieplanner.com/calendars";

export const tasksServerUrl = isDev
  ? "http://localhost:5050/tasks"
  : "https://valhalla.ellieplanner.com/tasks";

export const v1TasksServerUrl = isDev
  ? "http://localhost:5050/api/v1/tasks"
  : "https://valhalla.ellieplanner.com/api/v1/tasks";

export const usersServerUrl = isDev
  ? "http://localhost:5050/users"
  : "https://valhalla.ellieplanner.com/users";

export const recurringTasksServerUrl = isDev
  ? "http://localhost:5050/api/v1/recurringTasks"
  : "https://valhalla.ellieplanner.com/api/v1/recurringTasks";

export const slackServerUrl = isDev
  ? "http://localhost:5050/api/v1/slack"
  : "https://valhalla.ellieplanner.com/api/v1/slack";

export const outlookServerUrl = isDev
  ? "http://localhost:5050/api/v1/calendars/outlook"
  : "https://valhalla.ellieplanner.com/api/v1/calendars/outlook";

export const isGhostTask = ({ task, recurringTasks }) => {
  if (task && task.recurring) {
    const recurringTask = recurringTasks?.[task.recurring_id];

    if (
      recurringTask &&
      (!recurringTask.branched_tasks ||
        !recurringTask.branched_tasks?.includes(task.id))
    ) {
      return true;
    }
  }

  return false;
};

export const getRecurringTaskTimeString = (recurringTask) => {
  if (!recurringTask) {
    return "";
  }

  const task_template = recurringTask.task_template;
  // Get the time of the task_template and format it to "at 9:00 AM" if it exists
  const start =
    task_template && task_template.start && task_template.start.toDate
      ? task_template.start.toDate()
      : task_template.start;

  const time = start ? ` at ${moment(start).format("h:mm A")}` : "";

  return time;
};

const FREQ_LABELS = {
  [RRule.DAILY]: "day",
  [RRule.WEEKLY]: "week",
  [RRule.MONTHLY]: "month",
  [RRule.YEARLY]: "year",
};

export const getRecurrenceText = ({
  recurringTask,
  isCustomRRule = false,
  withTime = true,
  time_format = "12_hour",
}) => {
  if (!recurringTask) {
    return "Does not repeat";
  }

  if (!recurringTask.rrule) {
    return "Does not repeat";
  }

  try {
    const recurrenceRule = rrulestr(recurringTask.rrule);

    const task_template = recurringTask.task_template;
    // Get the time of the task_template and format it to "at 9:00 AM" if it exists
    const start =
      task_template && task_template.start && task_template.start.toDate
        ? task_template.start.toDate()
        : task_template?.start || null;

    const time =
      start && withTime
        ? ` at ${moment(start).format(
            time_format === "24_hour" ? "HH:mm" : "h:mm A"
          )}`
        : "";

    if (recurrenceRule) {
      if (isCustomRRule) {
        // Construct a custom recurrence description
        let customText = "Every ";

        if (
          recurrenceRule.options.interval &&
          recurrenceRule.options.interval > 1
        ) {
          customText += `${recurrenceRule.options.interval} `;
        }

        let freqLabel = FREQ_LABELS[recurrenceRule.options.freq].toLowerCase();
        if (recurrenceRule.options.interval > 1) {
          freqLabel += "s";
        }
        customText += freqLabel;

        if (recurrenceRule.options.interval === 1) {
          customText = customText.replace(" 1 ", " ");
        }

        // Check if there is a byday and bysetpos property in the rrule options
        if (
          recurrenceRule.options.byweekday &&
          recurrenceRule.options.bysetpos
        ) {
          const weekdaysMondayFirst = [
            "Mon",
            "Tue",
            "Wed",
            "Thu",
            "Fri",
            "Sat",
            "Sun",
          ];

          const ordinal_suffix_of = (i) => {
            const j = i % 10,
              k = i % 100;
            if (j === 1 && k !== 11) {
              return i + "st";
            }
            if (j === 2 && k !== 12) {
              return i + "nd";
            }
            if (j === 3 && k !== 13) {
              return i + "rd";
            }
            return i + "th";
          };

          // Handle multiple weekdays
          const weekdayStrings = recurrenceRule.options.byweekday.map(
            (dayIndex) => weekdaysMondayFirst[dayIndex]
          );
          const formattedWeekdays = weekdayStrings
            .join(", ")
            .replace(/,([^,]*)$/, " and$1");

          customText += ` on the ${
            recurrenceRule.options.bysetpos[0] === -1
              ? "last"
              : ordinal_suffix_of(recurrenceRule.options.bysetpos[0])
          } ${formattedWeekdays}`;
        } else {
          // Let's just use the default
          customText = recurrenceRule.toText();
        }

        return customText.charAt(0).toUpperCase() + customText.slice(1) + time;
      }

      // If it is daily, then this is a daily recurrence
      if (recurrenceRule.options.freq == RRule.DAILY) {
        return "Every day" + time;
      }

      // If it is weekly, there are a few options
      if (recurrenceRule.options.freq == RRule.WEEKLY) {
        // If byweekday has 5 days, this is "every weekday"
        if (recurrenceRule.options.byweekday.length == 5) {
          return "Every weekday (Mon - Fri)" + time;
        }

        // if by weekday has 2 days, this is "every weekend day"
        if (recurrenceRule.options.byweekday.length == 2) {
          return "Every weekend day (Sat & Sun)" + time;
        }

        // If there is 1 instance, this could be either weekly or biweekly
        if (recurrenceRule.options.byweekday.length == 1) {
          // Let's get the day of the week
          let weekdayNum = recurrenceRule.options.byweekday[0];

          // If the interval is 1, it is weekly
          if (recurrenceRule.options.interval == 1) {
            return `Every week (on ${weekdaysMondayFirst[weekdayNum]})` + time;
          }

          // If the interval is 2, it is bi-weekly
          if (recurrenceRule.options.interval == 2) {
            return (
              `Every 2 weeks (on ${weekdaysMondayFirst[weekdayNum]})` + time
            );
          }
        }
      }

      // If it is monthly, it is every month
      if (
        recurrenceRule.options.freq == RRule.MONTHLY &&
        recurrenceRule.options.byweekday
      ) {
        let weekdayNum = recurrenceRule.options.byweekday[0];

        return (
          `Every month (on the ${
            recurrenceRule.options.bysetpos[0] == -1
              ? "last"
              : ordinal_suffix_of(recurrenceRule.options.bysetpos[0])
          } ${weekdaysMondayFirst[weekdayNum]})` + time
        );
      }

      // If it is yearly, it is yearly
      if (recurrenceRule.options.freq == RRule.YEARLY) {
        return (
          `Every year (on ${
            months[recurrenceRule.options.bymonth - 1]
          } ${ordinal_suffix_of(recurrenceRule.options.bymonthday[0])})` + time
        );
      }
    }

    return "Does not repeat";
  } catch (error) {
    return "Error - Contact support";
  }
};

export const handleInlineLabel = (description, labels) => {
  try {
    const regex = /\[(.*?)\]/g;
    let detectedLabels = [...description.matchAll(regex)].map(
      (match) => match[1]
    );

    if (!detectedLabels.length) {
      return { label: null, newDescription: null };
    }

    let matchingLabelIds = [];
    let uniqueMatchingLabels = new Set();

    for (const label of Object.values(labels)) {
      if (
        detectedLabels.some(
          (dl) => dl.toLowerCase() === label.name.toLowerCase()
        )
      ) {
        matchingLabelIds.push(label.id);
        uniqueMatchingLabels.add(label.name.toLowerCase()); // Note the change here as well
      }
    }

    if (uniqueMatchingLabels.size > 0) {
      const firstValidLabel = Array.from(uniqueMatchingLabels)[0];
      const labelRegex = new RegExp(`\\[${firstValidLabel}\\]`, "i");
      description = description.replace(labelRegex, "").trim();
    }

    return {
      label: matchingLabelIds[0] || null,
      newDescription: description.trim(),
    };
  } catch (error) {
    console.log("error", error);
    return { label: null, newDescription: null };
  }
};

export const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
export const weekdaysMondayFirst = [
  "Mon",
  "Tue",
  "Wed",
  "Thu",
  "Fri",
  "Sat",
  "Sun",
];
export const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const areRRulesStringsEquivalent = (rruleString1, rruleString2) => {
  try {
    const rrule1 = RRule.fromString(rruleString1);
    const rrule2 = RRule.fromString(rruleString2);

    const normalizedRRuleString1 = rrule1.toText();
    const normalizedRRuleString2 = rrule2.toText();

    return normalizedRRuleString1 === normalizedRRuleString2;
  } catch (error) {
    return false;
  }
};

export function ordinal_suffix_of(i) {
  var j = i % 10,
    k = i % 100;
  if (j == 1 && k != 11) {
    return i + "st";
  }
  if (j == 2 && k != 12) {
    return i + "nd";
  }
  if (j == 3 && k != 13) {
    return i + "rd";
  }
  return i + "th";
}

export function generateTemplateFromTask(task) {
  var template = {};

  template.description = task.description || null;
  template.label = task.label || null;
  template.estimated_time = task.estimated_time || null;
  template.start = task.start || null;
  template.notes = task.notes || null;

  template.subtasks = [];

  if (task.subtasks) {
    task.subtasks.forEach((subtask) => {
      var subtaskClone = _.cloneDeep(subtask);
      subtaskClone.complete = false;
      template.subtasks.push(subtaskClone);
    });
  }

  return template;
}

export function updateTaskToMatchTemplate(task, template) {
  task.description = template.description;
  task.label = template.label;
  task.estimated_time = template.estimated_time;
  task.subtasks = template.subtasks;
  task.notes = template.notes;

  // If there is a template start date and a task date, then update start date to be the same day as the task date
  if (template.start && task.date) {
    const templateDate = template.start.toDate
      ? moment(template.start.toDate())
      : moment(template.start);

    const taskDate = task.date.toDate
      ? moment(task.date.toDate())
      : moment(task.date);

    task.start = moment(taskDate)
      .set({
        hour: templateDate.get("hour"),
        minute: templateDate.get("minute"),
        second: templateDate.get("second"),
      })
      .toDate();

    // Also update taskDate itself to match start
    task.date = task.start;
  } else {
    task.start = template.start;
  }

  return task;
}

export function generateTaskFromTemplateAndDate(template, date) {
  var newTask = {
    ...template,
    date: date,
  };

  // If there is a template start date and a task date, then update start date to be the same day as the task date
  if (template.start && date) {
    if (template.start && date) {
      const templateDate = template.start.toDate
        ? moment(template.start.toDate())
        : moment(template.start);

      newTask.start = moment(date)
        .set({
          hour: templateDate.get("hour"),
          minute: templateDate.get("minute"),
          second: templateDate.get("second"),
        })
        .toDate();

      // Also update taskDate itself to match start
      newTask.date = newTask.start;
    }
  }

  return newTask;
}

// Function to check if OS is Mac
export function isMac() {
  return navigator.platform.toUpperCase().indexOf("MAC") >= 0;
}

// Function to check if app is electron app
export function isElectron() {
  const userAgent = navigator.userAgent.toLowerCase();

  return userAgent.indexOf(" electron/") > -1;
}

function rgbToHsl(r, g, b) {
  r = r / 255;
  g = g / 255;
  b = b / 255;

  let max = Math.max(r, g, b),
    min = Math.min(r, g, b);
  let h,
    s,
    l = (max + min) / 2;

  if (max == min) {
    h = s = 0; // achromatic
  } else {
    let d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h /= 6;
  }

  return { h: h, s: s, l: l };
}

function hexToHsl(hex) {
  let { r, g, b } = hexToRgb(hex);
  return rgbToHsl(r, g, b);
}

function colorDistance(color1, color2) {
  let color1Hsl = hexToHsl(color1);
  let color2Hsl = hexToHsl(color2);

  // Scale the differences in HSL components
  let hDist = (color1Hsl.h - color2Hsl.h) * 360; // hue is circular, so scale to degrees
  let sDist = (color1Hsl.s - color2Hsl.s) * 100; // saturation is [0, 1], so scale to percentage
  let lDist = (color1Hsl.l - color2Hsl.l) * 100; // lightness is [0, 1], so scale to percentage

  // Apply weights to components to adjust their importance (can be tweaked)
  let hWeight = 2;
  let sWeight = 1;
  let lWeight = 1;

  return Math.sqrt(
    hDist * hWeight * (hDist * hWeight) +
      sDist * sWeight * (sDist * sWeight) +
      lDist * lWeight * (lDist * lWeight)
  );
}

export function closestColorSet(color, sets) {
  let closestSet;
  let closestDistance = Infinity;

  for (let set of sets) {
    let distance = colorDistance(color, set.primary);
    if (distance < closestDistance) {
      closestDistance = distance;
      closestSet = set;
    }
  }

  return closestSet;
}

// Helper function to convert hex to RGB
function hexToRgb(hex) {
  if (typeof hex !== "string") {
    throw new Error(`Invalid color string: "${hex}"`);
  }
  let result;
  if (/^#([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.test(hex)) {
    result = /^#([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
          a: 1,
        }
      : null;
  } else if (/^#([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.test(hex)) {
    result = /^#([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
          a: parseInt(result[4], 16) / 255, // convert the alpha component to a 0-1 scale
        }
      : null;
  } else {
    throw new Error(`Invalid color string: "${hex}"`);
  }
}

export const colorSets = [
  { primary: "#8F54F6", background: "#FBEDFE", text: "#6F37CA" }, // Purple
  { primary: "#8E24AA", background: "#FBEDFE", text: "#6F37CA" }, // Purple 2
  { primary: "#9596F8", background: "#F2F2FE", text: "#5F54D4" }, // Lavender
  { primary: "#56A3DF", background: "#E6F8FE", text: "#3C76BF" }, // Blue
  { primary: "#ED7944", background: "#FAE4CB", text: "#963917" }, // Orange
  { primary: "#DA2F25", background: "#F8D7C6", text: "#9A3125" }, // Red
  { primary: "#D81B60", background: "#F8D7C6", text: "#9A3125" }, // Red 2
  { primary: "#6FC787", background: "#E4FEE5", text: "#40823E" }, // Green
  { primary: "#009688", background: "#E4FEE5", text: "#40823E" }, // Green 2
  { primary: "#E9BA4A", background: "#FDF1C4", text: "#8D662E" }, // Yellow
  { primary: "#B3B4B4", background: "#F6F4F3", text: "#707172" }, // Gray
  { primary: "#000000", background: "#F6F4F3", text: "#707172" }, // Black
  { primary: "#BF45B9", background: "#F8D7F8", text: "#764374" }, // Pink
  { primary: "#A3705B", background: "#FCEEE4", text: "#983819" }, // Brown
  { primary: "#A79B8E", background: "#F9F2F3", text: "#645C5E" }, // Graphite
  { primary: "#BDAEB1", background: "#F9F2F3", text: "#645C5E" }, // Graphite 2
  { primary: "#C9CFCA", background: "#F5F5F2", text: "#3F8256" }, // Starlight
  {
    primary: "#F3B5E0",
    background: "#FCECFA",
    text: "#A13E94",
  }, // Pastel pink
  { primary: "#057B8D", background: "#E1F8FE", text: "#387D9C" }, // Cyan
];

export const darkColorSets = [
  { primary: "#9364F1", background: "#463B60", text: "#FDF6FE" },
  { primary: "#8E24AA", background: "#463B60", text: "#FDF6FE" }, // Purple 2
  { primary: "#9596F8", background: "#3F4059", text: "#FAFBFF" }, // Lavender
  { primary: "#69AADE", background: "#344554", text: "#E4FEFE" },
  { primary: "#EF8452", background: "#593727", text: "#FAEACD" },
  { primary: "#DE3D30", background: "#642D24", text: "#F9E5D8" },
  { primary: "#D81B60", background: "#642D24", text: "#F9E5D8" }, // Red 2
  { primary: "#80CF93", background: "#334837", text: "#DEFEE5" },
  { primary: "#009688", background: "#334837", text: "#DEFEE5" }, // Green 2
  { primary: "#F1C55D", background: "#4C4021", text: "#FBF7B9" },
  { primary: "#BDBCBC", background: "#424242", text: "#F7F7F7" },
  { primary: "#000000", background: "#424242", text: "#F7F7F7" },
  { primary: "#F3AFF1", background: "#563D55", text: "#F3AFF1" },
  { primary: "#A3705B", background: "#4D3D37", text: "#FBEAD1" }, // Brown
  { primary: "#A79B8E", background: "#3F3F3F", text: "#F9F2F3" }, // Graphite
  { primary: "#BDAEB1", background: "#3F3F3F", text: "#F9F2F3" }, // Graphite 2
  { primary: "#C9CFCA", background: "#3F3F3F", text: "#F9F2F3" }, // Starlight
  {
    primary: "#F3B5E0",
    background: "#4C3C46",
    text: "#FBE9FD",
  }, // Pastel pink
  { primary: "#057B8D", background: "#2D3D42", text: "#D0FAFB" }, // Cyan
];

export function calculateDaysToDueDateText(due_date) {
  var dueDate = moment(due_date.toDate ? due_date.toDate() : due_date);

  var now = moment();

  var diff = moment.duration(dueDate.diff(now));

  var days = diff.days();

  // If the due date is today, let's return that it is due today
  const isToday = dueDate.isSame(now, "day");
  const isTomorrow = dueDate.isSame(now.clone().add(1, "day"), "day");

  if (isToday) {
    return "Due Today";
  }

  if (isTomorrow) {
    return "Due Tomorrow";
  }

  if (days >= 0) {
    // Use Math.ceil to round up to the nearest whole day
    days = Math.ceil(diff.asDays());
    return `Due in ${days} day${days > 1 ? "s" : ""}`;
  }

  if (days < 0) {
    // Use Math.ceil to round up to the nearest whole day
    days = Math.ceil(diff.asDays());
    return `Due ${Math.abs(days)} day${Math.abs(days) > 1 ? "s" : ""} ago`;
  }
}

export function calculateDaysToDueDate(due_date) {
  var dueDate = moment(due_date.toDate ? due_date.toDate() : due_date);

  var now = moment();

  var diff = moment.duration(dueDate.diff(now));

  var days;

  if (diff.asDays() >= 0) {
    // Use Math.ceil to round up to the nearest whole day
    days = Math.ceil(diff.asDays());
  } else {
    // Use Math.ceil to round up to the nearest whole day
    days = Math.ceil(diff.asDays());
  }

  return days;
}

export function getIndicatorColorForDueDate(due_date) {
  const days = calculateDaysToDueDate(due_date);

  if (days < 0) {
    // If the due date is in the past, let's return red
    return "#ff0000";
  }

  if (days === 0 || days === 1) {
    // If the due date is today, let's return orange
    return "#ff8c00";
  }

  if (days === 2 || days === 3) {
    // If the due date is in the next 2 days, let's return purple
    return "#8e8e8e";
  }

  return "#8e8e8e";
}

// export const hasGoogleCalendar = (calendar_accounts) => {
//   if (calendar_accounts && Object.keys(calendar_accounts).length > 0) {
//     return Object.keys(calendar_accounts).filter(
//       (account) => calendar_accounts[account].type == "google"
//     ).length;
//   } else {
//     return false;
//   }
// };

// export const hasAppleCalendar = (calendar_accounts) => {
//   if (calendar_accounts && Object.keys(calendar_accounts).length > 0) {
//     return Object.keys(calendar_accounts).filter(
//       (account) => calendar_accounts[account].type == "apple"
//     ).length;
//   } else {
//     return false;
//   }
// };

export const hasCalendarTypeInAccount = (
  calendar_accounts,
  calendarProivder
) => {
  if (calendar_accounts && Object.keys(calendar_accounts).length > 0) {
    return Object.keys(calendar_accounts).filter(
      (account) => calendar_accounts[account].type === calendarProivder
    ).length;
  } else {
    return false;
  }
};

export function calculateHeightForZoomLevel(zoomLevel) {
  // If 100, return 3.5em
  // If 110 return 4.25em
  // If 120 return 5em
  // If 90, return 2.75em

  return 2.75 + (zoomLevel - 90) / 10 / 2;
}

export const getNotionLinkForTask = (task, deeplink_disabled) => {
  try {
    if (task && task.notes) {
      const notionLinkRegex = /(https:\/\/www.notion.so\/[^\s]+)/g;
      const rawLink = task.notes.match(notionLinkRegex);
      if (rawLink && rawLink[0].includes("notion.so")) {
        if (deeplink_disabled) {
          return rawLink[0];
        }
        const deepLink = convertNotionLinkToDeepLink(rawLink[0]);
        if (deepLink) {
          return deepLink;
        } else {
          return rawLink[0];
        }
      }
    }
    return null;
  } catch (error) {
    console.error("Error getting Notion link for task:", error);
    return null;
  }
};

function convertNotionLinkToDeepLink(url) {
  const notionRegex = /https:\/\/www\.notion\.so\/(.+)/;
  const match = url.match(notionRegex);

  if (match && match[1]) {
    const notionPageId = match[1].replace(/-/g, "");
    return `notion://www.notion.so/${notionPageId}`;
  } else {
    return null;
  }
}

export const getFigmaLinkForTask = (task, deeplink_disabled) => {
  try {
    if (task && task.notes) {
      const figmaLinkRegex = /(https:\/\/www\.figma\.com\/file\/[^\s]+)/g;
      const rawLink = task.notes.match(figmaLinkRegex);
      if (rawLink && rawLink[0].includes("figma.com")) {
        if (deeplink_disabled) {
          return rawLink[0];
        }
        const deepLink = convertFigmaLinkToDeepLink(rawLink[0]);
        if (deepLink) {
          return deepLink;
        } else {
          return rawLink[0];
        }
      }
    }
    return null;
  } catch (error) {
    console.error("Error getting Figma link for task:", error);
    return null;
  }
};

function convertFigmaLinkToDeepLink(url) {
  const figmaRegex = /https:\/\/www\.figma\.com\/file\/(.+)/;
  const match = url.match(figmaRegex);

  if (match && match[1]) {
    return `figma://file/${match[1]}`;
  } else {
    return null;
  }
}

// function to get google docs links from a task
export const getGoogleDocsLinkForTask = (task, deeplink_disabled) => {
  try {
    if (task && task.notes) {
      const googleDocsLinkRegex = /(https:\/\/docs\.google\.com\/[^\s]+)/g;
      const rawLink = task.notes.match(googleDocsLinkRegex);
      if (rawLink && rawLink[0].includes("docs.google.com")) {
        if (deeplink_disabled) {
          return rawLink[0];
        }
        const deepLink = convertGoogleDocsLinkToDeepLink(rawLink[0]);
        if (deepLink) {
          return deepLink;
        } else {
          return rawLink[0];
        }
      }
    }
    return null;
  } catch (error) {
    console.error("Error getting Google Docs link for task:", error);
    return null;
  }
};

function convertGoogleDocsLinkToDeepLink(url) {
  // Replace with actual logic if Google Docs supports a deep link format
  // Currently, this is a placeholder as Google Docs might not support custom URL schemes for deep linking
  return url; // For now, just return the original URL
}

export const getSlackLinkForTask = (task, deeplink_disabled) => {
  try {
    if (task && task.notes) {
      const slackLinkRegex = /(https:\/\/[^\s]+\.slack\.com\/[^\s]+)/g;
      const rawLink = task.notes.match(slackLinkRegex);
      if (rawLink && rawLink[0].includes(".slack.com")) {
        if (deeplink_disabled) {
          return rawLink[0];
        }

        const deepLink = convertSlackLinkToDeepLink(rawLink[0]);
        if (deepLink) {
          return deepLink;
        } else {
          return rawLink[0];
        }
      }
    }
    return null;
  } catch (error) {
    console.error("Error getting Slack link for task:", error);
    return null;
  }
};

function convertSlackLinkToDeepLink(url) {
  const slackRegex = /https:\/\/[^\s]+\.slack\.com\/([^\s]+)/;
  const match = url.match(slackRegex);

  if (match && match[1]) {
    return `slack://${match[1]}`;
  } else {
    return null;
  }
}

// function to get Linear links from a task
export const getLinearLinkForTask = (task, deeplink_disabled) => {
  try {
    if (task && task.notes) {
      const linearLinkRegex = /(https:\/\/linear\.app\/[^\s]+)/g;
      const rawLink = task.notes.match(linearLinkRegex);
      if (rawLink && rawLink[0].includes("linear.app")) {
        if (deeplink_disabled) {
          return rawLink[0];
        }
        const deepLink = convertLinearLinkToDeepLink(rawLink[0]);
        if (deepLink) {
          return deepLink;
        } else {
          return rawLink[0];
        }
      }
    }
    return null;
  } catch (error) {
    console.error("Error getting Linear link for task:", error);
    return null;
  }
};

function convertLinearLinkToDeepLink(url) {
  // Replace with actual logic if Linear supports a deep link format
  // Currently, this is a placeholder as Linear might not support custom URL schemes for deep linking
  return url; // For now, just return the original URL
}

// function to get Craft Docs links from a task
export const getCraftDocsLinkForTask = (task, deeplink_disabled) => {
  try {
    if (task && task.notes) {
      const craftDocsLinkRegex = /(https:\/\/[^\s]+\.craft\.me\/[^\s]+)/g;
      const rawLink = task.notes.match(craftDocsLinkRegex);
      if (rawLink && rawLink[0].includes(".craft.me")) {
        if (deeplink_disabled) {
          return rawLink[0];
        }
        const deepLink = convertCraftDocsLinkToDeepLink(rawLink[0]);
        if (deepLink) {
          return deepLink;
        } else {
          return rawLink[0];
        }
      }
    }
    return null;
  } catch (error) {
    console.error("Error getting Craft Docs link for task:", error);
    return null;
  }
};

function convertCraftDocsLinkToDeepLink(url) {
  // Replace with actual logic if Craft Docs supports a deep link format
  // Currently, this is a placeholder as Craft Docs might not support custom URL schemes for deep linking
  return url; // For now, just return the original URL
}

export function shouldProcessCalendarEventsForUser(currentUser) {
  // If there is a Google Calendar integration, process Google Calendar events

  if (!currentUser) {
    return false;
  }

  if (
    currentUser.default_google_calendar &&
    currentUser.default_google_calendar.calendarId &&
    currentUser.default_google_calendar.accountId
  ) {
    return true;
  } else if (
    currentUser.default_outlook_calendar &&
    currentUser.default_outlook_calendar.calendarId &&
    currentUser.default_outlook_calendar.accountId
  ) {
    return true;
  }

  return false;
}
