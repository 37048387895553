import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateCurrentUser, updatePowerFtux } from "../../redux/appSlice";
import { TbTargetArrow, TbExternalLink, TbApi, TbFlag2 } from "react-icons/tb";
import { MdMailOutline } from "react-icons/md";
import { BiBarChartSquare } from "react-icons/bi";
import { Switch } from "antd";
import { analytics } from "../../utils";
import { AiOutlineApi } from "react-icons/ai";

export default function PowerFeatures({ setToolTipActiveForTab }) {
  const power_feature_settings = useSelector(
    (state) =>
      state.app.currentUser.power_feature_settings || {
        analytics_enabled: true,
        due_dates: {
          enabled: false,
          number_of_days_to_show_indicator: 7,
        },
        api_access_enabled: false,
        priority_enabled: false,
      }
  );

  // For email_forwarding, we get the setting from email_forwarding_id off of the user itself
  const email_forwarding_id = useSelector(
    (state) => state.app.currentUser.email_forwarding_id || null
  );

  const dispatch = useDispatch();

  return (
    <div>
      <div className="settings">
        <div className="flex flex-col gap-4">
          <FeatureCard
            icon={<TbTargetArrow />}
            title="Due dates"
            description="Add due dates to your tasks. This also adds a special section to the sidebar where you can see all your tasks with due dates."
            image={require("../../images/due_dates2.png")}
            learnMoreLink="https://guide.ellieplanner.com/features/tasks/due-dates"
            isActive={power_feature_settings.due_dates?.enabled || false}
            settingsToggled={() => {
              setToolTipActiveForTab("due_dates");
              dispatch(
                updatePowerFtux({
                  due_date_topbar: true,
                })
              );
            }}
            setIsActive={(checked) => {
              if (checked) {
                dispatch(
                  updateCurrentUser({
                    newValues: {
                      power_feature_settings: {
                        due_dates: {
                          enabled: checked,
                          number_of_days_to_show_indicator: 7,
                        },
                      },
                    },
                    previousValues: {
                      power_feature_settings: power_feature_settings,
                    },
                  })
                );
              } else {
                dispatch(
                  updateCurrentUser({
                    newValues: {
                      power_feature_settings: {
                        due_dates: {
                          enabled: checked,
                        },
                      },
                    },
                    previousValues: {
                      power_feature_settings: power_feature_settings,
                    },
                  })
                );
              }
            }}
          />

          <FeatureCard
            icon={<TbFlag2 />}
            title="Task Priority"
            description="Add priority flags directly on your task to easily see what's important."
            image={require("../../images/power_priority.png")}
            learnMoreLink="https://guide.ellieplanner.com/features/tasks/task-priority"
            isActive={power_feature_settings.priority_enabled}
            settingsToggled={() => {
              // setToolTipActiveForTab("api_access");
            }}
            setIsActive={(checked) => {
              // Update redux

              dispatch(
                updateCurrentUser({
                  newValues: {
                    power_feature_settings: {
                      ...power_feature_settings,
                      priority_enabled: checked,
                    },
                  },
                  previousValues: {
                    power_feature_settings: power_feature_settings,
                  },
                })
              );
            }}
          />

          <FeatureCard
            icon={<MdMailOutline />}
            title="Email forwarding"
            description="Forward emails directly into Ellie (they will be created into tasks and the emails attached directly)"
            image={require("../../images/email_forwarding2.png")}
            learnMoreLink="https://guide.ellieplanner.com/features/tasks/email-greater-than-task"
            isActive={
              email_forwarding_id != null && email_forwarding_id.length > 0
            }
            settingsToggled={() => {
              setToolTipActiveForTab("emailForwarding");
            }}
            setIsActive={(checked) => {
              // Update redux

              if (checked) {
                const id = Math.random().toString(36).substring(2, 15);

                dispatch(
                  updateCurrentUser({
                    newValues: {
                      email_forwarding_id: id,
                    },
                    previousValues: {
                      email_forwarding_id: email_forwarding_id,
                    },
                  })
                );
              } else {
                dispatch(
                  updateCurrentUser({
                    newValues: {
                      email_forwarding_id: null,
                    },
                    previousValues: {
                      email_forwarding_id: email_forwarding_id,
                    },
                  })
                );
              }
            }}
          />

          <FeatureCard
            icon={<BiBarChartSquare />}
            title="Analytics"
            description="See how you spend your time with Ellie's analytics. View things like estimated vs actual time, time spent per label, and more."
            image={require("../../images/analytics.png")}
            learnMoreLink="https://guide.ellieplanner.com/features/analytics"
            isActive={power_feature_settings.analytics_enabled}
            settingsToggled={() => {
              setToolTipActiveForTab("analytics");
            }}
            setIsActive={(checked) => {
              // Update redux

              dispatch(
                updateCurrentUser({
                  newValues: {
                    power_feature_settings: {
                      ...power_feature_settings,
                      analytics_enabled: checked,
                    },
                  },
                  previousValues: {
                    power_feature_settings: power_feature_settings,
                  },
                })
              );
            }}
          />

          <FeatureCard
            icon={<AiOutlineApi />}
            title="API Token"
            description="Enable this to allow third party apps (like Zapier) to access your Ellie account and data."
            image={require("../../images/api_power.png")}
            learnMoreLink="https://guide.ellieplanner.com/features/zapier-integration"
            isActive={power_feature_settings.api_access_enabled}
            settingsToggled={() => {
              setToolTipActiveForTab("api_access");
            }}
            setIsActive={(checked) => {
              // Update redux

              dispatch(
                updateCurrentUser({
                  newValues: {
                    power_feature_settings: {
                      ...power_feature_settings,
                      api_access_enabled: checked,
                    },
                  },
                  previousValues: {
                    power_feature_settings: power_feature_settings,
                  },
                })
              );
            }}
          />
        </div>
      </div>
    </div>
  );
}

// Feature card
export function FeatureCard({
  icon,
  title,
  description,
  image,
  learnMoreLink,
  isActive = false,
  setIsActive,
  settingsToggled,
}) {
  return (
    <div className="flex flex-row gap-4 items-center border rounded-lg border-neutral-200 dark:border-neutral-700 overflow-hidden relative py-2 pl-5 pr-2">
      <div className="flex-1 flex flex-col items-start gap-2">
        <div className="flex items-center gap-2">
          <div>{icon}</div>
          <div>
            <div className="text-base font-semibold">{title}</div>
          </div>
        </div>
        <div>{description}</div>
        <div className="flex flex-row items-center gap-4">
          <Switch
            size="medium"
            checked={isActive}
            onChange={(checked) => {
              setIsActive(checked);
              if (checked && settingsToggled) {
                analytics("Power feature toggled", {
                  source: "web",
                  feature: title,
                });
                settingsToggled();
              }
            }}
          />

          <button
            onClick={() => {
              // Link to https://guide.ellieplanner.com/features/tasks/email-greater-than-task
              // New tab
              window.open(learnMoreLink, "_blank");
            }}
            className="flex flex-row items-center gap-1 text-sm"
          >
            <span>Learn more</span> <TbExternalLink />
          </button>
        </div>
      </div>
      <div className="flex items-center justify-center rounded-md overflow-hidden shadow-sm border border-neutral-100 dark:border-neutral-700">
        <img
          src={image}
          alt={title}
          className="h-44 w-48 object-cover object-left-top"
        />
      </div>
    </div>
  );
}
