import _ from "lodash";
import moment from "moment";

// Generate a pie chart of labels and number of tasks per label
export function generatePieChartOfLabelsAndNumberOfTasksPerLabel(
  tasks,
  dates,
  labels
) {
  // Get the number of tasks per label

  // Only get tasks with the period
  const tasksWithPeriod = Object.values(tasks).filter((task) => {
    const dateString = moment(task.date).format("YYYY-MM-DD");

    return dates.includes(dateString);
  });

  const numberOfTasksPerLabel = Object.values(labels).map((label) => {
    return {
      label,
      numberOfTasks: tasksWithPeriod.filter((task) => {
        return task.label && task.label == label.id && task.complete == true;
      }).length,
      tasks: tasksWithPeriod.filter((task) => {
        return task.label && task.label == label.id && task.complete == true;
      }),
    };
  });

  // Filter out labels with no tasks
  const numberOfTasksPerLabelFiltered = numberOfTasksPerLabel.filter(
    (label) => label.numberOfTasks > 0
  );

  // Chart data need to look like this
  /*  labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
    datasets: [
      {
        label: '# of Votes',
        data: [12, 19, 3, 5, 2, 3],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ], */

  return {
    labels: numberOfTasksPerLabelFiltered.map((label) => label.label.name),
    datasets: [
      {
        label: "# of Tasks",
        data: numberOfTasksPerLabelFiltered.map((label) => label.numberOfTasks),
        backgroundColor: numberOfTasksPerLabelFiltered.map(
          (label) => label.label.color
        ),
        borderColor: numberOfTasksPerLabelFiltered.map(
          (label) => label.label.color
        ),
        borderWidth: 1,
      },
    ],
    datasetTaskData: numberOfTasksPerLabelFiltered.map((label) => label.tasks),
  };
}

// Function to get the total amount of estimate_time for a set of tasks
export function getTotalEstimatedTime(tasks, dates) {
  // Only get tasks with the period

  const tasksWithPeriod = Object.values(tasks).filter((task) => {
    const dateString = moment(task.date).format("YYYY-MM-DD");

    return dates.includes(dateString);
  });

  return secondsToHours(
    tasksWithPeriod
      .filter((task) => {
        return task.complete == true && task.estimated_time && task.label;
      })
      .reduce((acc, task) => {
        return acc + task.estimated_time;
      }, 0) || 0
  );
}

// Generate a pie chart of labels and estimated time per label
export function generatePieChartOfLabelsAndEstimatedTimePerLabel(
  tasks,
  dates,
  labels
) {
  // Get the number of tasks per label

  // Only get tasks with the period
  const tasksWithPeriod = Object.values(tasks).filter((task) => {
    const dateString = moment(task.date).format("YYYY-MM-DD");

    return dates.includes(dateString);
  });

  const estimatedTimePerLabel = Object.values(labels).map((label) => {
    return {
      label,
      estimatedTime: secondsToHours(
        tasksWithPeriod
          .filter((task) => {
            return (
              task.label &&
              task.label == label.id &&
              task.complete == true &&
              task.estimated_time
            );
          })
          .reduce((acc, task) => {
            return acc + task.estimated_time;
          }, 0)
      ),
      tasks: tasksWithPeriod.filter((task) => {
        return (
          task.label &&
          task.label == label.id &&
          task.complete == true &&
          task.estimated_time
        );
      }),
      rawSecondsData: tasksWithPeriod
        .filter((task) => {
          return (
            task.label &&
            task.label == label.id &&
            task.complete == true &&
            task.estimated_time
          );
        })
        .reduce((acc, task) => {
          return acc + task.estimated_time;
        }, 0),
    };
  });

  // Filter out labels with no tasks
  const estimatedTimePerLabelFiltered = estimatedTimePerLabel.filter(
    (label) => label.estimatedTime > 0
  );

  return {
    labels: estimatedTimePerLabelFiltered.map((label) => label.label.name),
    datasets: [
      {
        label: estimatedTimePerLabelFiltered.map((label) => label.label.name),
        data: estimatedTimePerLabelFiltered.map((label) => label.estimatedTime),
        backgroundColor: estimatedTimePerLabelFiltered.map(
          (label) => label.label.color
        ),
        borderColor: estimatedTimePerLabelFiltered.map(
          (label) => label.label.color
        ),
        rawSecondsData: estimatedTimePerLabelFiltered.map(
          (label) => label.rawSecondsData
        ),
        borderWidth: 1,
      },
    ],
    datasetTaskData: estimatedTimePerLabelFiltered.map((label) => label.tasks),
  };
}

export function transformDataForActualVsEstimated(taskData, dates, labels) {
  // Only get the tasks that have an estimated_time and actual_time
  var filteredTaskData =
    taskData &&
    Object.values(taskData).filter((task) => {
      return task.estimated_time && task.actual_time && task.complete;
    });

  filteredTaskData = _.keyBy(filteredTaskData, "id");

  // Iterate through the task data and group by date, excluding null
  const groupedByDate = _.groupBy(filteredTaskData, (task) => {
    // Get date in format YYYY-MM-DD
    const dateString = moment(task.date).format("YYYY-MM-DD");

    return dateString;
  });

  var groupedByLabel = _.groupBy(filteredTaskData, (task) => {
    return task.label;
  });

  // Delete the "null" key from the groupedByDate object
  delete groupedByDate["null"];

  // Iterate through the groupedByLabel and create an array of objects
  var estimatedData = Object.keys(groupedByLabel).map((labelId) => {
    const label = labels[labelId];

    if (!label) return;
    // We need the format {label: "label1", data: [200, 500, 250], backgroundColor: "red"}

    // Get the tasks for the label
    const tasks = groupedByLabel[labelId];

    return {
      label: `${label.name} (estimated)`,
      data: dates.map((date) => {
        // Get the tasks for the date
        const tasksForDate = groupedByDate[date];

        // If there are no tasks for the date, return 0
        if (!tasksForDate) return 0;

        // If there are tasks for the date, return the estimated_time of tasks with the label
        return secondsToHours(
          tasksForDate
            .filter((task) => task.label === labelId)
            .reduce((acc, task) => {
              return acc + task.estimated_time;
            }, 0)
        );
      }),
      rawSecondsData: dates.map((date) => {
        // Get the tasks for the date
        const tasksForDate = groupedByDate[date];

        // If there are no tasks for the date, return 0
        if (!tasksForDate) return 0;

        // If there are tasks for the date, return the estimated_time of tasks with the label
        return tasksForDate
          .filter((task) => task.label === labelId)
          .reduce((acc, task) => {
            return acc + task.estimated_time;
          }, 0);
      }),
      backgroundColor: takeHexAndDecreaseOpacityBy(label.color, 0.2),
      stack: "Estimated time stack",
      borderRadius: 6,
      tasks: dates.map((date) => {
        // Get the tasks for the date
        const tasksForDate = groupedByDate[date];

        if (!tasksForDate) return [];

        return tasksForDate.filter((task) => task.label === labelId);
      }),
    };
  });

  var actualData = Object.keys(groupedByLabel).map((labelId) => {
    const label = labels[labelId];

    if (!label) return;
    // We need the format {label: "label1", data: [200, 500, 250], backgroundColor: "red"}

    // Get the tasks for the label
    const tasks = groupedByLabel[labelId];

    return {
      label: `${label.name} (actual)`,
      data: dates.map((date) => {
        // Get the tasks for the date
        const tasksForDate = groupedByDate[date];

        // If there are no tasks for the date, return 0
        if (!tasksForDate) return 0;

        // If there are tasks for the date, return the estimated_time of tasks with the label
        return secondsToHours(
          tasksForDate
            .filter((task) => task.label === labelId)
            .reduce((acc, task) => {
              return acc + task.actual_time;
            }, 0)
        );
      }),
      rawSecondsData: dates.map((date) => {
        // Get the tasks for the date
        const tasksForDate = groupedByDate[date];

        // If there are no tasks for the date, return 0
        if (!tasksForDate) return 0;

        // If there are tasks for the date, return the estimated_time of tasks with the label
        return tasksForDate
          .filter((task) => task.label === labelId)
          .reduce((acc, task) => {
            return acc + task.actual_time;
          }, 0);
      }),
      backgroundColor: label.color,
      stack: "Actual time stack",
      borderRadius: 6,
      tasks: dates.map((date) => {
        // Get the tasks for the date
        const tasksForDate = groupedByDate[date];

        if (!tasksForDate) return [];

        return tasksForDate.filter((task) => task.label === labelId);
      }),
    };
  });

  // Remove any nulls from the array
  estimatedData = estimatedData.filter((item) => item);
  actualData = actualData.filter((item) => item);

  // For the ones without label, let's make a new group called "No label"
  const tasksWithoutLabel = groupedByLabel["null"];

  if (tasksWithoutLabel) {
    estimatedData.push({
      label: "No label (estimated)",
      data: dates.map((date) => {
        // Get the tasks for the date
        const tasksForDate = groupedByDate[date];

        // If there are no tasks for the date, return 0
        if (!tasksForDate) return 0;

        // If there are tasks for the date, return the count of tasks with the label
        return (
          tasksForDate
            .filter((task) => task.label === null)
            .reduce((acc, task) => {
              return acc + task.estimated_time;
            }, 0) / 60
        );
      }),
      backgroundColor: takeHexAndDecreaseOpacityBy("#808080", 0.2),
      stack: "Estimated time stack",
      borderRadius: 6,
      tasks: dates.map((date) => {
        // Get the tasks for the date
        const tasksForDate = groupedByDate[date];

        if (!tasksForDate) return [];

        return tasksForDate.filter((task) => task.label === null);
      }),
    });

    actualData.push({
      label: "No label (actual)",
      data: dates.map((date) => {
        // Get the tasks for the date
        const tasksForDate = groupedByDate[date];

        // If there are no tasks for the date, return 0
        if (!tasksForDate) return 0;

        // If there are tasks for the date, return the count of tasks with the label
        return (
          tasksForDate
            .filter((task) => task.label === null)
            .reduce((acc, task) => {
              return acc + task.actual_time;
            }, 0) / 60
        );
      }),
      backgroundColor: "#808080",
      stack: "Actual time stack",
      borderRadius: 6,
      tasks: dates.map((date) => {
        // Get the tasks for the date
        const tasksForDate = groupedByDate[date];

        if (!tasksForDate) return [];

        return tasksForDate.filter((task) => task.label === null);
      }),
    });
  }

  // Return the transformed data
  return {
    finalData: [...estimatedData, ...actualData] || [],
    labelsUsed: Object.keys(groupedByLabel),
  };
}

export function getNumberOfTasksCompletedThisPeriodAndLast(
  taskData,
  dates,
  lastPeriodDates
) {
  // Only get taskData that is completed

  // Get all the tasks completed during this period
  const allTasksDuringThisPeriod =
    taskData &&
    Object.values(taskData).filter((task) => {
      const dateString = moment(task.date).format("YYYY-MM-DD");

      return dates.includes(dateString) && task.complete;
    });

  // Get all the tasks completed during the last period
  const allTasksDuringLastPeriod =
    taskData &&
    Object.values(taskData).filter((task) => {
      const dateString = moment(task.date).format("YYYY-MM-DD");

      return lastPeriodDates.includes(dateString) && task.complete;
    });

  return {
    thisPeriod: allTasksDuringThisPeriod,
    lastPeriod: allTasksDuringLastPeriod,
  };
}

export function getAverageEstimateAndActualTimesThisPeriodAndLast(
  taskData,
  dates,
  lastPeriodDates
) {
  const filteredTaskData =
    taskData &&
    Object.values(taskData).filter((task) => {
      return task.estimated_time && task.actual_time && task.complete;
    });

  // Get all the tasks completed during this period
  const allTasksDuringThisPeriod =
    filteredTaskData &&
    Object.values(filteredTaskData).filter((task) => {
      const dateString = moment(task.date).format("YYYY-MM-DD");

      return dates.includes(dateString);
    });

  const thisPeriodAverageEstimateTime =
    allTasksDuringThisPeriod.length > 0 &&
    allTasksDuringThisPeriod.reduce((acc, task) => {
      return acc + task.estimated_time;
    }, 0);

  const thisPeriodAverageActualTime =
    allTasksDuringThisPeriod.length > 0 &&
    allTasksDuringThisPeriod.reduce((acc, task) => {
      return acc + task.actual_time;
    }, 0);

  // On average, you took 7% less time time than expected to complete a task
  // On average, you took 8% longer to complete a task than expected
  // To 1 decimal place
  var averageDifferenceThisPeriod =
    ((thisPeriodAverageEstimateTime - thisPeriodAverageActualTime) /
      thisPeriodAverageEstimateTime) *
    100;

  // Cut this to 1 decimale place
  averageDifferenceThisPeriod = averageDifferenceThisPeriod.toFixed(1);

  /*
    // Get all the tasks completed during the last period
    const allTasksDuringLastPeriod =
      filteredTaskData &&
      Object.values(filteredTaskData).filter((task) => {
        const dateString = moment(task.date).format("YYYY-MM-DD");
  
        return lastPeriodDates.includes(dateString);
      });
  
    const lastPeriodAverageEstimateTime = 
    const lastPeriodAverageActualTime = 
  
    const averageDifferenceLastPeriod = 
   */

  // If averageDifferenceThisPeriod is > 0 then you took less time than expected
  // If averageDifferenceThisPeriod is < 0 then you took more time than expected

  var averageDifferenceThisPeriodText = "";

  if (averageDifferenceThisPeriod > 0) {
    averageDifferenceThisPeriodText = `On average, you took ${Math.abs(
      averageDifferenceThisPeriod
    )}% less time time than expected to complete a task`;
  } else if (averageDifferenceThisPeriod < 0) {
    averageDifferenceThisPeriodText = `On average, you took ${Math.abs(
      averageDifferenceThisPeriod
    )}% longer to complete a task than expected`;
  } else {
    averageDifferenceThisPeriodText = `On average, you took the same amount of time to complete a task as expected`;
  }

  return {
    averageEstimatedTime: thisPeriodAverageEstimateTime,
    averageActualTime: thisPeriodAverageActualTime,
    // Format this to a positive number
    estimatedActualDifference: Math.abs(averageDifferenceThisPeriod),
    averageDifferenceThisPeriodText,
    tasks: allTasksDuringThisPeriod,
  };
}

export function getAverageBraindumpTaskAge(tasks, braindumpOrder) {
  // Go through braindump order and get the tasks that are in the braindump order
  var braindumpTasks = braindumpOrder.map((braindumpTaskId) => {
    if (tasks[braindumpTaskId] && tasks[braindumpTaskId].created_at) {
      return tasks[braindumpTaskId];
    }
  });

  // Filter out null values
  braindumpTasks = braindumpTasks.filter((task) => task);

  if (braindumpTasks.length === 0) {
    return 0;
  }

  // Get the average age of the braindump tasks
  const averageBraindumpTaskAge =
    braindumpTasks.reduce((acc, task) => {
      // Age will be time from created_at to now
      // convert task.created_at from firebase timestamp to date
      var created_at = task.created_at?.toDate
        ? task.created_at.toDate()
        : task.created_at;

      return acc + moment().diff(moment(created_at), "days");
    }, 0) / braindumpTasks.length;

  return averageBraindumpTaskAge;
}

export function getBraindumpTasksSortedByAge(tasks, braindumpOrder) {
  // Go through braindump order and get the tasks that are in the braindump order
  var braindumpTasks = braindumpOrder.map((braindumpTaskId) => {
    if (tasks[braindumpTaskId] && tasks[braindumpTaskId].created_at) {
      return tasks[braindumpTaskId];
    }
  });

  // Filter out null values
  braindumpTasks = braindumpTasks.filter((task) => task);

  // Sort the braindump tasks by age
  braindumpTasks.sort((a, b) => {
    // convert task.created_at from firebase timestamp to date
    var created_at_a = a.created_at?.toDate
      ? a.created_at.toDate()
      : a.created_at;

    var created_at_b = b.created_at?.toDate
      ? b.created_at.toDate()
      : b.created_at;

    return moment(created_at_a).diff(moment(created_at_b));
  });

  return braindumpTasks;
}

function takeHexAndDecreaseOpacityBy(hex, opacity) {
  const hexWithoutHash = hex.replace("#", "");
  const r = parseInt(hexWithoutHash.substring(0, 2), 16);
  const g = parseInt(hexWithoutHash.substring(2, 4), 16);
  const b = parseInt(hexWithoutHash.substring(4, 6), 16);

  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}

export function generateCompletedTasksPerDayPerLabel(taskData, dates, labels) {
  // In the end we want to return an array of objects looking like
  // { name: "2022-2-3", tasksCount: 10, label1: 200, label2: 500, label3: 250 }

  // Create a new object where complete == true
  // taskData is NOT an array
  taskData = Object.values(taskData).filter((task) => task.complete);

  // Convert taskData form an array to grouped by "id", use lodash
  taskData = _.keyBy(taskData, "id");

  // Iterate through the task data and group by date, excluding null
  const groupedByDate = _.groupBy(taskData, (task) => {
    // Get date in format YYYY-MM-DD
    const dateString = moment(task.date).format("YYYY-MM-DD");

    return dateString;
  });

  var groupedByLabel = _.groupBy(taskData, (task) => {
    return task.label;
  });

  // Delete the "null" key from the groupedByDate object
  delete groupedByDate["null"];

  // Iterate through the groupedByLabel and create an array of objects
  var transformedData = Object.keys(groupedByLabel).map((labelId) => {
    const label = labels[labelId];

    if (!label) return;
    // We need the format {label: "label1", data: [200, 500, 250], backgroundColor: "red"}

    // Get the tasks for the label
    const tasks = groupedByLabel[labelId];

    return {
      label: label.name,
      data: dates.map((date) => {
        // Get the tasks for the date
        const tasksForDate = groupedByDate[date];

        // If there are no tasks for the date, return 0
        if (!tasksForDate) return 0;

        // If there are tasks for the date, return the count of tasks with the label
        return tasksForDate.filter((task) => task.label === labelId).length;
      }),
      backgroundColor: label.color,
      stack: "Stack 0",
      borderRadius: 6,
      tasks: dates.map((date) => {
        // Get the tasks for the date
        const tasksForDate = groupedByDate[date];

        // If there are no tasks for the date, return 0
        if (!tasksForDate) return 0;

        // If there are tasks for the date, return the count of tasks with the label
        return tasksForDate.filter((task) => task.label === labelId);
      }),
    };
  });

  // Remove any nulls from the array
  transformedData = transformedData.filter((item) => item);

  // For the ones without label, let's make a new group called "No label"
  const tasksWithoutLabel = groupedByLabel["null"];

  if (tasksWithoutLabel) {
    transformedData.push({
      label: "No label",
      data: dates.map((date) => {
        // Get the tasks for the date
        const tasksForDate = groupedByDate[date];

        // If there are no tasks for the date, return 0
        if (!tasksForDate) return 0;

        // If there are tasks for the date, return the count of tasks with the label
        return tasksForDate.filter((task) => task.label === null).length;
      }),
      backgroundColor: "gray",
      stack: "Stack 0",
      borderRadius: 6,
      tasks: dates.map((date) => {
        // Get the tasks for the date
        const tasksForDate = groupedByDate[date];

        // If there are no tasks for the date, return 0
        if (!tasksForDate) return 0;

        // If there are tasks for the date, return the count of tasks with the label
        return tasksForDate.filter((task) => task.label === null);
      }),
    });
  }

  // Return the transformed data
  return {
    finalData: transformedData || [],
    labelsUsed: Object.keys(groupedByLabel),
  };
}

export function generateEstimatedTimeTasksPerDayPerLabel(
  taskData,
  dates,
  labels
) {
  // In the end we want to return an array of objects looking like
  // { name: "2022-2-3", tasksCount: 10, label1: 200, label2: 500, label3: 250 }

  // Create a new object where complete == true
  // taskData is NOT an array
  taskData = Object.values(taskData).filter(
    (task) => task.complete && task.estimated_time
  );

  // Convert taskData form an array to grouped by "id", use lodash
  taskData = _.keyBy(taskData, "id");

  // Iterate through the task data and group by date, excluding null
  const groupedByDate = _.groupBy(taskData, (task) => {
    // Get date in format YYYY-MM-DD
    const dateString = moment(task.date).format("YYYY-MM-DD");

    return dateString;
  });

  var groupedByLabel = _.groupBy(taskData, (task) => {
    return task.label;
  });

  // Delete the "null" key from the groupedByDate object
  delete groupedByDate["null"];

  // Iterate through the groupedByLabel and create an array of objects
  var transformedData = Object.keys(groupedByLabel).map((labelId) => {
    const label = labels[labelId];

    if (!label) return;
    // We need the format {label: "label1", data: [200, 500, 250], backgroundColor: "red"}

    // Get the tasks for the label
    const tasks = groupedByLabel[labelId];

    return {
      label: label.name,
      data: dates.map((date) => {
        // Get the tasks for the date
        const tasksForDate = groupedByDate[date];

        // If there are no tasks for the date, return 0
        if (!tasksForDate) return 0;

        // If there are tasks for the date, return the estimated_time of tasks with the label
        return secondsToHours(
          tasksForDate
            .filter((task) => task.label === labelId)
            .reduce((acc, task) => acc + task.estimated_time, 0)
        );
      }),
      rawSecondsData: dates.map((date) => {
        // Get the tasks for the date
        const tasksForDate = groupedByDate[date];

        // If there are no tasks for the date, return 0
        if (!tasksForDate) return 0;

        // If there are tasks for the date, return the estimated_time of tasks with the label
        return tasksForDate
          .filter((task) => task.label === labelId)
          .reduce((acc, task) => acc + task.estimated_time, 0);
      }),
      backgroundColor: label.color,
      stack: "Stack 0",
      borderRadius: 6,
      tasks: dates.map((date) => {
        // Get the tasks for the date
        const tasksForDate = groupedByDate[date];

        // If there are no tasks for the date, return 0
        if (!tasksForDate) return 0;

        // If there are tasks for the date, return the count of tasks with the label
        return tasksForDate.filter((task) => task.label === labelId);
      }),
    };
  });

  // Remove any nulls from the array
  transformedData = transformedData.filter((item) => item);

  // For the ones without label, let's make a new group called "No label"
  const tasksWithoutLabel = groupedByLabel["null"];

  if (tasksWithoutLabel) {
    transformedData.push({
      label: "No label",
      data: dates.map((date) => {
        // Get the tasks for the date
        const tasksForDate = groupedByDate[date];

        // If there are no tasks for the date, return 0
        if (!tasksForDate) return 0;

        // If there are tasks for the date, return the estimated_time of tasks with the label
        return secondsToHours(
          tasksForDate
            .filter((task) => task.label === null)
            .reduce((acc, task) => acc + task.estimated_time, 0)
        );
      }),
      rawSecondsData: dates.map((date) => {
        // Get the tasks for the date
        const tasksForDate = groupedByDate[date];

        // If there are no tasks for the date, return 0
        if (!tasksForDate) return 0;

        // If there are tasks for the date, return the estimated_time of tasks with the label
        return tasksForDate
          .filter((task) => task.label === null)
          .reduce((acc, task) => acc + task.estimated_time, 0);
      }),
      backgroundColor: "gray",
      stack: "Stack 0",
      borderRadius: 6,
      tasks: dates.map((date) => {
        // Get the tasks for the date
        const tasksForDate = groupedByDate[date];

        // If there are no tasks for the date, return 0
        if (!tasksForDate) return 0;

        // If there are tasks for the date, return the count of tasks with the label
        return tasksForDate.filter((task) => task.label === null);
      }),
    });
  }

  // Return the transformed data
  return {
    finalData: transformedData || [],
    labelsUsed: Object.keys(groupedByLabel),
  };
}

// Function to convert seconds to hours
export function secondsToHours(seconds) {
  if (!seconds) return 0;
  // Round to nearest 2 decimal places
  return Math.round((seconds / 3600) * 100) / 100;
}

export function formatDuration(seconds) {
  const duration = moment.duration(seconds, "seconds");

  // Format the duration based on the number of hours and minutes
  if (duration.asHours() >= 1) {
    const hours = Math.floor(duration.asHours());
    const minutes = Math.floor(duration.asMinutes() % 60);
    if (minutes > 0) {
      return `${hours} hours and ${minutes} mins`;
    }
    return `${hours} hours`;
  } else {
    const minutes = Math.floor(duration.asMinutes());
    return `${minutes} mins`;
  }
}
