import React, { useState, useEffect, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { useNavigate } from "react-router-dom";

import { Modal } from "antd";

import { useMediaQuery } from "react-responsive";

import { XMarkIcon } from "@heroicons/react/24/outline";

import success from "../../images/success.gif";
import manage_calendars from "../../images/manage_calendars.png";

import confetti from "canvas-confetti";
import {
  analytics,
  googleServerUrl,
  hasGoogleCalendar,
  outlookServerUrl,
} from "../../utils";
import { toast } from "sonner";
import axios from "axios";
import { Switch } from "antd";
import _ from "lodash";
import { toggleSettings, updateCurrentUser } from "../../redux/appSlice";
import { AiOutlineCalendar } from "react-icons/ai";
import { IoSync } from "react-icons/io5";
import { FaArrowRight } from "react-icons/fa";
import { auth } from "../../firebase";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function CalendarSuccess() {
  const active_calendars = useSelector(
    (state) => state.app.currentUser.active_calendars
  );

  const userId = useSelector((state) => state.app.uid);
  const { calendar_accounts = {}, showCalendarSuccessModal = null } =
    useSelector((state) => state.app.currentUser);

  let query = useQuery();

  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [calendarEmail, setCalendarEmail] = useState("");
  const [accountId, setAccountId] = useState(null);
  const [googleCalendars, setGoogleCalendars] = useState([]);
  const [outlookCalendars, setOutlookCalendars] = useState([]);
  const [page, setPage] = useState(0);
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [outlookLoading, setOutlookLoading] = useState(false);
  const [calendarAccount, setCalendarAccount] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    var calendarEmail = query.get("calendarAdded");
    const accountId = query.get("accountId");

    const calendarError = query.get("calendarError");

    if (calendarEmail && accountId) {
      analytics("Calendar linked", {
        source: "Web",
        type: "Google",
      });

      navigate("/");
      setShow(true);
      setCalendarEmail(calendarEmail);
      setAccountId(accountId);
    }

    if (calendarError) {
      analytics("Calendar error", {
        source: "Web",
        reason: calendarError,
        type: "Google",
      });

      toast.error(calendarError || "ERROR", {
        position: "top-center",
      });

      navigate("/");
    }
  }, [query]);

  const getGoogleCalendars = () => {
    setLoading(true);
    if ((calendarEmail, accountId)) {
      return axios
        .get(`${googleServerUrl}/getCalendars`, {
          params: {
            userId: userId,
          },
        })
        .then((response) => {
          if (response.data) {
            console.log("response.data.calendars", response.data.calendars);
            const filteredCalendars =
              response.data.calendars?.[accountId]?.calendars || [];
            setGoogleCalendars(filteredCalendars);
            setLoading(false);
          }
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
        });
    } else {
      return Promise.resolve();
    }
  };

  const getOutlookCalendars = async () => {
    setOutlookLoading(true);
    const idToken = await auth.currentUser.getIdToken(true);
    return axios
      .get(`${outlookServerUrl}/getOutlookCalendars`, {
        params: {
          idToken: idToken,
        },
        headers: {
          "ngrok-skip-browser-warning": "true",
        },
      })
      .then((response) => {
        if (response.data) {
          setOutlookCalendars(
            response?.data?.calendars?.[showCalendarSuccessModal]?.calendars
          );
          setOutlookLoading(false);
        }
      })
      .catch((error) => {
        console.log("error fetching outlook calendars", error);
        setOutlookLoading(false);
      });
  };

  useEffect(() => {
    if (calendarEmail && accountId) {
      getGoogleCalendars();
    }
  }, [calendarEmail, accountId]);

  useEffect(() => {
    if (calendarAccount?.type === "outlook") {
      getOutlookCalendars();
    }
  }, [calendarAccount]);

  const handleSwitchChange = (checked, calendarId) => {
    if (calendarAccount.type === "outlook") {
      if (!active_calendars[showCalendarSuccessModal]) {
        toast.error("Something went wrong, please contact support");
        return;
      }

      const newActiveCalendars = _.cloneDeep(active_calendars);
      if (
        newActiveCalendars[showCalendarSuccessModal].calendars.includes(
          calendarId
        )
      ) {
        newActiveCalendars[showCalendarSuccessModal].calendars =
          newActiveCalendars[showCalendarSuccessModal].calendars.filter(
            (id) => id !== calendarId
          );
      } else {
        newActiveCalendars[showCalendarSuccessModal].calendars.push(calendarId);
      }

      dispatch(
        updateCurrentUser({
          newValues: { active_calendars: newActiveCalendars },
          previousValues: { active_calendars },
        })
      );
    } else {
      if (!active_calendars[accountId]) {
        toast.error("Something went wrong, please contact support");
        return;
      }

      const newActiveCalendars = _.cloneDeep(active_calendars);
      if (newActiveCalendars[accountId].calendars.includes(calendarId)) {
        newActiveCalendars[accountId].calendars = newActiveCalendars[
          accountId
        ].calendars.filter((id) => id !== calendarId);
      } else {
        newActiveCalendars[accountId].calendars.push(calendarId);
      }

      dispatch(
        updateCurrentUser({
          newValues: { active_calendars: newActiveCalendars },
          previousValues: { active_calendars },
        })
      );
    }
  };

  // we'll have a useEffect that listens showCalendarSuccessModal which contains the accountId
  useEffect(() => {
    if (calendar_accounts[showCalendarSuccessModal]) {
      dispatch(toggleSettings());
      setShow(true);
      setCalendarAccount(calendar_accounts[showCalendarSuccessModal]);
    } else {
      setShow(false);
    }
  }, [showCalendarSuccessModal]);

  function capitalizeFirstLetter(string) {
    if (!string) return null;
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  return (
    <div>
      <Modal
        closable={false}
        footer={null}
        style={{ top: isMobile ? "20px" : "30px" }}
        bodyStyle={{ padding: "0px" }}
        className={"light-theme"}
        open={show}
        width={450}
        zIndex={10000000000}
        onCancel={() => {
          dispatch(
            updateCurrentUser({
              newValues: { showCalendarSuccessModal: null },
              previousValues: { showCalendarSuccessModal },
            })
          );
          setShow(false);
        }}
      >
        <div className="w-full" style={{ padding: "20px" }}>
          <div
            onClick={() => {
              setShow(false);
            }}
            className="close-button-container"
          >
            <XMarkIcon className="close-upgrade-modal-button" />
          </div>

          <div className="flex flex-col my-5 gap-4 w-full">
            <div>
              <div className="text-xl font-semibold">
                🥳 {capitalizeFirstLetter(calendarAccount?.type) || "Google"}{" "}
                calendar linked!
              </div>
            </div>

            {page === 0 && (
              <div className="flex flex-col gap-6">
                <div className="text-base font-medium flex flex-col">
                  Let's select the calendars you want to show up in Ellie (you
                  can always change this later) 👇
                </div>
                <div className="flex flex-col gap-2">
                  <div className="text-neutral-500 text-sm font-semibold">
                    {calendarEmail || calendarAccount?.email}
                  </div>
                  <div className="flex flex-col gap-2 pr-2">
                    {(loading || outlookLoading) && (
                      <div className="flex flex-col gap-4 items-center justify-center mt-5">
                        <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-purple-500"></div>
                        <div>Loading calendars...</div>
                      </div>
                    )}
                    {googleCalendars > 0 &&
                      googleCalendars.map((calendar) => {
                        return (
                          <div className="flex gap-10 items-center justify-between text-base">
                            <div className="flex gap-2 items-center">
                              <div
                                className={`w-4 h-4 rounded-md`}
                                style={{
                                  backgroundColor: calendar.backgroundColor,
                                }}
                              ></div>
                              <div>{calendar.summary}</div>
                            </div>
                            <div className="w-5">
                              <Switch
                                size="small"
                                checked={active_calendars[
                                  accountId
                                ]?.calendars.includes(calendar.id)}
                                onChange={(checked) =>
                                  handleSwitchChange(checked, calendar.id)
                                }
                                disabled={!active_calendars[accountId]}
                              />
                            </div>
                          </div>
                        );
                      })}
                    {outlookCalendars?.length > 0 &&
                      outlookCalendars.map((calendar) => {
                        return (
                          <div className="flex gap-10 items-center justify-between text-base">
                            <div className="flex gap-2 items-center">
                              <div
                                className={`w-4 h-4 rounded-md`}
                                style={{
                                  backgroundColor: calendar.hexColor || "#000",
                                }}
                              ></div>
                              <div>{calendar.name}</div>
                            </div>
                            <div className="w-5">
                              <Switch
                                size="small"
                                checked={active_calendars[
                                  showCalendarSuccessModal
                                ]?.calendars.includes(calendar.id)}
                                onChange={(checked) =>
                                  handleSwitchChange(checked, calendar.id)
                                }
                                disabled={
                                  !active_calendars[showCalendarSuccessModal]
                                }
                              />
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            )}
            {page === 1 && (
              <div className="flex flex-col gap-4">
                <div className="text-neutral-500 text-lg align-middle">
                  You can manage your calendars by clicking the calendar icon{" "}
                  <AiOutlineCalendar className="inline-block text-neutral-600 align-middle mb-1" />{" "}
                  (video below)
                </div>

                <div className="relative overflow-hidden w-full h-64 rounded-lg">
                  <video
                    autoPlay
                    loop
                    muted
                    playsInline
                    className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 min-w-full min-h-full w-auto h-auto z-[20] object-contain rounded-lg"
                  >
                    <source
                      type="video/mp4"
                      src={require(`../../images/manage_calendars.mp4`)}
                    />
                  </video>
                </div>
              </div>
            )}
          </div>
          <div className="w-full flex justify-end">
            {page === 0 ? (
              <div
                className="button primary"
                onClick={() => setPage((prev) => prev + 1)}
              >
                <div>Save selection</div>
              </div>
            ) : (
              <div
                className="button primary"
                onClick={() => {
                  setPage(0);
                  setShow(false);
                  dispatch(
                    updateCurrentUser({
                      newValues: { showCalendarSuccessModal: null },
                      previousValues: { showCalendarSuccessModal },
                    })
                  );
                }}
              >
                <div>Done</div>
              </div>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
}
