import React, { useState, useEffect, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { useNavigate } from "react-router-dom";

import { Modal } from "antd";

import { useMediaQuery } from "react-responsive";

import { XMarkIcon } from "@heroicons/react/24/outline";

import success from "../../images/success.gif";

import confetti from "canvas-confetti";
import { RiAppStoreFill } from "react-icons/ri";
import { updateCurrentUser } from "../../redux/appSlice";
import { analytics } from "../../utils";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function MobileFtux() {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const dispatch = useDispatch();

  const { mobile_web_fux_shown = false } = useSelector(
    (state) => state.app.currentUser
  );

  const [show, setShow] = useState(false);

  useEffect(() => {
    if (isMobile && !mobile_web_fux_shown) {
      setShow(true);
    }
  }, [isMobile, mobile_web_fux_shown]);

  if (!isMobile) return null;

  return (
    <div>
      <Modal
        closable={false}
        footer={null}
        style={{ top: isMobile ? "20px" : "30px" }}
        bodyStyle={{ padding: "0px" }}
        className={"light-theme"}
        open={show}
        width={450}
        zIndex={10000000000}
        onCancel={() => {
          dispatch(
            updateCurrentUser({
              newValues: {
                mobile_web_fux_shown: true,
              },
              previousValues: {
                mobile_web_fux_shown: false,
              },
            })
          );
          setShow(false);
        }}
      >
        <div className="upgrade-container">
          <div className="message-modal no-border" style={{ padding: "20px" }}>
            <div
              onClick={() => {
                dispatch(
                  updateCurrentUser({
                    newValues: {
                      mobile_web_fux_shown: true,
                    },
                    previousValues: {
                      mobile_web_fux_shown: false,
                    },
                  })
                );
                setShow(false);
              }}
              className="close-button-container"
            >
              <XMarkIcon className="close-upgrade-modal-button" />
            </div>
            <div className="success-h1">✌️ Just a heads up </div>
            <div className="success-h2">
              Ellie is best experienced through our iOS, Mac or web app
              (app.ellieplanner.com).
            </div>
            <div className="success-h2">
              We tried our best to optimize the mobile-web experience but we
              recommend you download the app for the best experience.
            </div>

            <img
              onClick={() => {
                analytics("Download iOS App", {
                  source: "Web",
                });
                window.open(
                  "https://apps.apple.com/us/app/ellie-daily-planner/id1602196200"
                );
              }}
              src={require("../../images/appstore.png")}
              className="h-16 object-contain"
            />
          </div>
        </div>
      </Modal>
    </div>
  );
}
