import "./Settings.css";
import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { Default, Mobile } from "../../mediaUtils";
import {
  updateCurrentUser,
  setToastVisible,
  setUpgradeVisible,
} from "../../redux/appSlice";

import { ArrowRightIcon } from "@heroicons/react/24/outline";
import { ChevronRightIcon } from "@heroicons/react/24/solid";

import { Button, Input, Select, Switch } from "antd";
import { FcGoogle } from "react-icons/fc";
import { TbArrowRight, TbMail, TbPlus } from "react-icons/tb";

import axios from "axios";
import { googleServerUrl } from "../../utils";
import { AiOutlineApple } from "react-icons/ai";
import { FaAppStoreIos } from "react-icons/fa";

import calenderImageDark from "../../images/cal_integration_dark.png";
import calenderImageLight from "../../images/cal_integration.png";
import { Toaster, toast } from "sonner";

const { Option } = Select;

export default function EmailForwarding() {
  const { currentUser } = useSelector((state) => state.app);
  const userId = useSelector((state) => state.app.uid);
  const subscriptionActive = useSelector(
    (state) => state.app.subscriptionActive
  );
  const dispatch = useDispatch();

  const { user_theme = "system", email_forwarding_id = null } = useSelector(
    (state) => state.app.currentUser
  );

  function getThemeFromUser(user_theme) {
    if (user_theme == "system") {
      return window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches
        ? "dark"
        : "light";
    } else {
      return user_theme;
    }
  }

  if (email_forwarding_id == null) {
    return (
      <div className="email-forwarding">
        <div>
          Email forwarding allows you to create tasks from your inbox. Simply
          forward emails to your special email address and they will
          automatically be sent to either your Braindump or Today list. You'll
          be able to view the full email in Ellie as well.
        </div>

        <div
          onClick={() => {
            // Let's generate a lowercase id for the user and update email_forwarding_id
            // Ensure it is lowercase
            const id = Math.random().toString(36).substring(2, 15);

            dispatch(
              updateCurrentUser({
                newValues: {
                  email_forwarding_id: id,
                },
                previousValues: {
                  email_forwarding_id: email_forwarding_id,
                },
              })
            );
          }}
          className="add-calendar-container"
          style={{ width: "100%" }}
        >
          <div className="add-calendar-text">
            <TbMail className="gcal-icon" />
            <span>Enable email fowarding</span>
          </div>
          <TbArrowRight className="plus-icon" />
        </div>

        <div>Want to see a demo? Click the video below 👇</div>

        <div className="youtube-embed">
          <iframe
            width="500"
            height="315"
            src="https://www.youtube.com/embed/9Q4eR5ZqpJQ"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    );
  }

  return (
    <div className="email-forwarding">
        <div>
          Send or forward an email to this address to create a task. The email's
          subject will become the task's title. You'll be able to view the full
          email in Ellie as well.
        </div>

        <div>
          Email address to add task to <b>Braindump</b>
        </div>
        <div className="email-forwarding-email">
          <Input
            value={`Ellie braindump <reply+${email_forwarding_id}.brain_dump@inbound.ellieplanner.com>`}
            contentEditable={false}
          />
          <div
            className="button primary"
            onClick={() => {
              // Copy to clipboard
              toast.success("Copied to clipboard!");
              navigator.clipboard.writeText(
                `Ellie braindump <reply+${email_forwarding_id}.brain_dump@inbound.ellieplanner.com>`
              );
            }}
          >
            Copy email
          </div>
        </div>
        <div>
          Email address to add task to <b>Today</b>
        </div>
        <div className="email-forwarding-email">
          <Input
            value={`Ellie Today <reply+${email_forwarding_id}.today@inbound.ellieplanner.com>`}
            contentEditable={false}
          />
          <div
            className="button primary"
            onClick={() => {
              // Copy to clipboard
              toast.success("Copied to clipboard!");
              navigator.clipboard.writeText(
                `Ellie Today <reply+${email_forwarding_id}.today@inbound.ellieplanner.com>`
              );
            }}
          >
            Copy email
          </div>
        </div>
        <div className="disclaimer">
          Do not share this email address with anyone. Anybody with this email
          can create tasks on your account.
        </div>
    </div>
  );
}
