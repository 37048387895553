import { padEnd, set } from "lodash";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FaChevronLeft, FaChevronRight, FaSlack } from "react-icons/fa";
import axios from "axios";
import { slackServerUrl } from "../../utils";
import { BiError, BiSolidError } from "react-icons/bi";
import { toast } from "sonner";
import { FaCircleCheck } from "react-icons/fa6";
import { ArrowLeftCircleIcon } from "@heroicons/react/24/outline";

function Integrations() {
  const userId = useSelector((state) => state.app.uid);

  const slackConnections = useSelector((state) => state.app.currentUser.slack);

  const [connectedIntegration, setConnectedIntegration] = useState(false);
  const [slackIntegrations, setSlackIntegrations] = useState([]);
  const [page, setPage] = useState(0);

  useEffect(() => {
    axios
      .get(`${slackServerUrl}/getConnectedWorkspaces`, {
        params: {
          userId: userId,
        },
      })
      .then((response) => {

        // if there are slackIntegrations in the response, then set connectedIntegration to true
        if (response.data.slackIntegrations.length > 0) {
          setSlackIntegrations(response.data.slackIntegrations);
          setConnectedIntegration(true);
        } else {
          setSlackIntegrations([]);
          setConnectedIntegration(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setConnectedIntegration(false);
      });
  }, [slackConnections, userId]);

  const handleRevokeClick = (slack_auth_id) => {
    // Hit the slackServerUrl to revoke the token /revokeTokenForSlackAuthId
  
    try {
      const axiosPromise = axios
        .post(`${slackServerUrl}/revokeTokenForSlackAuthId`, {
          slackAuthId: slack_auth_id,
        })
        .then((response) => {
 
          return response.data;
        })
        .catch((error) => {
          console.log("error", error);
          throw error; // Throw the error instead of returning it
        });

      toast.promise(axiosPromise, {
        loading: "Revoking slack access...",
        success: "Slack access revoked",
        error: "Something went wrong. Please try again.",
      });

      return axiosPromise;
    } catch (error) {
      console.log("error", error);
      toast.error("Something went wrong. Please try again.");
      return error;
    }
  };

  return (
    <>
      {page === 0 && (
        <div className="flex flex-wrap gap-4">
          <IntegrationBox
            title="Slack"
            imgSrc={require("../../images/Slack-mark-RGB.png")}
            description="Connect your Slack account to create tasks from messages"
            isConnected={connectedIntegration}
            setPage={setPage}
          />
          <IntegrationBox
            title="Notion"
            imgSrc={require("../../images/Notion_app_logo.png")}
            description="Connect your Notion account to create tasks from pages"
            disabled={true}
            setPage={setPage}
          />
          <IntegrationBox
            title="Todoist"
            imgSrc={require("../../images/todoist-icon.png")}
            description="Connect your Todoist account to create tasks from pages"
            disabled={true}
            setPage={setPage}
          />
        </div>
      )}
      {page === 1 && (
        <div className="flex flex-col gap-2 justify-start items-start">
          <div
            onClick={() => {
              setPage(0);
            }}
            className="back-button"
          >
            <ArrowLeftCircleIcon className="back-button-icon" />
            Back
          </div>

          <div className="flex justify-start items-center">
            <div className="flex items-center gap-2">
              <img
                src={require("../../images/Slack-mark-RGB.png")}
                alt="Slack Logo"
                className="w-5 h-5 object-contain"
              />
              <div className="text-2xl font-medium">Slack</div>
            </div>
          </div>
          <div className="flex flex-col gap-1 justify-start items-start w-full">
            <div className="text-neutral-500">
              Connect your Slack account to create tasks from messages
            </div>

            <div className="flex flex-col my-2 border border-neutral-200 dark:border-neutral-700 rounded-lg w-full">
              {slackIntegrations.map((connectionData, index) => {
                return (
                  <WorkSpaceRow
                    connectionData={connectionData}
                    key={connectionData.id}
                    isLast={slackIntegrations.length === index + 1}
                    handleRevokeClick={handleRevokeClick}
                  />
                );
              })}
            </div>
            <div
              onClick={() => {
                window.open(
                  `https://slack.com/oauth/v2/authorize?client_id=3704128363168.6274499426309&scope=commands,team:read&user_scope=users:read,team:read`
                );
              }}
              className="flex flex-row gap-3 justify-start items-center border  rounded-lg px-4 py-2 cursor-pointer  text-textPrimaryLight dark:text-textPrimaryDark border-neutral-300 dark:border-neutral-700 shadow-sm mt-2 font-medium text-base dark:hover:border-neutral-500 "
            >
              <img
                src={require("../../images/Slack-mark-RGB.png")}
                alt="Slack Logo"
                className="w-4 h-4 object-contain"
              />
              Connect another workspace
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Integrations;
const IntegrationBox = ({
  title = null,
  imgSrc = null,
  description = null,
  disabled = false,
  isConnected = false,
  setPage,
}) => {
  const buttonClass = isConnected
    ? "font-medium text-green-700/70 dark:bg-green-400/10 dark:text-green-500/70 rounded-md px-2 py-1 cursor-default"
    : !disabled
    ? "cursor-pointer font-semibold flex flex-row gap-1 items-center hover:text-purple-700 dark:hover:text-purple-300"
    : "text-neutral-400  dark:text-neutral-400 cursor-default font-semibold";

  const buttonText = !disabled
    ? isConnected
      ? "Connected"
      : "Connect"
    : "Coming Soon";

  return (
    <div className="border border-neutral-200 dark:border-neutral-700 shadow-sm rounded-xl w-64 flex flex-col justify-center items-center overflow-hidden relative">
      {isConnected && (
        <FaCircleCheck className="text-green-600 h-5 w-5 absolute top-3 right-3" />
      )}
      <div className="px-4 pt-6 pb-4 flex flex-col gap-2 justify-center items-center">
        <img
          src={imgSrc || "default_image_path"}
          alt={`${title} Logo`}
          className="w-6 h-6 object-contain"
        />
        <div className="font-medium text-lg flex flex-row gap-2 items-center">
          {title}
        </div>
        <div className="text-neutral-500">{description}</div>
      </div>
      <div
        className={`border-t dark:border-t-neutral-800 pt-2 w-full ${
          isConnected ? "bg-green-500/10" : "bg-neutral-100 dark:bg-neutral-800"
        } flex flex-row gap-2 justify-center py-3 space-between h-12 items-center`}
      >
        {disabled && (
          <div className="text-neutral-400 dark:text-neutral-400 font-semibold">
            Coming Soon
          </div>
        )}
        {!disabled && !isConnected && (
          <div className={buttonClass}
            onClick={() => {
              window.open(
                `https://slack.com/oauth/v2/authorize?client_id=3704128363168.6274499426309&scope=commands,team:read&user_scope=users:read,team:read`
              );
            }
          }
          >
            Connect
              <FaChevronRight className="h-3 w-3" />
          </div>
        )}
        {isConnected && (
          <div
            onClick={() => setPage(1)}
            className="text-green-700 dark:text-green-500 flex gap-1 items-center px-2 rounded-lg font-medium cursor-pointer hover:text-black hover:dark:text-green-200"
          >
            Manage connections
            <FaChevronRight className="h-3 w-3" />
          </div>
        )}
      </div>
    </div>
  );
};

const WorkSpaceRow = ({ connectionData, isLast, handleRevokeClick }) => {
  if (connectionData.error) {
    return (
      <div
        className={`border border-t-0 border-r-0 border-l-0 px-4 py-4 border-neutral-200 dark:border-neutral-700 flex justify-between w-full ${
          isLast && "rounded-lg border-b-0 border-none"
        }`}
      >
        <div className="flex items-center gap-2">
          <div className="bg-yellow-400 dark:bg-neutral-700 h-10 w-10 rounded-md flex flex-row items-center justify-center">
            <BiSolidError className="h-6 w-6 text-white" />
          </div>
          <div className="flex flex-col">
            <div className="text-lg">{connectionData.error?.team_name}</div>
            <div className="text-xs text-neutral-500">
              {connectionData.error?.message}
            </div>
          </div>
        </div>
        <div
          className="text-blue-500 flex items-center rounded-md px-2 py-1 font-semibold text-xs hover:text-blue-800 cursor-pointer"
          onClick={() => {
            window.open(
              `https://slack.com/oauth/v2/authorize?client_id=3704128363168.6274499426309&scope=commands,team:read&user_scope=users:read,team:read`
            );
          }}
        >
          Re-authenticate
        </div>
      </div>
    );
  }

  return (
    <div
      className={`border border-t-0 border-r-0 border-l-0 px-4 py-4 border-neutral-200 dark:border-neutral-700 flex justify-between ${
        isLast && "rounded-lg border-b-0 border-none"
      }`}
    >
      <div className="flex items-center gap-2">
        <img
          className="bg-neutral-300 dark:bg-neutral-700 h-10 w-10 rounded-md"
          src={connectionData?.team?.icon}
        />
        <div className="flex flex-col">
          <div className="text-lg">{connectionData?.team?.name}</div>
          <div className="text-xs text-neutral-500">
            {connectionData?.team?.url}
          </div>
        </div>
      </div>
      <div
        className=" text-red-600 flex items-center rounded-md px-2 py-1 font-semibold text-xs hover:text-red-700 cursor-pointer"
        onClick={() => {
          handleRevokeClick(connectionData?.slack_auth_id);
        }}
      >
        Revoke access
      </div>
    </div>
  );
};
