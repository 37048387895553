import "./AddTaskCard.css";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { useSelector } from "react-redux";

import moment from "moment";
import { useEffect, useState } from "react";
import { Tooltip } from "antd";

export default function AddTaskCard({ setCreateActive, date }) {
  const { data: taskData } = useSelector((state) => state.tasks);

  const selectedList = useSelector(
    (state) => state.app.currentUser?.selectedList || "brain_dump"
  );

  const {
    work_threshold = 8,
    enable_work_threshold = true,
    work_threshold_excluded_labels = [],
  } = useSelector((state) => state.app?.currentUser);

  const taskOrder = useSelector((state) =>
    date
      ? state.tasks.order[date]?.order
      : state.tasks.order[selectedList]?.order
  );

  const [totalEstimatedTime, setTotalEstimatedTime] = useState(0);
  const [totalActualTime, setTotalActualTime] = useState(0);

  const { label_filters: activeLabels = [], hide_complete = false } =
    useSelector((state) => state.app?.currentUser);

  useEffect(() => {
    // Whenever task order updates, let's re-calculate the total time
    // spent on tasks for this column.
    var totalEstimatedTimeTemp = 0;
    var totalActualTimeTemp = 0;

    if (taskOrder) {
      // If there is a date, get all tasks for a given date and sum their time
      const tasks = taskOrder
        .map((taskId) => {
          let task = taskData[taskId];

          if (
            task &&
            (!hide_complete || !task.complete) &&
            (activeLabels?.length === 0 ||
              activeLabels?.includes(task.label)) &&
            (!enable_work_threshold ||
              !work_threshold_excluded_labels.includes(task.label))
          ) {
            return task;
          }
        })
        .filter((task) => task);

      totalEstimatedTimeTemp = tasks.reduce(
        (acc, task) =>
          acc + (!isNaN(task.estimated_time) ? task.estimated_time : 0),
        0
      );

      totalActualTimeTemp = tasks.reduce(
        (acc, task) => acc + (!isNaN(task.actual_time) ? task.actual_time : 0),
        0
      );
    }

    setTotalEstimatedTime(totalEstimatedTimeTemp);
    setTotalActualTime(totalActualTimeTemp);
  }, [
    taskOrder,
    taskData,
    activeLabels,
    hide_complete,
    work_threshold_excluded_labels,
    enable_work_threshold,
  ]);

  // Utility function to convert seconds to hours and minutes
  const secondsToHoursAndMinutes = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    return { hours, minutes };
  };

  // Utility function to generate the tooltip message based on the threshold
  const getTooltipMessage = (totalTime, threshold) => {
    const thresholdSeconds = threshold * 3600;
    if (totalTime > thresholdSeconds) {
      const exceededTime = secondsToHoursAndMinutes(
        totalTime - thresholdSeconds
      );

      const hoursPart =
        exceededTime.hours > 0
          ? `${exceededTime.hours} hour${
              exceededTime.hours > 1 ? "s" : ""
            } and `
          : "";

      return `😓 You have exceeded your work threshold (${threshold} hours) by ${hoursPart}${exceededTime.minutes} mins`;
    } else if (totalTime >= thresholdSeconds - 7200) {
      // 7200 seconds = 2 hours
      const remainingTime = secondsToHoursAndMinutes(
        thresholdSeconds - totalTime
      );

      const hoursPart =
        remainingTime.hours > 0
          ? `${remainingTime.hours} hour${
              remainingTime.hours === 1 ? "" : "s"
            } and `
          : "";

      return `👀 You are ${hoursPart}${remainingTime.minutes} mins away from hitting your work threshold (${threshold} hours)`;
    }
    return ""; // Default case
  };

  function formatTime(totalEstimatedTime) {
    const hours = Math.floor(totalEstimatedTime / 3600);
    const minutes = Math.floor((totalEstimatedTime % 3600) / 60);
    return `${hours}:${minutes < 10 ? "0" : ""}${minutes}`;
  }

  return (
    <div>
      <div
        onClick={() => {
          if (date) {
            setCreateActive(date);
          } else {
            setCreateActive(true);
          }
        }}
        className="add-card-container"
      >
        <div className="add-card-left">
          <PlusCircleIcon className="add-card-icon" />
          <div className="add-card-text">Add a task</div>
        </div>

        {!isNaN(totalEstimatedTime) && totalEstimatedTime > 0 && (
          <Tooltip
            title={
              enable_work_threshold
                ? getTooltipMessage(totalEstimatedTime, work_threshold)
                : ""
            }
            placement="bottom"
          >
            <div
              className={`card-time-estimate
            ${
              enable_work_threshold &&
              (totalEstimatedTime / 3600 > work_threshold
                ? "!text-red-600 !bg-red-100 dark:!text-white dark:!bg-rose-950/30 dark:!border-red-900/90 !border border-red-200"
                : totalEstimatedTime / 3600 >= work_threshold - 2
                ? " !text-yellow-700 !bg-amber-100/80 dark:!bg-amber-950/30 dark:!text-white !border dark:!border-yellow-900/90 border-yellow-300"
                : "")
            }
            `}
            >
              {`
            ${
              !isNaN(totalActualTime) && totalActualTime > 0
                ? `${formatTime(totalActualTime)} / `
                : ""
            }
            ${formatTime(totalEstimatedTime)}
            `}
            </div>
          </Tooltip>
        )}
      </div>
    </div>
  );
}
