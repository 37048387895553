import {
  ArchiveBoxXMarkIcon,
  CheckCircleIcon,
  DocumentDuplicateIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { setContext } from "@sentry/react";
import { Popover } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { FcGoogle } from "react-icons/fc";
import { useDispatch, useSelector } from "react-redux";
import { setContextMenuActiveForEvent } from "../../redux/calendarSlice";
import {
  deleteTask,
  duplicateTask,
  moveTaskToBottom,
  moveTaskToBottomOfIncomplete,
  softDelete,
  updateTask,
} from "../../redux/tasksSlice";
import { FaCheck } from "react-icons/fa6";

export default function TaskCalendarEvent({
  taskId,
  event,
  eventObject,
  textColor,
  calendarView,
  moreLinkActive = false,
  setMoreLinkActive,
}) {
  const task = useSelector((state) => state.tasks.data[taskId]);

  const {
    move_task_on_complete_disabled = false,
    auto_fill_actual_time_disabled = false,
  } = useSelector((state) => state.app.currentUser);

  const { contextMenuActiveForEvent } = useSelector((state) => state.calendar);

  const dispatch = useDispatch();

  function removeEventForTask() {
    dispatch(
      updateTask({
        taskId: task?.id,
        newData: {
          start: null,
        },
        currentTask: task,
        saveGhostOrder: false,
      })
    );
    dispatch(setContextMenuActiveForEvent(null));
  }

  function completeTaskForEvent(event) {
    const taskId = event.id;
    // Update the task

    var newCompleteValue = task?.complete ? false : true;

    var newActualTime = task?.actual_time || 0;

    if (!auto_fill_actual_time_disabled) {
      // If auto_fill_actual_time_disabled is false, then let's fill in the actual time if estimated_time is set and actual_time is not
      if (
        task?.estimated_time &&
        (!task?.actual_time || task?.actual_time == 0)
      ) {
        newActualTime = task?.estimated_time;
      }
    }

    dispatch(
      updateTask({
        taskId: taskId,
        newData: {
          complete: newCompleteValue,
          completed_at: new Date(),
          actual_time: newActualTime,
        },
        currentTask: task,
        saveGhostOrder: false,
      })
    );

    if (!move_task_on_complete_disabled) {
      // If we are marking as complete, move it to the bottom of the order

      // Get date in format YYYY-MM-DD
      const dateString = moment(task?.date).format("YYYY-MM-DD");

      if (newCompleteValue) {
        dispatch(
          moveTaskToBottom({
            taskId: task?.id,
            date: dateString,
          })
        );
      } else {
        // We are marking as incomplete, move it to the bottom of the incomplete tasks
        dispatch(
          moveTaskToBottomOfIncomplete({
            taskId: task?.id,
            date: dateString,
          })
        );
      }
    }

    dispatch(setContextMenuActiveForEvent(null));
  }

  const durationLessThan15Minutes =
    moment(event.end).diff(moment(event.start), "minutes") <= 15;

  function customTimeFormat(date) {
    const momentDate = moment(date);
    if (momentDate.minutes() === 0) {
      return momentDate.format("ha");
    } else {
      return momentDate.format("h:mma");
    }
  }

  return (
    <Popover
      placement="left"
      title={null}
      content={
        <div className="card-context-menu">
          <div
            onClick={(e) => {
              e.stopPropagation();
              completeTaskForEvent(event);

              dispatch(setContextMenuActiveForEvent(null));
            }}
            className="context-menu-row"
          >
            <CheckCircleIcon className="context-mmenu-row-icon" />
            {task?.complete && <span>Mark as incomplete</span>}
            {!task?.complete && <span>Mark as complete</span>}
          </div>
          <div
            onClick={(e) => {
              e.stopPropagation();
              dispatch(
                duplicateTask({
                  taskId: task?.id,
                  taskToDuplicate: task,
                })
              );

              if (setMoreLinkActive) {
                setMoreLinkActive(false);
              }
              dispatch(setContextMenuActiveForEvent(null));
            }}
            className="context-menu-row"
          >
            <DocumentDuplicateIcon className="context-mmenu-row-icon" />
            Duplicate task
          </div>
          <div
            onClick={(e) => {
              e.stopPropagation();
              removeEventForTask(event);

              if (setMoreLinkActive) {
                setMoreLinkActive(false);
              }

              dispatch(setContextMenuActiveForEvent(null));
            }}
            className="context-menu-row"
          >
            <ArchiveBoxXMarkIcon className="context-mmenu-row-icon" />
            Remove from timebox
          </div>
          <div
            onClick={(e) => {
              e.stopPropagation();
              dispatch(softDelete(task));

              if (setMoreLinkActive) {
                setMoreLinkActive(false);
              }

              dispatch(setContextMenuActiveForEvent(null));
            }}
            className="context-menu-row"
          >
            <TrashIcon className="context-mmenu-row-icon" />
            Delete task
          </div>
        </div>
      }
      open={!moreLinkActive && contextMenuActiveForEvent === event.id}
      onOpenChange={(open) => {
        // If moreLinkActive, don't open the context menu
        if (moreLinkActive) {
          return;
        }

        if (!open) {
          dispatch(setContextMenuActiveForEvent(null));
        } else {
          dispatch(setContextMenuActiveForEvent(event.id));
        }
      }}
      trigger={["contextMenu"]}
      id="card-context-menu"
    >
      {calendarView !== "month" ? (
        <div
          className={`event-content ${
            durationLessThan15Minutes ? "small-text" : ""
          } ${task?.complete ? "!opacity-70" : "opacity-100"} ${
            durationLessThan15Minutes ? "flex flex-row !items-center" : ""
          }`}
        >
          <div
            className={`flex flex-row gap-1 ${
              durationLessThan15Minutes ? "items-center" : ""
            }`}
          >
            {task?.complete ? (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  completeTaskForEvent(event);
                }}
                className={`flex flex-row justify-center items-center border-solid ${getTextColor(
                  { color: event.backgroundColor, hover: false, type: "border" }
                )}  ${getCheckmarkColor({
                  color: event.backgroundColor,
                })} ${getTextColor({
                  color: event.backgroundColor,
                  hover: false,
                  type: "bg",
                })} ${
                  durationLessThan15Minutes
                    ? "rounded-sm border w-2.5 h-2.5 !min-w-2.5 !min-h-2.5"
                    : "border-2 !w-4 !h-4 !min-w-4 !min-h-4 rounded-md"
                }`}
              >
                <FaCheck className="w-2 h-2" />
              </div>
            ) : (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  completeTaskForEvent(event);
                }}
                className={`border-solid ${getTextColor({
                  color: event.backgroundColor,
                  hover: false,
                  type: "border",
                })} ${
                  durationLessThan15Minutes
                    ? "rounded-sm border w-2.5 h-2.5 !min-w-2.5 !min-h-2.5"
                    : "rounded-md border-2 w-4 h-4 !min-w-4 !min-h-4"
                } hover:${getTextColor({
                  color: event.backgroundColor,
                  hover: true,
                  type: "border",
                })}`}
              ></div>
            )}

            <div className="event-meta">
              <div
                className={`event-title ph-mask ${
                  task?.complete ? "completed" : ""
                } ${durationLessThan15Minutes ? "truncate" : ""}`}
              >
                {event.title}
              </div>
              {durationLessThan15Minutes ? null : (
                <div className="event-time">{eventObject.timeText}</div>
              )}
            </div>
          </div>
          {event?.extendedProps?.calendar_events &&
          Object.values(event?.extendedProps?.calendar_events).length > 0 ? (
            Object.values(event?.extendedProps?.calendar_events)[0]?.type ===
            "google" ? (
              <FcGoogle
                className={`event-complete-check ${
                  durationLessThan15Minutes ? "small" : ""
                }`}
                style={{
                  color: eventObject.textColor,
                }}
              />
            ) : (
              <img
                src={require("../../images/icons8-microsoft-outlook-480.png")}
                alt="outlook icon"
                className="w-4 h-4"
              />
            )
          ) : null}
        </div>
      ) : (
        //full month calendar view
        <div className={`overflow-hidden event-content w-full`}>
          <div
            className="flex flex-row items-center gap-1 overflow-hidden text-neutral-700  dark:text-neutral-300 px-1"
            // style={{
            //   color: textColor,
            // }}
          >
            {" "}
            {task?.complete ? (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  completeTaskForEvent(event);
                }}
                className={`flex flex-row justify-center items-center border-solid border-green-600 text-white bg-green-600
                rounded-[5px] border-[1.5px] w-3.5 h-3.5 !min-w-3.5 !min-h-3.5
                `}
              >
                <FaCheck className="w-2 h-2" />
              </div>
            ) : (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  completeTaskForEvent(event);
                }}
                className={`border-solid border-neutral-400 hover:border-neutral-800 dark:hover:border-neutral-200 rounded-[5px] border-[1.5px] w-3.5 h-3.5 !min-w-3.5 !min-h-3.5`}
              ></div>
            )}
            <div className="event-time">
              {eventObject.timeText ||
                (event.start && customTimeFormat(event.start))}
            </div>
            <div
              className={`event-title ph-masktruncate pl-0.5 ${
                task?.complete ? "completed" : ""
              }`}
            >
              {event.title}
            </div>
          </div>
          {/* </div> */}
          {event?.extendedProps?.calendar_events &&
            Object.values(event?.extendedProps?.calendar_events).length > 0 && (
              <FcGoogle
                className={`event-complete-check`}
                style={{
                  color: textColor,
                }}
              />
            )}
         
        </div>
      )}
    </Popover>
  );
}

function hexToRgb(hex) {
  // Remove the hash at the start if it's there
  hex = hex.replace(/^#/, "");
  var r, g, b;

  // If the hex code is in the shorthand form (3 characters), expand it
  if (hex.length === 3) {
    r = parseInt(hex.charAt(0) + hex.charAt(0), 16);
    g = parseInt(hex.charAt(1) + hex.charAt(1), 16);
    b = parseInt(hex.charAt(2) + hex.charAt(2), 16);
  } else {
    r = parseInt(hex.substring(0, 2), 16);
    g = parseInt(hex.substring(2, 4), 16);
    b = parseInt(hex.substring(4, 6), 16);
  }

  return { r, g, b };
}

function getTextColor({ color, hover = false, type = "bg" }) {
  const { r, g, b } = hexToRgb(color);
  // Normalize RGB values to [0, 1]
  const rNormalized = r / 255;
  const gNormalized = g / 255;
  const bNormalized = b / 255;
  // Calculate luminance
  const luminance =
    0.2126 * rNormalized + 0.7152 * gNormalized + 0.0722 * bNormalized;
  // Return 'white' for dark colors and 'black' for light colors
  return (
    `${type}-` +
    (luminance > 0.5
      ? hover
        ? "neutral-500"
        : "neutral-800"
      : hover
      ? "neutral-300"
      : "neutral-100")
  );
}

// Get checkmark color, reverse of the background color
function getCheckmarkColor({ color }) {
  const { r, g, b } = hexToRgb(color);
  const rNormalized = r / 255;
  const gNormalized = g / 255;
  const bNormalized = b / 255;
  const luminance =
    0.2126 * rNormalized + 0.7152 * gNormalized + 0.0722 * bNormalized;
  return luminance > 0.5 ? "text-white" : "text-black";
}
