import React, { useEffect, useRef, useState, useMemo } from "react";
import { useSelector } from "react-redux";

import Card from "../../Components/Kanban/Card";

import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import Droppable from "../../Components/DnDContainer/Containers/Droppable";
import SortableCard from "../../Components/DnDContainer/Containers/Sortable";

function BraindumpBody({
  brainDumpOrder,
  indexSelected,
  manuallySelectColumn,
  navigatedViaKeyboard,
  setIndexSelected,
  hide_sidebar,
}) {
  const { data: taskData } = useSelector((state) => state.tasks);

  const { label_filters: activeLabels, hide_complete } = useSelector(
    (state) => state.app?.currentUser
  );

  // Function that checks if a task should be visible
  function shouldShowTask(task) {
    // If the task is complete and hide_complete is true, don't show it
    if (task.complete && hide_complete) {
      return false;
    }

    // If there are no active labels, show the task
    if (!activeLabels || activeLabels.length === 0) {
      return true;
    }

    // If there are active labels, check if task.label exists and is in activeLabels
    if (task.label) {
      return activeLabels.includes(task.label);
    } else {
      return false;
    }
  }

  return (
    <Droppable braindump={true} id="brain_dump" hide_sidebar={hide_sidebar}>
      <SortableContext
        items={brainDumpOrder}
        strategy={verticalListSortingStrategy}
        id="brain_dump"
      >
        {brainDumpOrder
          .filter(
            (taskId) => taskData[taskId] && shouldShowTask(taskData[taskId])
          )
          .map((taskId, index) => {
            return (
              <SortableCard columnId={"brain_dump"} id={taskId} key={taskId}>
                <Card
                  key={taskId}
                  index={index}
                  item={taskData[taskId]}
                  activelySelected={indexSelected === index}
                  manuallySelectColumn={manuallySelectColumn}
                  navigatedViaKeyboard={navigatedViaKeyboard}
                  columnId="braindump"
                  setIndexSelected={setIndexSelected}
                />
              </SortableCard>
            );
          })}
      </SortableContext>
    </Droppable>
  );
}

// Custom areEqual function to check if we should re-render
function areEqual(prev, next) {
  return (
    prev.brainDumpOrder.length === next.brainDumpOrder.length &&
    prev.brainDumpOrder.every(
      (taskId, index) => taskId === next.brainDumpOrder[index]
    ) &&
    prev.hide_sidebar === next.hide_sidebar &&
    prev.indexSelected === next.indexSelected &&
    prev.navigatedViaKeyboard === next.navigatedViaKeyboard
  );
}

// Use React.memo to prevent unnecessary re-renders
export default React.memo(BraindumpBody, areEqual);
