import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import {
  updateCurrentUser,
  setToastVisible,
  setUpgradeVisible,
} from "../../../redux/appSlice";

import { ArrowRightIcon } from "@heroicons/react/24/outline";
import { ChevronRightIcon } from "@heroicons/react/24/solid";

import { Button, Input, Select, Switch } from "antd";

const { Option } = Select;

export default function DueDateSettings() {
  const due_date_settings = useSelector(
    (state) =>
      state.app.currentUser.power_feature_settings?.due_dates || {
        enabled: false,
        // Settings to determine when to show indicator
        number_of_days_to_show_indicator: 7,
      }
  );

  const dispatch = useDispatch();

  const [daysIndicator, setDaysIndicator] = useState(
    due_date_settings.number_of_days_to_show_indicator || 7
  );

  const power_feature_settings = useSelector(
    (state) =>
      state.app.currentUser.power_feature_settings || {
        analytics_enabled: true,
        due_dates: {
          enabled: false,
          number_of_days_to_show_indicator: 7,
        },
      }
  );

  useEffect(() => {
    setDaysIndicator(due_date_settings.number_of_days_to_show_indicator);
  }, [due_date_settings.number_of_days_to_show_indicator]);

  return (
    <div>
      <div className="settings personal">
        <div className="settings-item">
          <div className="settings-item-title">Due date indicator</div>
          <div className="settings-item-value">
            <div className="flex flex-row gap-2 items-center">
              <span> Show indicator on task card if due date is within</span>
              <input
                className="settings-item-input"
                type="number"
                style={{ width: "60px", minWidth: "60px" }}
                value={daysIndicator}
                onChange={(e) => {
                  setDaysIndicator(e.target.value);
                }}
                onBlur={() => {
                  let validatedValue = Number(daysIndicator);

                  // If the value is NaN or lesser than 0, reset it to 7
                  if (isNaN(validatedValue) || validatedValue <= 0) {
                    validatedValue = 7;
                    setDaysIndicator(validatedValue);
                  }

                  dispatch(
                    updateCurrentUser({
                      newValues: {
                        power_feature_settings: {
                          due_dates: {
                            number_of_days_to_show_indicator: validatedValue,
                          },
                        },
                      },
                      previousValues: {
                        power_feature_settings: power_feature_settings,
                      },
                    })
                  );
                }}
              />
              <span>days</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
