import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import _, { transform } from "lodash";
import "./Analytics.css";

import { analytics, getDaysDiff } from "../../utils";

import moment from "moment";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { Bar, Pie } from "react-chartjs-2";
import TopbarActions from "../Kanban/TopbarActions";
import { daysInWeek } from "date-fns";
import {
  FiArrowDown,
  FiArrowLeftCircle,
  FiArrowRight,
  FiArrowUp,
  FiCalendar,
  FiLifeBuoy,
} from "react-icons/fi";
import {
  BiBrain,
  BiCheck,
  BiCheckCircle,
  BiExport,
  BiHelpCircle,
  BiTime,
} from "react-icons/bi";
import { TiWarningOutline } from "react-icons/ti";
import { TbCircle } from "react-icons/tb";
import { MdFilterList } from "react-icons/md";
import { XMarkIcon } from "@heroicons/react/24/outline";
import dayjs from "dayjs";

import { Checkbox, DatePicker, Popover, Segmented, Select } from "antd";

import { Mobile, Default } from "../../mediaUtils";

import LabelFilters from "./LabelFilters";
import {
  collection,
  getDocs,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";

import { db } from "../../firebase";
import { BsArrowRight, BsCheckCircle } from "react-icons/bs";
import BraindumpCleanup from "./BraindumpCleanup";
import TimeSpentPieChart from "./Cards/TimeSpentPieChart";
import ActualvsEstimatedBarChart from "./Cards/ActualvsEstimatedBarChart";
import DailyCompletedBarChart from "./Cards/DailyCompletedBarChart";
import ActualvsEstimatedCard from "./Cards/ActualvsEstimateCard";
import BraindumpAverageAgeCard from "./Cards/BraindumpAverageAgeCard";
import { getBraindumpTasksSortedByAge } from "./AnalyticsFunctions";
import SmallCompletedTasksCard from "./Cards/SmallCompletedTasksCard";
import { useNavigate } from "react-router-dom";
import TimeSpentPieChartByEstimate from "./Cards/TimeSpentPieChartByEstimate";
import DailyCompletedBarChartByEstimate from "./Cards/DailyCompletedBarChartByEstimate";
const { RangePicker } = DatePicker;

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

ChartJS.register(ArcElement, Tooltip, Legend);

export default function Analytics({ userTheme }) {
  const navigate = useNavigate();
  const [taskData, setTaskData] = useState({});

  const [filteredTaskData, setFilteredTaskData] = useState({});
  const [brainDumpTaskData, setBrainDumpTaskData] = useState({});

  const userId = useSelector((state) => state.app.uid);

  const [activeFilters, setActiveFilters] = useState([]);

  const taskOrder = useSelector((state) => state.tasks.order);

  const [unit, setUnit] = useState("number");

  const rangeOptions = [
    {
      id: "7days",
      name: "Last 7 days",
      value: "7days",
      label: "Last 7 days",
      dates: getDaysDiff(
        moment().add(-7, "days").format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD")
      ),
      lastPeriodDates: getDaysDiff(
        moment().add(-14, "days").format("YYYY-MM-DD"),
        moment().add(-7, "days").format("YYYY-MM-DD")
      ),
    },
    {
      id: "14days",
      name: "Last 14 days",
      value: "14days",
      label: "Last 14 days",
      dates: getDaysDiff(
        moment().add(-14, "days").format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD")
      ),
      lastPeriodDates: getDaysDiff(
        moment().add(-28, "days").format("YYYY-MM-DD"),
        moment().add(-14, "days").format("YYYY-MM-DD")
      ),
    },
    {
      id: "30days",
      name: "Last month",
      value: "30days",
      label: "Last month",
      dates: getDaysDiff(
        moment().add(-30, "days").format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD")
      ),
      lastPeriodDates: getDaysDiff(
        moment().add(-60, "days").format("YYYY-MM-DD"),
        moment().add(-30, "days").format("YYYY-MM-DD")
      ),
    },
    {
      id: "3months",
      name: "Last 3 months",
      value: "3months",
      label: "Last 3 months",
      dates: getDaysDiff(
        moment().add(-3, "months").format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD")
      ),
      lastPeriodDates: getDaysDiff(
        moment().add(-6, "months").format("YYYY-MM-DD"),
        moment().add(-3, "months").format("YYYY-MM-DD")
      ),
    },
  ];

  const [dateRange, setDateRange] = useState(rangeOptions[0]);

  function loadTasksForDate(dates) {
    const tasksQuery = query(
      collection(db, "users", userId, "tasks"),
      where(
        "date",
        ">=",
        moment(dates[0], "YYYY-MM-DD").startOf("day").toDate()
      ),
      where(
        "date",
        "<=",
        moment(dates[dates.length - 1], "YYYY-MM-DD")
          .endOf("day")
          .toDate()
      ),
      // OR where("date", "==", null)
      where("date", "!=", null)
    );

    const braindumpQuery = query(
      collection(db, "users", userId, "tasks"),
      where("date", "==", null)
    );

    // Make it real time
    const unsubscribeTasksQuery = onSnapshot(tasksQuery, (querySnapshot) => {
      var tempTasks = [];
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots

        tempTasks.push({
          ...doc.data(),
          id: doc.id,
          date: doc.data().date ? doc.data().date.toDate() : null,
        });
      });

      setTaskData(_.keyBy(tempTasks, "id"));
    });

    const unsubscribeBraindumpQuery = onSnapshot(
      braindumpQuery,
      (querySnapshot) => {
        var tempTasks = [];
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots

          tempTasks.push({
            ...doc.data(),
            id: doc.id,
            date: null,
          });
        });

        setBrainDumpTaskData(_.keyBy(tempTasks, "id"));
      }
    );

    return {
      unsubscribeTasksQuery,
      unsubscribeBraindumpQuery,
    };
  }

  // Let's get the tasks from firestore (not realtime) based on the dates selected
  useEffect(() => {
    const { unsubscribeTasksQuery, unsubscribeBraindumpQuery } =
      loadTasksForDate([...dateRange.lastPeriodDates, ...dateRange.dates]);

    return () => {
      unsubscribeTasksQuery();
      unsubscribeBraindumpQuery();
    };
  }, [dateRange]);

  const braindumpSortedByAge = getBraindumpTasksSortedByAge(
    {
      ...taskData,
      ...brainDumpTaskData,
    },
    taskOrder?.["brain_dump"]?.order || []
  );

  const [mode, setMode] = useState("all-charts");

  useEffect(() => {
    // Filter the tasks based on the active filters
    // If there are any active filters, if not, just show all the tasks
    if (activeFilters.length > 0) {
      const filteredTasks = _.filter(taskData, (task) => {
        return _.includes(activeFilters, task.label);
      });

      setFilteredTaskData(filteredTasks);
    } else {
      setFilteredTaskData(taskData);
    }
  }, [activeFilters, taskData]);

  useEffect(() => {
    if (activeFilters.length > 0) {
      analytics("Analytics filters updated", {
        count: activeFilters.length,
      });
    }
  }, [activeFilters]);

  useEffect(() => {
    analytics("Analytics viewed", {
      source: "web",
    });
  }, []);

  return (
    <>
      <Default>
        <div className="analytics">
          <div className="analytics-header">
            <div className="title">📈 Analytics</div>

            <div
              onClick={() => {
                analytics("Analytics closed", {
                  source: "web",
                });
                navigate("/");
              }}
              className="close-container"
            >
              <XMarkIcon className="close-button" />
            </div>
          </div>

          <div className="analytics-body">
            {mode == "all-charts" && (
              <>
                <div className="filter-bar">
                  <div className="filter-bar-left">
                    <div className="range-bar">
                      {rangeOptions.map((option) => (
                        <>
                          <div
                            key={option.id}
                            className={`range-option ${
                              option.id === dateRange.id ? "selected" : ""
                            }`}
                            onClick={() => {
                              analytics("Analytics range changed", {
                                type: dateRange.id,
                              });
                              setDateRange(option);
                            }}
                          >
                            {option.name}
                          </div>
                          <div className="divider"></div>
                        </>
                      ))}
                      <div
                        className={`range-option ${
                          dateRange.id === "custom" ? "selected" : ""
                        }`}
                        onClick={() => {
                          analytics("Analytics range changed", {
                            type: "custom",
                          });
                          setDateRange({
                            id: "custom",
                            name: "Custom",
                            value: "custom",
                            label: "Custom",
                            dates: getDaysDiff(
                              moment().add(-30, "days").format("YYYY-MM-DD"),
                              moment().format("YYYY-MM-DD")
                            ),
                            lastPeriodDates: getDaysDiff(
                              moment().add(-60, "days").format("YYYY-MM-DD"),
                              moment().add(-30, "days").format("YYYY-MM-DD")
                            ),
                          });
                        }}
                      >
                        <FiCalendar />
                        Custom
                      </div>
                    </div>
                    {dateRange.id === "custom" && (
                      <RangePicker
                        // Value is dayjs
                        value={
                          dateRange.dates.length > 0
                            ? [
                                dayjs(dateRange.dates[0]),
                                dayjs(
                                  dateRange.dates[dateRange.dates.length - 1]
                                ),
                              ]
                            : []
                        }
                        onChange={(dates) => {
                          // Using dayjs
                          const startDate = dates[0].format("YYYY-MM-DD");
                          const endDate = dates[1].format("YYYY-MM-DD");

                          analytics("Analytics range changed", {
                            type: "custom",
                            start: startDate,
                            end: endDate,
                          });

                          const dateRange = getDaysDiff(startDate, endDate);

                          const lastPeriodStartDate = dayjs(startDate)
                            .add(-dateRange.length, "days")
                            .format("YYYY-MM-DD");

                          const lastPeriodEndDate = dayjs(endDate)
                            .add(-dateRange.length, "days")
                            .format("YYYY-MM-DD");

                          const lastPeriodDateRange = getDaysDiff(
                            lastPeriodStartDate,
                            lastPeriodEndDate
                          );

                          setDateRange({
                            id: "custom",
                            name: "Custom",
                            value: "custom",
                            label: "Custom",
                            dates: dateRange,
                            lastPeriodDates: lastPeriodDateRange,
                          });
                        }}
                      />
                    )}
                  </div>

                  <div className="filter-bar-right">
                    <Popover
                      placement="bottom"
                      trigger="click"
                      zIndex={900}
                      content={
                        <div>
                          <LabelFilters
                            activeFilters={activeFilters}
                            setActiveFilters={setActiveFilters}
                          />
                        </div>
                      }
                    >
                      <div className="filter-bar-right-item">
                        <MdFilterList />
                        Filter
                        {
                          // If there are active filters, show the number of active filters
                          activeFilters.length > 0 && (
                            <div className="filter-count">
                              ({activeFilters.length})
                            </div>
                          )
                        }
                      </div>
                    </Popover>

                    <Select
                      options={[
                        { value: "number", label: "Unit: # of Tasks" },
                        {
                          value: "estimated_time",
                          label: "Unit: Estimated time",
                        },
                      ]}
                      onChange={(value) => {
                        analytics("Analytics chart type changed", {
                          type: value,
                        });
                        setUnit(value);
                      }}
                      value={unit}
                    />
                  </div>
                </div>
                <div className="analytics-charts">
                  {unit === "number" ? (
                    <TimeSpentPieChart
                      taskData={filteredTaskData}
                      dateRange={dateRange}
                      userTheme={userTheme}
                    />
                  ) : (
                    <TimeSpentPieChartByEstimate
                      taskData={filteredTaskData}
                      dateRange={dateRange}
                      userTheme={userTheme}
                    />
                  )}

                  {unit === "number" ? (
                    <DailyCompletedBarChart
                      taskData={filteredTaskData}
                      dateRange={dateRange}
                      userTheme={userTheme}
                    />
                  ) : (
                    <DailyCompletedBarChartByEstimate
                      taskData={filteredTaskData}
                      dateRange={dateRange}
                      userTheme={userTheme}
                    />
                  )}

                  <ActualvsEstimatedBarChart
                    taskData={filteredTaskData}
                    dateRange={dateRange}
                    userTheme={userTheme}
                  />

                  <ActualvsEstimatedCard
                    taskData={filteredTaskData}
                    dateRange={dateRange}
                  />

                  <BraindumpAverageAgeCard
                    taskData={filteredTaskData}
                    dateRange={dateRange}
                    setMode={setMode}
                    brainDumpTaskData={brainDumpTaskData}
                  />

                  <SmallCompletedTasksCard
                    taskData={filteredTaskData}
                    dateRange={dateRange}
                  />
                </div>
              </>
            )}

            {mode == "braindump" && (
              <BraindumpCleanup
                setMode={setMode}
                braindumpSortedByAge={braindumpSortedByAge}
              />
            )}
          </div>
        </div>
      </Default>
      <Mobile>
        <div
          style={{
            color: "var(--text-primary)",
          }}
        >
          Analytics is not available on mobile (yet) 🥲 please use a desktop to
          access this
        </div>
      </Mobile>
    </>
  );
}
